import React from 'react';

import { Button, Modal, ModalBody } from 'reactstrap';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import useStore from '../../../data/store';
import PlaylistBody from './PlaylistBody';

const PlaylistModal = () => {
  const { playlist } = useStore();

  const toggle = () => {
    playlist.reset();
    playlist.update({ loading: false });
  };

  const location = useLocation();

  return (
    <Modal
      isOpen={playlist.loading || playlist.hasActive}
      size="xl"
      centered
      modalClassName="node-presenter-modal"
    >
      <Button className="toggle-modal" onClick={toggle}>
        &times;
      </Button>
      <ModalBody>
        <PlaylistBody location={location} />
      </ModalBody>
    </Modal>
  );
};

export default observer(PlaylistModal);
