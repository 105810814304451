import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ContentEditable from 'react-contenteditable';
import ContentContainer from './ContentContainer';

const NodeSummary = observer(({ sidebar }) => {
  const sNode = sidebar.node;
  const { t } = useTranslation();

  return (
    <ContentContainer title={t('sidebar.tabs.summary.label')}>
      <ContentEditable
        target="_blank"
        className="comment-details"
        html={sNode.summarise}
      />
    </ContentContainer>
  );
});

export default NodeSummary;
