import React from 'react';

import { isEmpty, map } from 'lodash';
import { Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import classnames from 'classnames';
import useStore from '../../../data/store';
import GroupRow from './GroupRow';

const Sidebar = () => {
  const { trees: store, nodeGroups, app } = useStore();
  const { t } = useTranslation();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        className={classnames('back-drop', { open: app.showGallerySidebar })}
        onClick={() => app.update({ showGallerySidebar: false })}
      />
      <div
        className={classnames('trees-sidebar', {
          'sidebar-open': app.showGallerySidebar,
        })}
      >
        <div className="ts-groups ts-card">
          {!isEmpty(nodeGroups.records) && (
            <div className="search-groups">
              <Input
                type="search"
                value={store.rootStore.nodeGroups.searchQuery}
                onChange={e =>
                  store.rootStore.nodeGroups.update({
                    searchQuery: e.target.value,
                  })
                }
              />
              <FontAwesomeIcon className="text-success" icon="search" />
            </div>
          )}
          <ul>
            {isEmpty(store.rootStore.nodeGroups.filteredGroups) ? (
              <li className="no-groups">{t('groups.no_groups')}</li>
            ) : (
              <>
                {!isEmpty(store.rootStore.nodeGroups.filteredSystem) && (
                  <>
                    {map(
                      store.rootStore.nodeGroups.filteredSystem,
                      (group, i) => (
                        <GroupRow group={group} key={i} />
                      )
                    )}
                    <hr />
                  </>
                )}
                {map(
                  store.rootStore.nodeGroups.filteredExceptSystem,
                  (group, i) => (
                    <GroupRow group={group} key={i} />
                  )
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default observer(Sidebar);
