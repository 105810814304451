import React, { useState } from 'react';

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../../../data/store';

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
  oldPassword: '',
};

const Password = () => {
  const { users: store } = useStore();
  const [params, setParams] = useState(INITIAL_STATE);

  const { t } = useTranslation();

  const handleSubmit = () => {
    store.currentUser.updatePassword(params).then(() => {
      setParams(INITIAL_STATE);
    });
  };

  return (
    <div className="profile-form sidebar-common-inputs">
      <h3>{t('settings.password.heading')}</h3>
      <FormGroup>
        <Label>{t('form_fields_old_password')}</Label>
        <Input
          type="password"
          value={params.oldPassword}
          onChange={e => setParams({ ...params, oldPassword: e.target.value })}
        />
      </FormGroup>
      <Row>
        <Col lg={6}>
          <FormGroup>
            <Label>{t('form_fields_password')}</Label>
            <Input
              type="password"
              value={params.password}
              onChange={e => setParams({ ...params, password: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <Label>{t('form_fields_confirm_password')}</Label>
            <Input
              type="password"
              value={params.confirmPassword}
              onChange={e =>
                setParams({ ...params, confirmPassword: e.target.value })
              }
            />
          </FormGroup>
        </Col>
      </Row>

      <Button onClick={handleSubmit} color="success" className="mt-2" size="lg">
        {store.currentUser.updatingPassword ? (
          <FontAwesomeIcon icon="spinner" className="fa-spin" />
        ) : (
          t('form.actions.change')
        )}
      </Button>
    </div>
  );
};

export default observer(Password);
