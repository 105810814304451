import React, { useState } from 'react';

import {
  Button,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import jQuery from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { isEqual, map } from 'lodash';
import useStore from '../data/store';
import BrandNavbar from './shared/BrandNavbar';
import Loader from './shared/Loader';
import StarredTreesList from './StarredTreesList';
import SaveUser from '../views/guest/SaveUser';

const PrivateNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { users: store, notifications: notificationStore, app } = useStore();

  const toggle = () => setIsOpen(!isOpen);

  const getBuildNumber = () => jQuery('meta[name="build_number"]')[0]?.content;

  const getBuildComment = () =>
    jQuery('meta[name="build_comment"]')[0]?.content;

  const { t } = useTranslation();
  return (
    <Navbar
      className={classnames('auth-navbar', {
        'guest-session': store.currentUser.guest,
      })}
      dark
      expand="lg"
    >
      {app.showZoomAlert && (
        <Loader
          text={`${t('control_bar.zoom_alert', { percent: app.zoomPercent })}%`}
          progress={false}
        />
      )}
      <BrandNavbar />
      {!app.embedded && (
        <>
          <NavbarToggler className="text-success" onClick={toggle}>
            <FontAwesomeIcon icon="bars" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto align-content-start" navbar>
              {map(['trees', 'boards', 'calendar'], (x,i) => (
                <NavItem key={i}>
                  <NavLink
                    className={
                      window.location.pathname.startsWith(`/${x}`)
                        ? 'active-header-nav'
                        : ''
                    }
                    tag={Link}
                    to={`/${x}`}
                  >
                    {t(`nav.links.${x}`)}
                  </NavLink>{' '}
                </NavItem>
              ))}
              <NavItem className="mr-2 ml-5">
                <StarredTreesList />
              </NavItem>
            </Nav>
          </Collapse>
          <Nav className="nav-right">
            {store.currentUser.guest && (
              <NavItem>
                <Button
                  color="warning save-user"
                  size="sm"
                  onClick={() => app.update({ saveUser: true })}
                >
                  <FontAwesomeIcon
                    icon={{ prefix: 'far', iconName: 'user' }}
                    className="mr-2"
                  />
                  <span className="btn-text">{t('nav.links.save_user')}</span>
                </Button>
                <SaveUser />
              </NavItem>
            )}
            {/*<NavItem className="ml-3 mr-3">*/}
            {/*  <Button color="success" size="sm" onClick={() => Beacon('open')}>*/}
            {/*    <FontAwesomeIcon*/}
            {/*      icon={{ prefix: 'far', iconName: 'comment-dots' }}*/}
            {/*      className="mr-2"*/}
            {/*    />*/}
            {/*    <span className="btn-text">{t('nav.links.chat')}</span>*/}
            {/*  </Button>*/}
            {/*</NavItem>*/}
            <NavItem>
              <NavLink
                style={{ cursor: 'pointer' }}
                onClick={() => notificationStore.showNotifications()}
                className={
                  notificationStore.hasUnread ? 'hasNotifications' : ''
                }
              >
                <FontAwesomeIcon
                  icon="bell"
                  color={notificationStore.hasUnread ? '' : '#c3ceb6'}
                />
                {notificationStore.hasUnread && (
                  <sup className="notifications-unread-count">
                    {notificationStore.unreadCount}
                  </sup>
                )}
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav className="avatar p-0 bg-success">
                <div className="initials text-white">
                  {store.currentUser.initials}
                </div>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  tag={Link}
                  to={{ pathname: '/settings', state: { tab: 'profile' } }}
                >
                  {t('labels.your_profile')}
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to={{ pathname: '/settings', state: { tab: 'account' } }}
                >
                  {t('labels.account')}
                </DropdownItem>
                <DropdownItem
                  tag={Link}
                  to={{ pathname: '/settings', state: { tab: 'contacts' } }}
                >
                  {t('labels.contacts')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag="a" href="/sign_out">
                  {t('nav.links.sign_out')}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="small disabled">
                  {store.currentUser.fullName}
                </DropdownItem>
                <DropdownItem className="small disabled">
                  {store.currentUser.email}
                </DropdownItem>

                <DropdownItem
                  className="disabled small"
                  title={getBuildComment()}
                >
                  {t('nav.links.version_build')} {getBuildNumber()}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </>
      )}
    </Navbar>
  );
};

export default observer(PrivateNav);
