import {
  makeObservable,
  action,
  flow,
  observable,
  computed,
  extendObservable,
} from 'mobx';
import { camelCase, filter, find, includes, isEmpty, map } from 'lodash';

import User from '../entities/user';
import client from '../axiosClient';
import selectableStore from '../mixins/selectable_store';

class UserStore {
  /* eslint-disable */
  @observable records = [];
  @observable protectedCurrentUser;
  @observable request;
  @observable fetchingCurrentUser = true;
  @observable showEnhance = false;
  /* eslint-enable */

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    extendObservable(this, selectableStore);
  }

  getById(id) {
    return find(this.records, { id });
  }

  getByIds(ids) {
    return filter(this.records, r => includes(ids, r.id));
  }

  userPresent(id) {
    return !isEmpty(this.getById(id));
  }

  getByGroupId(groupId) {
    return filter(
      this.records,
      r => !isEmpty(find(r.assignedGroups, { id: groupId }))
    );
  }

  @computed
  get usersWithoutGroup() {
    return filter(this.records, user => isEmpty(user.assignedGroups));
  }

  @computed
  get currentUser() {
    if (this.rootStore.app.embedded) return null;
    return this.protectedCurrentUser;
  }

  @computed
  get showGeneralOnBoarding() {
    return this.currentUser && !this.currentUser.generalOnboarded;
  }

  @computed
  get userSignedIn() {
    return !isEmpty(this.currentUser);
  }

  @computed
  get hasRecords() {
    return !isEmpty(this.records);
  }

  @action
  setRecords(records) {
    this.records = map(records, r => new User(r, this));
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setFetchedCurrentUser() {
    this.fetchingCurrentUser = false;
  }

  @action
  updateContactHeartbeats(heartbeats) {
    map(heartbeats, h => {
      const u = this.getById(h.id);
      if (isEmpty(u)) return;
      u.update({ lastHeartbeatAt: null });
      u.update({ lastHeartbeatAt: h.last_heartbeat_at });
    });
  }

  @action
  checkAndAdd(user) {
    if (isEmpty(user)) return;
    if (this.userPresent(user.id)) return;

    this.records.push(new User(user, this));
  }

  @action
  setCurrentUser(user) {
    this.protectedCurrentUser = new User(user, this);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @flow
  *fetchCurrentUser() {
    if (this.rootStore.app.embedded) {
      this.setFetchedCurrentUser();
      return false;
    }

    const response = yield client.get('/api/v1/users/current_user.json');

    if (response.data.success) {
      this.setCurrentUser(response.data.user);
      this.rootStore.products.fetchProducts().then(() => {
        this.setFetchedCurrentUser();
      });
      this.rootStore.notifications.fetchNotifications();
      return true;
    }

    this.setFetchedCurrentUser();
    return false;
  }

  @flow
  *searchNetwork(inputValue, me = false, withGroups = false) {
    this.request = yield client.get(`/api/v1/users/network_search.json`, {
      params: {
        query: inputValue,
        me,
        with_groups: withGroups,
      },
    });

    return this.request.data.results;
  }

  @flow
  *createGuest(params) {
    try {
      const response = yield client.post(
        '/api/v1/users/create_guest.json',
        params
      );

      return response.data;
    } catch (err) {
      return { success: false, message: 'Internal Server Error' };
    }
  }
}

export default UserStore;
