import React from 'react';

import PropTypes from 'prop-types';
import NodeProperties from './types/NodeProperties';
import MultiNodeProperties from './types/MultiNodeProperties';
import Search from './types/Search';
import Notifications from './types/Notifications';
import BoardAddForm from './types/BoardAddForm';
import BoardSettings from './types/BoardSettings';

const types = sidebar => {
  switch (sidebar.type) {
    case 'node':
      return <NodeProperties sidebar={sidebar} />;
    case 'tree':
      return <NodeProperties sidebar={sidebar} />;
    case 'multiNode':
      return <MultiNodeProperties sidebar={sidebar} />;
    case 'search':
      return <Search sidebar={sidebar} />;
    case 'notifications':
      return <Notifications />;
    case 'board-add':
      return <BoardAddForm sidebar={sidebar} />;
    case 'board-edit':
      return <BoardSettings sidebar={sidebar} />;
    default:
      return null;
  }
};

const Panel = ({ sidebar, sidebarWidth }) => (
  <div
    className="sidebar-panel"
    style={{ width: sidebarWidth, flex: `0 0 ${sidebarWidth}px` }}
  >
    {types(sidebar)}
  </div>
);

Panel.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
  sidebarWidth: PropTypes.number.isRequired,
};

export default Panel;
