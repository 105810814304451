import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { getNodeClassNames } from '../../../../helpers/node_helpers';
import NodeActions from './NodeActions';
import NodeAdditionalDetails from './NodeAdditionalDetails';
import NodeCollapsible from './NodeCollapsible';
import NodeName from './NodeName';
// eslint-disable-next-line import/no-cycle
import NodeChildren from './NodeChildren';
import Draggable from './Draggable';
import NodePlaceholders from './NodePlaceholders';
import NodeSiblingHandlers from './NodeSiblingHandlers';
import NodeThumbnail from './NodeThumbnail';
import NodeInfo from './NodeInfo';

const Node = observer(({ node }) => (
  <div id={node.id} className={getNodeClassNames(node)}>
    <Draggable node={node}>
      <NodePlaceholders node={node} />
      <div className="cell-info">
        <NodeInfo node={node} />
      </div>
      <NodeName node={node} />
      <NodeThumbnail node={node} />
      <NodeAdditionalDetails node={node} />
      <NodeActions node={node} />
      <NodeCollapsible node={node} />
      <NodeSiblingHandlers node={node} />
    </Draggable>
    {node.dragging && <div className="placeholder-clone" />}
    <NodeChildren node={node} />
  </div>
));

Node.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default Node;
