import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
// import Select from 'react-select';
import { Input } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { forEach, includes, isEmpty, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import useStore from '../data/store';

const StarredTreesList = () => {
  const { t } = useTranslation();
  const { trees } = useStore();

  useEffect(() => {
    if (!includes(['/trees'], window.location.pathname)) {
      trees.fetchStarred();
    }
  }, []);

  const gotoStarredTree = selectEvent => {
    if (isEmpty(selectEvent.target.value)) return;
    // eslint-disable-next-line no-restricted-globals
    location.replace(`/trees/${selectEvent.target.value}`);
    selectEvent.target.value = '';
  };

  const options = [];
  options.push({ id: '', name: 'Quick Nav for Starred Trees', disabled: true, selected: true });
  forEach(trees.starredTrees(), (op) =>
    options.push({ id: op.id, name: op.name, disabled: false, selected: false })
  );
  return (
    <div className="quick-tree-nav">
      <Input
        bsSize="sm"
        id="quick-nav"
        type="select"
        defaultValue=''
        placeholder={t('control_bar.starred.placeholder')}
        onChange={gotoStarredTree}
      >
        {map(options, (op, i) => (
          <option key={i} value={op.id} disabled={op.disabled}>
            {op.name}
          </option>
        ))}
      </Input>
    </div>
  );
};

export default observer(StarredTreesList);
