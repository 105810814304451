import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import GroupDeleteHandler from '../../../shared/GroupDeleteHandler';

const ContactGroup = ({ group }) => (
  <div className="contact-group" onDoubleClick={() => group.open()}>
    <div className="icon">
      <FontAwesomeIcon icon="folder" />
    </div>
    <h4 className="d-flex align-items-center">
      {group.name}{' '}
      <Badge color="success ml-2">
        {group.store.groupableStore.getByGroupId(group.id).length}
      </Badge>
      <GroupDeleteHandler group={group} />
    </h4>
  </div>
);

ContactGroup.propTypes = {
  group: PropTypes.instanceOf(Object).isRequired,
};

export default observer(ContactGroup);
