import React from 'react';

import classnames from 'classnames';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import client from '../../../../data/axiosClient';

const ControlBarButton = ({
  paramKey,
  paramValue,
  active,
  children,
  disabled,
  tree,
  title,
  handleClickOverride,
}) => {
  const defaultHandleClick = () => {
    if (disabled) return;
    const params = { [paramKey]: paramValue };
    if (handleClickOverride) {
      handleClickOverride(params);
    } else {
      tree.updateCurrentState(params);
    }
  };

  return (
    <Button
      onClick={defaultHandleClick}
      title={title}
      disabled={disabled}
      className={classnames({
        active,
        'mr-2': true,
      })}
    >
      {children}
    </Button>
  );
};

ControlBarButton.defaultProps = {
  active: false,
  disabled: false,
  title: '',
  handleClickOverride: null,
};

ControlBarButton.propTypes = {
  paramKey: PropTypes.string,
  paramValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  tree: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  handleClickOverride: PropTypes.func,
};

export default ControlBarButton;
