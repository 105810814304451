import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, map } from 'lodash';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classnames from 'classnames';
import NodeComments from '../node_comments';
import Attachments from '../node_attachments';
import NodeDescription from '../node_description';
import PlayableArea from './PlayableArea';

const NodePresenter = ({ node, children, onEnded }) => {
  const nodeOnlyText = !node.hasFeaturedVideo && !node.hasThumbnail;

  let tabs = [];

  if (!nodeOnlyText)
    tabs.push({
      name: 'description',
      icon: 'info',
      component: NodeDescription,
    });

  if (node.store.rootStore.users.userSignedIn) {
    tabs = [
      ...tabs,
      {
        name: 'attachments',
        icon: 'paperclip',
        component: Attachments,
      },
      {
        name: 'comments',
        icon: 'comment-alt',
        component: NodeComments,
      },
    ];
  }

  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    setActiveTab(tabs[0]);
  }, [node]);

  const Component = activeTab?.component;

  return (
    <div className="node-presenter">
      <PlayableArea node={node} onEnded={onEnded}>
        {children}
      </PlayableArea>
      {!isEmpty(tabs) && (
        <div className="node-collapsible-area">
          <div className="node-presenter-tabs">
            {map(tabs, (tab, i) => (
              <Button
                color={
                  isEqual(tab.name, activeTab.name) ? 'success' : 'secondary'
                }
                key={i}
                onClick={() => setActiveTab(tab)}
              >
                <FontAwesomeIcon icon={tab.icon} />
              </Button>
            ))}
          </div>
          <div className="node-presenter-content mt-1">
            {Component && <Component node={node} summary />}
          </div>
        </div>
      )}
    </div>
  );
};

NodePresenter.defaultProps = {
  onEnded: () => {},
};

NodePresenter.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
  onEnded: PropTypes.func,
};

export default observer(NodePresenter);
