import React from 'react';

import { Nav, NavItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const EmbeddedPromoNavbar = () => {
  const { t } = useTranslation();
  return (
    <Nav className="ml-auto" navbar>
      <NavItem className="ml-auto float-right">
        <a
          href="https://app.amalati.com"
          target="_blank"
          className="btn btn-success font-weight-bold"
          rel="noreferrer"
        >
          {t('promo.lovethis')}
        </a>
      </NavItem>
    </Nav>
  );
};

export default EmbeddedPromoNavbar;
