import React from 'react';

import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import SearchInputs from '../../search';
import SearchedNode from '../../search/SearchedNode';

const Search = observer(({ sidebar }) => {
  const tree = sidebar.store.rootStore.trees.activeTree;
  const {
    search: { results },
  } = tree;

  return (
    <div className="sidebar-search">
      <h4>Search</h4>
      <div className="sidebar-common-inputs">
        <SearchInputs tree={tree} searchText="Search" menuPlacement="bottom" />
      </div>
      <div className="searched-nodes">
        <h4>{isEmpty(results) ? 'No results' : `${results.length} Results`}</h4>
        {isEmpty(results) ? (
          <div>No nodes found</div>
        ) : (
          map(results, (r, i) => <SearchedNode nodeId={r} key={i} />)
        )}
      </div>
    </div>
  );
});

Search.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default Search;
