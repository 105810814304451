import React from 'react';

import { map } from 'lodash';

const ScrollPlaceholders = () => (
  <>
    {map(['left', 'top', 'right', 'bottom'], (dir, i) => (
      <div
        id={`direction-placeholder-${dir}`}
        className="direction-placeholder"
        key={i}
        direction={dir}
      />
    ))}
  </>
);

export default ScrollPlaceholders;
