import { action, computed, flow, makeObservable, observable } from 'mobx';
import { camelCase, isEmpty, isEqual, isNull, isUndefined, map } from 'lodash';
import client from '../axiosClient';

class NodeAttachment {
  /* eslint-disable */
  id;
  nodeId;
  userId;
  previewLink;
  @observable attachmentInstructions = '';
  @observable attachmentTitle = '';
  @observable attachmentType = 'document';
  @observable attachmentUrl;
  canEdit;
  @observable featured = false;
  @observable attachment;
  @observable filename;
  @observable creating = false;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  @computed
  get isDocument() {
    return isEqual(this.attachmentType, 'document');
  }

  @computed
  get isVideo() {
    return isEqual(this.attachmentType, 'video');
  }

  @computed
  get isNew() {
    return isEmpty(this.id);
  }

  @computed
  get node() {
    return this.store.rootStore.nodes.getById(this.nodeId);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  destroyRecord() {
    if (
      !isEmpty(this.store.rootStore.playlist.active) &&
      isEqual(this.store.rootStore.playlist.active.id, this.node.id)
    )
      this.store.playlist.reset();

    this.store.records.splice(this.store.records.indexOf(this));
  }

  @flow
  *create() {
    if (this.creating) return;

    if (
      isEmpty(this.attachmentTitle) ||
      isNull(this.attachment) ||
      isUndefined(this.attachment)
    )
      return;

    this.update({ creating: true });

    const params = new FormData();
    const whiteListedParams = [
      'node_id',
      'attachment_title',
      'attachment_type',
      'attachment_instructions',
      'attachment',
      'featured',
    ];

    map(whiteListedParams, p => {
      params.append(p, this[camelCase(p)]);
    });

    const response = yield client.post(`/api/v1/node_attachments.json`, params);

    if (response.data.success) {
      this.store.setRecords([response.data.attachment]);
      this.creating = false;
      this.update({ creating: false });
    }
  }

  @flow
  *toggleFeatured() {
    const response = yield client.put(
      `/api/v1/node_attachments/${this.id}.json`,
      { featured: !this.featured }
    );

    const isFeatured = !this.featured;

    if (!response.data.success) return;

    if (isFeatured) {
      return map(this.node.attachments, a => {
        if (isEqual(a.id, this.id)) return this.update({ featured: true });

        a.update({ featured: false });
      });
    }

    this.update({ featured: false });
  }

  @flow
  *updateServer() {
    const params = {
      attachment_title: this.attachmentTitle,
      attachment_instructions: this.attachmentInstructions,
      featured: this.featured,
    };

    const response = yield client.put(
      `/api/v1/node_attachments/${this.id}.json`,
      params
    );

    if (response.data.success) {
      const obj = this.store.getById(this.id);
      obj.update(params);
    }
  }

  @flow
  *destroy() {
    const response = yield client.delete(
      `/api/v1/node_attachments/${this.id}.json`
    );
    if (response.data.success) {
      this.destroyRecord();
    }
  }
}

export default NodeAttachment;
