import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import useStore from '../../../../data/store';
import Form from '../../../boards/index_components/Form';

const BoardAddForm = ({ sidebar }) => {
  const { boards } = useStore();

  return <Form board={boards.form} onSubmit={() => sidebar.destroy()} />;
};

BoardAddForm.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default observer(BoardAddForm);
