import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const NodeShareableLink = ({ node }) => {
  const text = `${window.location.origin}/trees/${node.rootId}?n=${node.id}`;
  const { t } = useTranslation();
  return (
    <>
      <hr />
      <CopyToClipboard text={text}>
        <Button color="success" className="w-100" size="sm">
          {t('sidebar.nodeshare.label')}
        </Button>
      </CopyToClipboard>
      <p className="small text-muted">{t('sidebar.nodeshare.message')}</p>
    </>
  );
};

NodeShareableLink.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(NodeShareableLink);
