import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ShareModule from '../../share';
import ContentContainer from './ContentContainer';

const NodeShare = observer(({ sidebar }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer title={t('sidebar.tabs.share.label')}>
      {' '}
      <div className="sidebar-common-inputs">
        <ShareModule record={sidebar.node} type="tree" />
      </div>
    </ContentContainer>
  );
});

export default NodeShare;
