import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';

import { useTranslation } from 'react-i18next';
import ControlBarButton from './Button';

const CanvasViewToggle = observer(({ tree }) => {
  const canvasParamValue = isEqual(tree.canvas, 'half') ? 'full' : 'half';
  const { t } = useTranslation();

  return (
    <div className="control-bar-btn">
      <ControlBarButton
        paramKey="canvas"
        tree={tree}
        title={t('control_bar.tooltip.canvas_view_toggle')}
        paramValue={canvasParamValue}
        active={isEqual(tree.canvas, 'full')}
      >
        <FontAwesomeIcon
          icon={isEqual(tree.canvas, 'half') ? 'expand' : 'compress'}
          data-toggle="tooltip"
        />
      </ControlBarButton>
    </div>
  );
});

CanvasViewToggle.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default CanvasViewToggle;
