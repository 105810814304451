import React, { useEffect, useRef } from 'react';

import { observer } from 'mobx-react';
// import Plyr from 'react-plyr';

import PropTypes from 'prop-types';

const Player = ({ onEnded, url }) => {
  useEffect(() => {}, [url]);
  const player = useRef();

  return (
    <div>Player is temporarily unavailable</div>
    // <Plyr
    //   ref={player}
    //   autoplay
    //   type="video" // or "vimeo"
    //   url={url}
    //   onEnd={onEnded}
    //   onLoadedData={e => {
    //     player.current.play();
    //   }}
    // />
  );
};
Player.defaultPropTypes = {
  onEnded: () => {},
};

Player.propTypes = {
  onEnded: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default observer(Player);
