import React from 'react';
import { observer } from 'mobx-react';
import { Col, Nav, Row } from 'reactstrap';
import { isEqual, map } from 'lodash';
import SettingsNavItem from '../../SettingsNavItem';
import Product from './Product';
import useStore from '../../../../data/store';

const Plans = () => {
  const { products: store } = useStore();
  return (
    <Row>
      <Col md={2} className="pricing-tabs user-settings">
        <Nav vertical pills tabs className="user-settings-nav">
          {map(Object.keys(store.groupByType), (plan, i) => (
            <SettingsNavItem
              key={i}
              active={isEqual(plan, store.selectedPlanType)}
              onClickHandler={() => store.changeSelectedPlanType(plan)}
              title={plan}
              tabId={plan}
            />
          ))}
        </Nav>
      </Col>
      <Col md={10} className="h-100">
        <Product />
      </Col>
    </Row>
  );
};

export default observer(Plans);
