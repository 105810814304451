import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Button,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  canAddChildren,
  canDeleteNode,
  canDragNode,
  canStarNode,
  getEllipsisActions,
} from '../../../../helpers/node_helpers';
import Starred from '../../../modules/starred';
import NodeDeleteAction from './NodeDeleteAction';

const NodeActions = observer(({ node }) => {
  const { t } = useTranslation();
  const [showPopover, setShowPopover] = useState(false);
  const history = useHistory();

  const {
    multiSelect,
    drag: { dragging, canvasDragging },
  } = node.tree;

  useEffect(() => {
    if (showPopover) setShowPopover(false);
  }, [canvasDragging, dragging]);

  if (node.fetching || node.isOrphanContainerNode || multiSelect || dragging)
    return null;

  const toggle = () => setShowPopover(!showPopover);

  const ellipsisActions = node.canChange
    ? getEllipsisActions(
        t,
        node,
        () => {
          toggle();
        },
        history
      )
    : [];

  const actionButton = (ea, key, showName = true) => (
    <Button
      key={key}
      className={classNames({ 'text-left w-100': showName })}
      color="link"
      onClick={ea.onClick}
    >
      <FontAwesomeIcon
        style={{ width: 15 }}
        icon={ea.icon}
        data-toggle="tooltip"
      />{' '}
      {showName && ea.name}
    </Button>
  );

  const actions = ellipsisActions;

  const useFirstShownAction = () => {
    let r = null;
    for (let i = 0; i < actions.length; i += 1) {
      if (actions[i].show) {
        r = actionButton(actions[i], i, false);
        actions[i].show = false;
        break;
      }
    }
    return r;
  };

  const oActions = [];
  const otherActions = () => {
    map(actions, (ea, i) => ea.show && oActions.push(actionButton(ea, i)));
    return oActions;
  };

  return (
    <div className="cell-actions cell-actions-btn">
      <ButtonDropdown
        target={node.nodeActionsPopoverId}
        isOpen={showPopover}
        toggle={toggle}
        placement="bottom"
        trigger="legacy"
      >
        {canDeleteNode(node) ? (
          <NodeDeleteAction node={node} />
        ) : (
          useFirstShownAction()
        )}
        {otherActions().length > 0 && node.canChange && (
          <>
            <DropdownToggle
              onClick={() => {
                toggle();
                node.toggleSelected(false, true);
              }}
              className="btn-edit mr-0"
            >
              <FontAwesomeIcon icon="ellipsis-v" data-toggle="tooltip" />
            </DropdownToggle>
            <DropdownMenu right>{oActions}</DropdownMenu>
          </>
        )}
      </ButtonDropdown>
      <div className="ml-auto" />
      {canAddChildren(node) && (
        <Button
          className="btn-add"
          onClick={() => node.addChild()}
          title={t('nodes.actions.tooltip.add_child')}
        >
          <FontAwesomeIcon icon="plus" data-toggle="tooltip" />
        </Button>
      )}
      {canDragNode(node) && (
        <Button
          className="btn-move"
          id={`button-move-${node.id}`}
          title={t('nodes.actions.tooltip.drag')}
        >
          <FontAwesomeIcon icon="arrows-alt" data-toggle="tooltip" />
        </Button>
      )}
      {canStarNode(node) && <Starred record={node} />}
    </div>
  );
});

NodeActions.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default NodeActions;
