import React, { useState } from 'react';

import { observer } from 'mobx-react';
import ContentEditable from 'react-contenteditable';
import { FormGroup, Label } from 'reactstrap';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import { sanitizedName } from '../../../../helpers/node_helpers';

const NodeWorkEstimate = observer(({ node }) => {
  const [estimate, setEstimate] = useState(node.estimate);

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleBlur = e => {
    node.update({ estimate: e.target.value }, true);
  };
  const { t } = useTranslation();
  return (
    <div className="plan-param-fields sidebar-common-inputs">
      <FormGroup>
        <Label>{t('sidebar.work.estimate')}</Label>
        <input
          className="form-control"
          value={estimate}
          onKeyDown={onKeyPress}
          onChange={e => {
            setEstimate(e.target.value);
          }}
          onBlur={handleBlur}
        />
      </FormGroup>
    </div>
  );
});

export default NodeWorkEstimate;
