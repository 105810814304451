import React from 'react';

import { Button, Progress } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const AutoplayCountDown = ({ countDown, playlist, cancelCountDown }) => {
  if (!countDown) return null;
  return (
    <div className="next-video-autoplay">
      <Progress
        color="success"
        value={(10 - countDown) * 10}
        style={{ height: '3px' }}
        className="mb-1"
      />
      <span className="small">
        Starting
        <b>
          {playlist.next.attachmentTitle} in {countDown} seconds
        </b>
      </span>
      <span>
        <Button
          className="d-inline-flex"
          onClick={cancelCountDown}
          color="danger"
          size="sm"
        >
          Cancel
        </Button>
      </span>
    </div>
  );
};
AutoplayCountDown.propTypes = {
  countDown: PropTypes.number.isRequired,
  cancelCountDown: PropTypes.func.isRequired,
  playlist: PropTypes.instanceOf(Object).isRequired,
};

export default observer(AutoplayCountDown);
