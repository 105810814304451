import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getComponentFromTab } from '../../../../helpers/sidebar_helpers';

const NodeProperties = observer(({ sidebar }) => (
  <div className="node-properties">
    {sidebar.fetching ? (
      <div className="sidebar-loader">
        <FontAwesomeIcon icon="spinner" className="fa-spin" />
        <span className="mt-2">Fetching</span>
      </div>
    ) : (
      <div className="sidebar-tab-content">{getComponentFromTab(sidebar)}</div>
    )}
  </div>
));

NodeProperties.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default NodeProperties;
