import React from 'react';

import { Redirect as RedirectTo } from 'react-router-dom';
import PropTypes from 'prop-types';

const Redirect = ({ match }) => {
  const paramsId = match?.params?.id;

  switch (paramsId) {
    case 'go_unlimited':
      return (
        <RedirectTo
          to={{
            pathname: '/settings',
            state: { tab: 'account', goUnlimited: true },
          }}
        />
      );

    default:
      return <Redirect to="/" />;
  }
};

Redirect.propTypes = {
  match: PropTypes.instanceOf('Object').isRequired,
};

export default Redirect;
