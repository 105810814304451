import React from 'react';
import TimeAgo from 'react-timeago';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import formatter from '../../i18n/timeAgoTranslations';

const TimeAgoInWords = ({ date }) => {
  const { i18n } = useTranslation();

  return <TimeAgo date={date} formatter={formatter(i18n.language)} />;
};

TimeAgoInWords.propTypes = {
  date: PropTypes.string.isRequired,
};

export default TimeAgoInWords;
