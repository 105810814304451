import React from 'react';

import PropTypes from 'prop-types';

const ContentContainer = ({ title, children }) => (
  <div className="pt-3">{children}</div>
);

ContentContainer.defaultProps = {
  title: '',
};

ContentContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default ContentContainer;
