import { isEmpty } from 'lodash';

import Unsplash from 'unsplash-js';

export const GALLERY_MAX = 11;
export const unsplash = app =>
  new Unsplash({
    accessKey: app.unsplashKey,
    secret: app.unsplashSecret,
  });

export const bgParams = img => ({
  backgroundUrl: `${img.urls.regular}&username=${
    img.user.username
  }&user_full_name=${img.user.name.split(' ').join('+')}`,
});

export const imageBackgroundStyle = record => {
  const url = record?.backgroundUrl;

  if (isEmpty(url)) return {};

  const rtn = {
    backgroundImage: `url("${url}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
  };
  return rtn;
};
