import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { isEmpty } from 'lodash';
import AddTree from './AddTree';
import useStore from '../../../data/store';
import StoreSearchWidget from '../../shared/StoreSearchWidget';
import StoreSortWidget from '../../shared/StoreSortWidget';
import { customStylesForFilter } from '../../../helpers/shared_helpers';
import FilterTabs from './FilterTabs';

const FilterWidget = () => {
  const { users: userStore, app } = useStore();
  const [refreshToggles, setRefreshToggles] = useState(false);

  const toggle = () => setRefreshToggles(!refreshToggles);

  return (
    <Row>
      <Col>
        <div className="filter-tree-bg">
          <div className="filter-trees">
            <Button
              onClick={() =>
                app.update({ showGallerySidebar: !app.showGallerySidebar })
              }
              color={classnames(
                {
                  success: app.showGallerySidebar,
                  'light text-success': !app.showGallerySidebar,
                },
                'btn-sidebar'
              )}
            >
              <FontAwesomeIcon icon="bars" />
            </Button>
            <StoreSearchWidget stores={[userStore.rootStore.trees]} />
            <ResponsiveToggle
              refresh={refreshToggles}
              beforeClick={toggle}
              className="search-sort-widget"
              size="md"
              icon="sort"
            >
              <FilterTabs />
              <StoreSortWidget
                styles={customStylesForFilter}
                stores={[userStore.rootStore.trees]}
                options={['name', 'createdAt', 'updatedAt', 'nodes']}
              />
            </ResponsiveToggle>
            {userStore.userSignedIn && <AddTree />}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export const ResponsiveToggle = ({
  children,
  icon,
  size,
  className,
  beforeClick,
  refresh,
  direction,
  btnClassName,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
  }, [refresh]);

  const toggle = () => {
    beforeClick(!show);
    setTimeout(() => setShow(!show), 100);
  };

  return (
    <div
      className={classnames(`toggle-responsive toggle-hidden-${size}`, {
        'is-open': show,
        [className]: true,
        [`toggle-${direction}`]: true,
      })}
    >
      <Button
        onClick={toggle}
        color={show ? 'success' : 'light'}
        className={classnames({
          'text-white': show,
          'text-success': !show,
          [btnClassName]: true,
        })}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
      <div className="toggle-content">{children}</div>
    </div>
  );
};

ResponsiveToggle.defaultProps = {
  className: '',
  beforeClick: () => {},
  refresh: false,
  direction: 'left',
  btnClassName: '',
};

ResponsiveToggle.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  beforeClick: PropTypes.func,
  refresh: PropTypes.bool,
  direction: PropTypes.string,
  btnClassName: PropTypes.string,
};

export default observer(FilterWidget);
