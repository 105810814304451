import React, { useState } from 'react';

import {
  Button,
  Col,
  FormGroup,
  Form as BootstrapForm,
  Input,
  Label,
  Row,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import SelectTemplate from 'react-select';
import Select from 'react-select/async/dist/react-select.esm';
import DateTime from 'react-datetime';
import { isDate, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { startCase } from 'lodash/string';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../../helpers/shared_helpers';
import Loader from '../../../layouts/shared/Loader';

const Form = observer(({ board, onSubmit }) => {
  const [template, setTemplate] = useState(null);
  const [activeTab, setActiveTab] = useState('trees');
  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();

    board.submit().then(() => {
      if (onSubmit) onSubmit();
    });
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const tabItems = ['trees', 'tags', 'assignees', 'due_dates'].map(tab => (
    <NavItem>
      <NavLink
        className={classnames({
          active: activeTab === tab,
        })}
        onClick={() => {
          toggleTab(tab);
        }}
      >
        {startCase(tab)}
      </NavLink>
    </NavItem>
  ));

  return (
    <BootstrapForm
      className="sidebar-tab-content sidebar-common-inputs"
      onSubmit={handleSubmit}
    >
      <h4 className="text-muted">
        {board?.isNew
          ? t('boards.form.heading.add')
          : t('boards.form.heading.update')}
      </h4>
      <hr />
      {isEmpty(board) ? (
        <Loader />
      ) : (
        <>
          <Row>
            <Col lg={12}>
              {board.isNew && (
                <FormGroup className="mb-4">
                  <h5>From a template</h5>
                  <SelectTemplate
                    placeholder={t('boards.form.placeholder.template')}
                    options={board.store.templatesAsSelectOptions}
                    styles={customStyles}
                    onChange={temp => {
                      setTemplate(temp);
                      board.store.onTemplateSelect(temp);
                    }}
                    value={template}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  value={board.name}
                  onChange={e => board.update({ name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  value={board.description}
                  type="textarea"
                  onChange={e => board.update({ description: e.target.value })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h5 className="mb-4">Board Criteria</h5>
              <Nav tabs>{tabItems}</Nav>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <TabContent
                style={{ paddingLeft: 2, paddingTop: 10 }}
                activeTab={activeTab}
              >
                <TabPane tabId="trees">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Select
                          isMulti
                          value={board.criteria.trees}
                          onChange={e => board.criteria.update({ trees: e })}
                          styles={customStyles}
                          placeholder={t('boards.form.placeholder.tree')}
                          loadOptions={inputValue =>
                            board.store.rootStore.trees.searchUserTrees(
                              inputValue
                            )
                          }
                        />
                        {isEmpty(board.criteria.trees) && (
                          <div className="text-muted mt-1 small">
                            {t('boards.form.supporting_text.tree')}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="tags">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Select
                          value={board.criteria.tags}
                          placeholder={t('boards.form.placeholder.tags')}
                          onChange={e => board.criteria.update({ tags: e })}
                          loadOptions={inputValue =>
                            board.store.rootStore.tags.searchTags(
                              null,
                              inputValue,
                              true
                            )
                          }
                          isMulti
                          styles={customStyles}
                        />
                        {isEmpty(board.criteria.tags) && (
                          <div className="text-muted mt-1 small">
                            {t('boards.form.supporting_text.tags')}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="assignees">
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Select
                          onChange={e =>
                            board.criteria.update({ assignedTo: e })
                          }
                          isMulti
                          value={board.criteria.assignedTo}
                          styles={customStyles}
                          placeholder={t('boards.form.placeholder.assignees')}
                          loadOptions={inputValue =>
                            board.store.rootStore.users.searchNetwork(
                              inputValue,
                              true
                            )
                          }
                        />
                        {isEmpty(board.criteria.assignedTo) && (
                          <div className="text-muted mt-1 small">
                            {t('boards.form.supporting_text.assignees')}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="due_dates">
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <DateTime
                          isValidDate={(c, s) => {
                            if (isDate(board.criteria.dueDateTo)) {
                              return c < board.criteria.dueDateTo;
                            }
                            return true;
                          }}
                          value={board.criteria.dueDateFrom}
                          timeFormat={false}
                          inputProps={{
                            placeholder: t(
                              'boards.form.placeholder.due_date.from'
                            ),
                          }}
                          closeOnSelect
                          onChange={e =>
                            board.criteria.update({ dueDateFrom: e._d })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <DateTime
                          isValidDate={(c, s) => {
                            if (isDate(board.criteria.dueDateFrom)) {
                              return c > board.criteria.dueDateFrom;
                            }
                            return true;
                          }}
                          value={board.criteria.dueDateTo}
                          inputProps={{
                            placeholder: t(
                              'boards.form.placeholder.due_date.to'
                            ),
                          }}
                          timeFormat={false}
                          closeOnSelect
                          onChange={e =>
                            board.criteria.update({ dueDateTo: e._d })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="statuses">
                  <Row>
                    <Col sm="12"></Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              <Button
                style={{ padding: '7px 35px' }}
                className="mt-2 w-100"
                color="success"
              >
                {board.isNew
                  ? t('form.actions.submit')
                  : t('form.actions.update')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </BootstrapForm>
  );
});

export default Form;
