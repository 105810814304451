import React from 'react';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';

const GroupDeleteHandler = ({ group, color }) => {
  const { t } = useTranslation();
  const { users: userStore } = useStore();

  if (!userStore.userSignedIn) return null;
  if (!group.isOwner) return null;

  return (
    <div className="actions">
      <Button
        onClick={() =>
          confirmAlert({
            title: t('groups.confirm_alerts.delete.title'),
            message: t('groups.confirm_alerts.delete.message'),
            buttons: [
              {
                label: 'Yes',
                onClick: () => group.destroy(true),
              },
              {
                label: 'No',
              },
            ],
          })
        }
        color={color}
        className="archive"
      >
        <FontAwesomeIcon icon="trash" size="lg" />
      </Button>
    </div>
  );
};

GroupDeleteHandler.defaultProps = {
  color: 'link p-0',
};

GroupDeleteHandler.propTypes = {
  group: PropTypes.instanceOf(Object).isRequired,
  color: PropTypes.string,
};

export default observer(GroupDeleteHandler);
