import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEqual, map } from 'lodash';
import classnames from 'classnames';
// eslint-disable-next-line import/no-cycle
import Node from './index';
import Limited from './Limited';

const NodeChildren = observer(({ node }) => (
  <>
    {node.hasChildren && (
      <div
        className={classnames('cell-children', {
          'parent-dragging': node.dragging,
          'single-child': isEqual(node.children.length, 1),
        })}
      >
        {map(node.children, (n, i) => (
          <React.Fragment>
            {/* eslint-disable-next-line no-nested-ternary */}
            {!n.canChange && n.computedPublicationStatus === 'draft' ? (
              <></>
            ) : node.isTag ? (
              <Limited node={n} key={i} />
            ) : (
              <Node node={n} key={i} />
            )}
          </React.Fragment>
        ))}
      </div>
    )}
  </>
));

NodeChildren.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default NodeChildren;
