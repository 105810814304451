import React from 'react';

import { isEqual } from 'lodash';
import NodeDetails from '../views/modules/sidebar/tab_content/NodeDetails';
import NodeShare from '../views/modules/sidebar/tab_content/NodeShare';
import NodeSharedWith from '../views/modules/sidebar/tab_content/NodeSharedWith';
import NodeComments from '../views/modules/sidebar/tab_content/NodeComments';
import NodeWorkManagement from '../views/modules/sidebar/tab_content/NodeWorkManagement';
import NodeAttachments from '../views/modules/sidebar/tab_content/NodeAttachments';
import NodeSettings from '../views/modules/sidebar/tab_content/NodeSettings';

import BoardShare from '../views/modules/sidebar/tab_content/BoardShare';
import BoardSharedWith from '../views/modules/sidebar/tab_content/BoardSharedWith';
import BoardInfo from '../views/modules/sidebar/tab_content/BoardInfo';
import BoardSettings from '../views/modules/sidebar/tab_content/BoardSettings';
import NodeSummary from '../views/modules/sidebar/tab_content/NodeSummary';
import TreeAttachments from '../views/modules/sidebar/tab_content/TreeAttachments';
import NodeQuiz from '../views/modules/sidebar/tab_content/NodeQuiz';

export const getComponentFromTab = sidebar => {
  if (isEqual(sidebar.type, 'board-edit')) {
    switch (sidebar.activeTab) {
      case 'details':
        return <BoardInfo sidebar={sidebar} />;
      case 'share':
        return <BoardShare sidebar={sidebar} />;
      case 'shared_with':
        return <BoardSharedWith sidebar={sidebar} />;
      case 'settings':
        return <BoardSettings sidebar={sidebar} />;
      default:
        return null;
    }
  }
  switch (sidebar.activeTab) {
    case 'details':
      return <NodeDetails sidebar={sidebar} />;
    case 'work':
      return <NodeWorkManagement sidebar={sidebar} />;
    case 'attachments':
      return <NodeAttachments sidebar={sidebar} />;
    case 'tree_attachments':
      return <TreeAttachments sidebar={sidebar} />;
    case 'share':
      return <NodeShare sidebar={sidebar} />;
    case 'shared_with':
      return <NodeSharedWith sidebar={sidebar} />;
    case 'comments':
      return <NodeComments sidebar={sidebar} />;
    case 'settings':
      return <NodeSettings sidebar={sidebar} />;
    case 'summary':
      return <NodeSummary sidebar={sidebar} />;
    case 'quiz':
      return <NodeQuiz sidebar={sidebar} />;
    default:
      return null;
  }
};
