import { action, computed, flow, makeObservable, observable } from 'mobx';
import { camelCase, isDate, isEmpty, map } from 'lodash';
import moment from 'moment';
import client from '../axiosClient';

class Search {
  /* eslint-disable */
  @observable dueDateStart = null;
  @observable dueDateEnd = null;
  @observable tags = [];
  @observable statuses = [];
  @observable query = '';
  @observable results = [];
  store;
  /* eslint-enable */

  constructor(store) {
    makeObservable(this);

    this.store = store;
  }

  @computed
  get dateRangeValue() {
    if (!isDate(this.dueDateStart) && !isDate(this.dueDateEnd))
      return this.store.rootStore.translate(
        'search_inputs.placeholder.due_date'
      );
    const startDate = isDate(this.dueDateStart)
      ? moment(this.dueDateStart).format('MMM/DD/YYYY')
      : 'NA';
    const endDate = isDate(this.dueDateEnd)
      ? moment(this.dueDateEnd).format('MMM/DD/YYYY')
      : 'NA';

    return `${startDate} - ${endDate}`;
  }

  @computed
  get canBeFetched() {
    return (
      !isEmpty(this.query) ||
      !isEmpty(this.tags) ||
      !isEmpty(this.statuses) ||
      isDate(this.dueDateStart)
    );
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @flow
  *fetch(sidebar = false) {
    if (this.canBeFetched) {
      const tagIds = map(this.tags, t => t.value);
      const statuses = map(this.statuses, t => t.value);
      const { query } = this;
      const response = yield client.get('/api/v1/search.json', {
        params: {
          query,
          tags: tagIds,
          root: this.store.activeTree.activeTab.node.rootId,
          start_due_date: this.dueDateStart,
          end_due_date: this.dueDateEnd,
          statuses,
        },
      });

      if (sidebar) {
        this.store.rootStore.sidebars.addSidebar({ type: 'search' });
      }

      this.results = response.data;
    }
  }
}

export default Search;
