import React, { useState } from 'react';

import { observer } from 'mobx-react';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from 'react-i18next';
import { sanitizedName } from '../../../../helpers/node_helpers';

const NodeName = observer(({ node }) => {
  const [name, setName] = useState(node.name);
  const { t } = useTranslation();
  const onKeyPress = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
    if (!node.isTag && (node.hasTags || node.hasChildren) && e.key === '#') {
      e.preventDefault();
      node.store.rootStore.notification('danger', t('nodes.name.error'));
    }
  };

  const handleBlur = e => {
    const nName = sanitizedName(e.target.innerHTML);

    node.update({ name: nName }, true);
  };

  return (
    <div>
      <ContentEditable
        className="cell-edit mb-4"
        html={name}
        onKeyDown={onKeyPress}
        onChange={e => {
          setName(e.target.value);
        }}
        onBlur={handleBlur}
      />
    </div>
  );
});

export default NodeName;
