import { action, flow, makeObservable, observable } from 'mobx';

import { filter, includes, isEqual, map, uniqBy } from 'lodash';

import client from '../axiosClient';
import NodeAssignee from '../entities/node_assignee';
import User from '../entities/user';

class NodeAssigneeStore {
  @observable records = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setRecords(records) {
    this.records = uniqBy(
      [...this.records, ...map(records, r => this.newRecord(r))],
      'id'
    );
  }

  @action
  newRecord(record) {
    const { users: userStore } = this.rootStore;

    userStore.checkAndAdd(record.user);

    return new NodeAssignee(record, this);
  }

  @flow
  *fetchingNodeAssignees(nodeId, setRecords = true) {
    const response = yield client.get(
      `/api/v1/nodes/${nodeId}/node_assignees.json`
    );
    if (response.data.success) {
      if (setRecords) this.setRecords(response.data.results);

      return response.data.results;
    }

    return [];
  }

  @flow
  *destroyByRecordIds(userId, nodeIds) {
    const response = yield client.put('/api/v1/nodes/unassign.json', {
      user_id: userId,
      node_ids: nodeIds,
    });

    if (response.data.success) {
      const recordsToBeDeleted = filter(
        this.records,
        r => isEqual(userId, r.userId) && includes(nodeIds, r.nodeId)
      );

      map(recordsToBeDeleted, r => r.destroy());
    }
  }

  @flow
  *searchCollaborators(id, val) {
    const response = yield* this.rootStore.nodeCollaborators.fetchCollaborators(
      id,
      'search',
      val
    );

    return map(response.data.results, r => {
      const user = new User(r.user);
      return { label: user.fullName, value: user.id };
    });
  }

  @flow
  *addByRecordIds(userId, nodeIds) {
    const response = yield client.post('/api/v1/nodes/assign.json', {
      node_ids: nodeIds,
      user_id: userId,
    });

    if (response.data.success) this.setRecords(response.data.results);
  }
}

export default NodeAssigneeStore;
