import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ControlBarButton from './Button';

const CanvasReset = observer(({ tree }) => {
  const buttonClick = () => {
    tree.defaultExpand();
  };

  return (
    <div className="control-bar-btn pl-2">
      <ControlBarButton
        tree={tree}
        paramKey=""
        paramValue=""
        handleClickOverride={buttonClick}
      >
        <FontAwesomeIcon
          icon="undo-alt"
          data-toggle="tooltip"
          title="Reset the tree"
        />
      </ControlBarButton>
    </div>
  );
});

CanvasReset.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default CanvasReset;
