import React from 'react';

import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import Form from '../../../boards/index_components/Form';

const BoardInfo = observer(({ sidebar }) => {
  const { t } = useTranslation();

  return (
    <Form
      board={sidebar.board.asForm}
      onSubmit={() =>
        sidebar.store.rootStore.notification(
          'success',
          t('boards.toast.update')
        )
      }
    />
  );
});

export default BoardInfo;
