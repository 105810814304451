import React, { useEffect, useState } from 'react';

import { Redirect, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { isEmpty, isEqual, isNull, map } from 'lodash';

import { useTranslation } from 'react-i18next';

import useStore from '../../data/store';
import ScrollPlaceholders from './show_components/ScrollPlaceholders';
import { setRedirectionUrl } from '../../helpers/shared_helpers';
import AppLayout from '../../layouts/App';
import Loader from '../../layouts/shared/Loader';
import InfoBar from './show_components/InfoBar';
import PromoPopup from '../modules/promo_popup';
import PlaylistModal from './show_components/PlaylistModal';
import TreeCanvas from './show_components/TreeCanvas';
import TreesWrapper from './show_components/TreesWrapper';
import HelmetSettings from './show_components/HelmetSettings';

const TreeShow = () => {
  const { trees, users: userStore, notification, sidebars } = useStore();
  const aTree = trees.activeTree;
  const mTree = trees.masterTree;

  const match = useRouteMatch('/trees/:id');
  const [redirect, setRedirect] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    trees.update({ records: [] });

    const tree = trees.createOrFindTree(match.params.id);
    trees.update({ masterTreeId: match.params.id });

    tree.fetchTreeAndNodes().then(res => {
      if (res.success) {
        let active = null;
        map(res.tree.tabs, tab => {
          trees.createOrFindTree(tab.node_id);
          if (tab.active) {
            trees.setActiveTree(tab.node_id);
            active = tab.node_id;
          }
        });

        if (isNull(active)) trees.setActiveTree(tree.id);

        return;
      }
      if (isEqual(res.reason, 'login_required')) {
        notification('danger', 'You need to sign in to continue');
        setRedirectionUrl();
        setRedirect('/public/auth');
        return;
      }
      if (isEqual(res.reason, 'not_authorized')) {
        notification('danger', 'You are not authorized to access this page');
      }

      setRedirect('/public/trees');
    });
  }, []);

  useEffect(
    () => () => {
      trees.resetAll();
      sidebars.clearAll();
    },
    []
  );

  if (!isEmpty(redirect)) return <Redirect to={redirect} />;
  if (mTree?.redirect) return <Redirect to="/" />;

  return (
    <AppLayout
      scrollable
      loading={aTree?.fetching}
      publiclyVisible={!userStore.userSignedIn}
      sidebarNp={false}
    >
      <HelmetSettings />
      {aTree && !aTree.fetchingNodes && <InfoBar />}
      {mTree?.pastingNodes && <Loader text={t('nodes.pasting_nodes')} />}

      <TreesWrapper>
        {map(mTree?.tabs, (tab, i) => (
          <TreeCanvas tab={tab} key={i} />
        ))}
      </TreesWrapper>

      <ScrollPlaceholders />
      <PlaylistModal />
      <PromoPopup />
    </AppLayout>
  );
};

export default observer(TreeShow);
