import React, { useEffect, useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { observer } from 'mobx-react';
import { isEmpty, isEqual } from 'lodash';

import { Container } from 'reactstrap';
import classNames from 'classnames';
import AppLayout from '../../layouts/App';
import useStore from '../../data/store';

const localizer = momentLocalizer(moment); // or globalizeLocalizer

const CalendarView = () => {
  const { nodes: store, sidebars } = useStore();
  const [currentDate, setCurrentDate] = useState(
    moment()
      .startOf('month')
      .toDate()
  );

  const updateDates = date => {
    const startDate = moment(date)
      .startOf('month')
      .format('DD-MM-YYYY');
    const endDate = moment(date)
      .endOf('month')
      .format('DD-MM-YYYY');

    store.updateFilters({ start_date: startDate, end_date: endDate });
  };

  useEffect(() => {
    console.log();
  }, [sidebars.records]);

  useEffect(() => {
    updateDates(new Date());
  }, []);

  useEffect(() => {
    if (!isEmpty(store.filters.start_date)) {
      store.fetchCalendarRecords();
    }
  }, [store.filters]);

  const handleSelectSlot = (date, view) => {
    setCurrentDate(date);
    updateDates(date);
  };

  const handleSelectEvent = event => {
    const node = store.getById(event.id);

    if (isEmpty(node)) return;
    if (!node.fetched) node.fetch();

    sidebars.addSidebar({
      type: 'node',
      viewType: 'board',
      nodeIds: [event.id],
      activeTab: 'details',
      treeName: node.treeName,
    });
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const node = store.getById(event.id);

    const style = {
      backgroundColor: classNames({
        '#ef970c': isEqual(node.status, 'started'),
        '#05a679': isEqual(node.status, 'completed'),
        '#dde6d3': isEqual(node.status, 'orphan'),
        '#de6060': isEqual(node.status, 'blocked'),
      }),
    };

    return {
      style,
    };
  };

  return (
    <AppLayout loading={false}>
      <Container fluid>
        <div className="calendar-container">
          <Calendar
            elementProps={{ className: 'hello ' }}
            localizer={localizer}
            events={store.calendarEvents}
            date={currentDate}
            views={['month']}
            popup
            onSelectEvent={handleSelectEvent}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventStyleGetter}
            onNavigate={handleSelectSlot}
          />
        </div>
      </Container>
    </AppLayout>
  );
};

export default observer(CalendarView);
