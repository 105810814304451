import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import RestrictedNodeDetails from '../../nodes/restricted_node_details';
import NodeName from '../../nodes/node_name';
import NodeDescription from '../../nodes/node_description';
import Tags from '../../nodes/nodes_tags';
import ContentContainer from './ContentContainer';
import FeaturedAttachment from '../../nodes/featured_attachment';
import NodeStatus from '../../nodes/node_status';
import NodePublicationStatus from '../../nodes/node_publication_status';

const NodeDetails = observer(({ sidebar }) => {
  const sNode = sidebar.node;
  const sNodes = sidebar.nodes;

  if (!sNode.fetched) return null;
  if (!sNode.canChange) return <RestrictedNodeDetails node={sNode} />;

  const { t } = useTranslation();

  return (
    <ContentContainer title={t('sidebar.tabs.details.label')}>
      <div className="node-details">
        <NodeName node={sNode} />
        <NodeDescription node={sNode} />
        {!sNode.isTag && <Tags nodes={sNodes} viewType={sidebar.viewType} />}
        <div>
          <FeaturedAttachment node={sNode} />
        </div>
      </div>
    </ContentContainer>
  );
});

export default NodeDetails;
