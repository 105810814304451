import React from 'react';

import { Label } from 'reactstrap';
import Select from 'react-select';
import { find } from 'lodash';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../../../helpers/shared_helpers';
import { treeTypes } from '../../../../helpers/tree_helpers';

const NodeTypeSelector = observer(({ node }) => {
  const handleChange = selectedValue => {
    const val = selectedValue.value;
    node.updateNodeOnServer({ node_type: val }).then(res => {
      if (res.data.success) {
        node.tree.update({ nodeType: val });
      }
    });
  };
  const { t } = useTranslation();
  const types = treeTypes(t);
  return (
    <div className="status-selector">
      <Label>{t('sidebar.tree_type')}</Label>
      <Select
        options={types}
        value={find(types, { value: node.tree.nodeType })}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
});

NodeTypeSelector.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default NodeTypeSelector;
