import React from 'react';

import { makeAutoObservable } from 'mobx';
import BoardStore from './stores/board_store';
import CommentStore from './stores/comment_store';
import NodeAssigneeStore from './stores/node_assignee_store';
import CollaboratorStore from './stores/collaborator_store';
import NodeStore from './stores/node_store';
import TagStore from './stores/tag_store';
import UserStore from './stores/user_store';
import TreeStore from './stores/tree_store';
import SidebarStore from './stores/sidebar_store';
import NodeAttachmentStore from './stores/node_attachment_store';
import notify from '../views/shared/notification';
import ActionCableStore from './stores/actioncable_store';
import Playlist from './entities/playlist';
import ProductStore from './stores/product_store';
import i18n from '../i18n';
import GroupStore from './stores/group_store';
import NotificationStore from './stores/notification_store';
import AppStore from './stores/app_store';

export default class RootStore {
  constructor() {
    this.notification = notify;
    this.translate = i18n.t;
    this.app = new AppStore(this);
    this.users = new UserStore(this);
    this.boards = new BoardStore(this);
    this.trees = new TreeStore(this);
    this.comments = new CommentStore(this);
    this.nodeAssignees = new NodeAssigneeStore(this);
    this.nodeCollaborators = new CollaboratorStore(this, 'nodes');
    this.boardCollaborators = new CollaboratorStore(this, 'boards');
    this.nodeGroups = new GroupStore(this, 'node');
    this.userGroups = new GroupStore(this, 'user');
    this.tags = new TagStore(this);
    this.nodes = new NodeStore(this);
    this.sidebars = new SidebarStore(this);
    this.nodeAttachments = new NodeAttachmentStore(this);
    this.playlist = new Playlist(this);
    this.products = new ProductStore(this);
    this.notifications = new NotificationStore(this);
    this.cable = new ActionCableStore(this);

    makeAutoObservable(this);
  }
}
