import { action, computed, flow, makeAutoObservable, observable } from 'mobx';

import { camelCase, find, head, isEmpty, map, uniqBy } from 'lodash';
import client from '../axiosClient';
import Board from '../entities/board';

class BoardStore {
  /* eslint-disable */
  @observable records = [];
  @observable fetching = true;
  @observable lastOpenedBoard= null;
  @observable form;
  @observable templates = [];
  @observable fetchedTemplates = false;
  @observable fetchingTemplates = false;
  defaultTemplate = { id: 'template1', name: 'New', description: 'No description', isNew: true, criteria: {} };
  /* eslint-enable */

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  getById(id) {
    const record = this.records.find(r => r.id === id);

    return record;
  }

  @computed
  get templatesAsSelectOptions() {
    if (this.fetchedTemplates)
      return map([this.defaultTemplate, ...this.templates], r => ({
        label: r.name,
        value: r.id,
      }));

    if (!this.fetchingTemplates) this.getTemplates();

    return [];
  }

  @computed
  get boardsAsSelectOptions() {
    return map(this.records, r => ({ label: r.name, value: r.id }));
  }

  @computed
  get activeBoard() {
    if (!isEmpty(this.lastOpenedBoard)) {
      return this.getById(this.lastOpenedBoard.value);
    }
    const b = isEmpty(this.records) ? null : head(this.records);
    if (!isEmpty(this.records))
      setTimeout(() => this.updateActiveBoard(b.id), 200);
    return b;
  }

  @action
  onTemplateSelect(template) {
    const temp = find([this.defaultTemplate, ...this.templates], {
      id: template.value,
    });

    this.form = new Board({ ...temp, isNew: true }, this);
  }

  @action
  updateActiveBoard(boardId, updateServer = true) {
    this.lastOpenedBoard = isEmpty(boardId)
      ? null
      : find(this.boardsAsSelectOptions, {
          value: boardId,
        });
    if (!isEmpty(this.activeBoard)) this.activeBoard.markAsViewed();
    if (updateServer) this.updateActiveBoardOnServer();
  }

  @action
  setRecords(records, initial = true) {
    const oldRecords = initial ? [] : [...this.records];
    this.records = uniqBy(
      [
        ...oldRecords,
        ...map(records, r => {
          if (!isEmpty(r.collaborators)) {
            this.rootStore.boardCollaborators.setRecords(r.collaborators);
          }
          return new Board(r, this);
        }),
      ],
      'id'
    );
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  initializeForm() {
    this.form = new Board(this.defaultTemplate, this);
  }

  @flow
  *fetchMyBoards() {
    const response = yield client.get('/api/v1/boards.json');
    if (response.data.success) {
      this.setRecords(response.data.results);
      if (!isEmpty(response.data.last_opened_board)) {
        this.updateActiveBoard(response.data.last_opened_board, false);
      }
      this.fetching = false;
    }
  }

  @flow
  *updateActiveBoardOnServer() {
    return yield client.put('/api/v1/boards/last_opened_board.json', {
      last_opened_board: this.lastOpenedBoard.value,
    });
  }

  @flow
  *getTemplates() {
    this.update({ fetchingTemplates: true });
    const response = yield client.get('/api/v1/boards/templates.json');

    this.update({
      fetchingTemplates: false,
      fetchedTemplates: true,
      templates: response.data.templates,
    });
  }
}

export default BoardStore;
