import { action, flow, makeObservable } from 'mobx';
import { camelCase, map } from 'lodash';
import client from '../axiosClient';

class BaseEntity {
  store;

  constructor(value, store) {
    makeObservable(this);

    this.store = store;
  }

  @action
  initialize(params) {
    this.update(params);
  }

  @action
  update(params, updateServer) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );

    if (updateServer) this.updateServer(params);
  }

  @flow
  *updateServer(params) {
    yield client.put(this.updateUrl, params);
  }
}

export default BaseEntity;
