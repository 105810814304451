import React, { useEffect, useState } from 'react';

import { Col, Container, Nav, Row, TabContent } from 'reactstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { isEmpty, map } from 'lodash';
import useStore from '../../data/store';
import SettingsNavItem from './SettingsNavItem';
import SettingsPane from './SettingsPane';
import UserProfile from './UserProfile';
import UserAccount from './UserAccount';
import UserContacts from './UserContacts';
import AppLayout from '../../layouts/App';
import GuestRestriction from './GuestRestriction';

const Settings = observer(({ location }) => {
  const { users: userStore } = useStore();
  const [activeTab, setActiveTab] = useState('profile');
  const { t } = useTranslation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (location.state && location.state.tab) setActiveTab(location.state.tab);
    else if (!isEmpty(searchParams.get('tab')))
      setActiveTab(searchParams.get('tab'));
  }, [location]);

  const userModules = [
    {
      title: t('labels.your_profile'),
      description: t('manage.profile'),
      icon: 'user',
      tabId: 'profile',
      widget: <UserProfile />,
    },
    {
      title: t('labels.account'),
      description: t('manage.account'),
      icon: 'briefcase',
      tabId: 'account',
      widget: <UserAccount location={location} />,
    },
    {
      title: t('labels.contacts'),
      description: t('manage.contacts'),
      icon: 'address-book',
      tabId: 'contacts',
      widget: <UserContacts />,
    },
  ];

  const tabs = [];
  const panes = [];

  map(userModules, (tab, i) => {
    tabs.push(
      <SettingsNavItem
        title={tab.title}
        icon={tab.icon}
        tabId={tab.tabId}
        active={activeTab === tab.tabId}
        tag={Link}
        key={i}
        to={{ pathname: '/settings', state: { tab: tab.tabId } }}
      />
    );
    panes.push(
      <SettingsPane
        key={i}
        title={tab.description}
        tabId={tab.tabId}
        widget={<GuestRestriction>{tab.widget}</GuestRestriction>}
      />
    );

    return tab;
  });

  return (
    <AppLayout loading={userStore.fetchingCurrentUser}>
      <Container fluid>
        <Row className="user-settings">
          <Col md={1} className="user-settings-nav pt-5">
            <Nav pills tabs vertical>
              {tabs}
            </Nav>
          </Col>
          <Col md={10} className="h-100 mr-1">
            <TabContent activeTab={activeTab}>{panes}</TabContent>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
});

Settings.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default Settings;
