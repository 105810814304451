import {
  makeObservable,
  observable,
  action,
  computed,
  flow,
  extendObservable,
} from 'mobx';
import {
  camelCase,
  filter,
  includes,
  isEmpty,
  isEqual,
  map,
  upperCase,
} from 'lodash';

import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import Account from './account';
import client from '../axiosClient';
import { parameterize } from '../../helpers/shared_helpers';
import selectable from '../mixins/selectable';

class User {
  /* eslint-disable */
  id;
  isAccountAdmin;
  @observable lastHeartbeatAt;
  @observable isInstanceAdmin;
  @observable firstname = 'You';
  @observable lastname = '@amalati';
  @observable account;
  @observable locale = 'en';
  @observable userBackgroundPreference = 'random';
  @observable updatingProfile = false;
  @observable updatingPassword = false;
  @observable contacts = [];
  @observable notifications = [];
  @observable assignedGroups = [];
  @observable fetchingContacts = false;
  @observable fetchedContacts = false;
  @observable hasActiveSubscription = false;
  @observable managedByYou = false;
  @observable guest = false;
  @observable apiKey = '';
  @observable onboarded = [];
  store;
  email;

  ONLINE = "online"
  OFFLINE = "offline"
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);
    this.store = store;
    map(
      Object.keys(value),
      function(k) {
        if (!isEmpty(value[k]) && isEqual(k, 'account'))
          return (this.account = new Account(value[k], this.store));
        this[camelCase(k)] = value[k];
      }.bind(this)
    );
    extendObservable(this, selectable);
  }

  @computed
  get isYou() {
    const { currentUser } = this.store;

    return isEqual(currentUser.id, this.id);
  }

  @computed
  get treeImageBackgroundsEnabled() {
    return this.userBackgroundPreference === 'random' || this.isInstanceAdmin;
  }

  @computed
  get initials() {
    return upperCase(`${this.firstname[0]||'X'}${this.lastname[0]||'X'}`);
  }

  @computed
  get fullName() {
    if (isEmpty(this.email)) return 'Unknown User';

    return `${this.firstname} ${this.lastname}`;
  }

  @computed
  get name() {
    return this.fullName;
  }

  @computed
  get asSelectOption() {
    return { value: this.id, label: this.fullName };
  }

  @computed
  get onlineStatus() {
    if (this.isYou) return this.ONLINE;
    if (isEmpty(this.lastHeartbeatAt)) return this.OFFLINE;
    const timeSince = (moment() - moment(this.lastHeartbeatAt)) / 1000;

    if (timeSince > 45) return this.OFFLINE;
    return this.ONLINE;
  }

  @computed
  get canAssignLicense() {
    return (
      this.isAccountAdmin &&
      this.account.hasSubscription &&
      this.account.subscription.availableLicenses > 0
    );
  }

  @computed
  get hasAvailableLicence() {
    return (this.account.subscription?.availableLicenses || 0) > 0;
  }

  @computed
  get generalOnboarded() {
    return includes(Object.keys(this.onboarded), 'general');
  }

  @computed
  get managedByMe() {
    return filter(this.store.records, {
      managedByYou: true,
      hasActiveSubscription: true,
    });
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @flow
  *onboard(params, onComplete) {
    const response = yield client.post(`/api/v1/users/onboard.json`, params);
    this.update({ onboarded: response.data.onboarded });

    if (response.data.success) {
      confirmAlert({
        title: 'Onboarding Successful',
        message: 'You have been successfully onboarded.',
        buttons: [{ label: 'Done', onClick: onComplete }],
        closeOnClickOutside: false,
        closeOnEscape: false,
      });
    }
  }

  @flow
  *updatePassword(params) {
    if (this.updatingPassword) return false;

    this.update({ updatingPassword: true });

    const response = yield client.put(
      '/api/v1/users/change_password.json',
      parameterize(params)
    );

    this.store.rootStore.notification(
      response.data.success ? 'success' : 'danger',
      this.store.rootStore.translate(
        `notifications.users.password.${response.data.message}`
      )
    );

    this.update({ updatingPassword: false });

    return true;
  }

  @flow
  *updateProfile(params) {
    if (this.updatingProfile) return false;

    this.update({ updatingProfile: true });

    const response = yield client.put(`/api/v1/users/${this.id}.json`, params);

    this.store.rootStore.notification(
      response.data.success ? 'success' : 'danger',
      this.store.rootStore.translate(
        `notifications.users.profile.${response.data.message}`
      )
    );

    if (response.data.success) this.update(params);

    this.update({ updatingProfile: false });

    return true;
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *unlinkMe() {
    const response = yield client.put(`/api/v1/billing/unlink_me.json`);
    if (response.data.success) {
      this.store.fetchCurrentUser();
    }
    return response.data.success;
  }

  @flow
  *fetchMyContacts() {
    if (this.fetchingContacts || this.fetchedContacts) return;

    const response = yield client.get('/api/v1/users/contacts.json');

    if (response.data.success) {
      this.update({
        fetchingContacts: false,
        fetchedContacts: true,
      });

      this.store.setRecords(response.data.results);
    }
  }

  @flow
  *myTemplates(tType) {
    this.request = yield client.get(`/api/v1/users/my_templates?type=${tType}`);

    return this.request.data.results;
  }

  @flow
  *heartbeat() {
    const response = yield client.get('/api/v1/users/heartbeat.json');
    // eslint-disable-next-line no-unused-expressions
    this.onlineStatus;
    if (response.data.success) {
      this.update({ lastHeartbeatAt: response.data.last_heartbeat_at });
      this.store.updateContactHeartbeats(
        response.data.contacts_last_heartbeats
      );
    }
    return response.data.success;
  }

  @flow
  *saveGuest(params) {
    const response = yield client.put('/api/v1/users/save_guest.json', params);

    return response.data;
  }
}

export default User;
