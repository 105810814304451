import React from 'react';

import { observer } from 'mobx-react';
import useStore from '../../../../data/store';
import Form from './Form';
import { getCommonValues } from '../../../../helpers/shared_helpers';

const NodeAssign = observer(({ nodes, viewType }) => {
  const { users } = useStore();
  const assignees = users.getByIds(getCommonValues(nodes, 'assignees'));

  return (
    <div className="node-assignees">
      <Form viewType={viewType} nodes={nodes} assignees={assignees} />
    </div>
  );
});

export default NodeAssign;
