import React from 'react';

import { observer } from 'mobx-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Player from '../../player';
import NodeDescription from '../node_description';
import useStore from '../../../../data/store';
import QuizForm from './QuizForm';

const PlayableArea = ({ node, children, onEnded, disableTitle, className }) => {
  const nodeOnlyText = !node.hasFeaturedVideo && !node.hasThumbnail;
  const { playlist } = useStore();

  return (
    <div
      className={classnames('node-play-area', {
        'playing-quiz': playlist.playingQuiz,
        [className]: true,
      })}
    >
      {!disableTitle && <h3>{node.name}</h3>}
      <div className="node-player">
        {playlist.playingQuiz ? (
          <QuizForm node={node} />
        ) : (
          <>
            <div className={classnames({ 'd-none': !node.hasFeaturedVideo })}>
              <Player
                onEnded={onEnded}
                url={node.featuredAttachment?.attachmentUrl}
              />
            </div>
            {!node.hasFeaturedVideo && node.hasThumbnail && (
              <img src={node.thumbnailUrl} alt="" />
            )}
            {nodeOnlyText && <NodeDescription node={node} summary />}
          </>
        )}
        {children}
      </div>
    </div>
  );
};

PlayableArea.defaultProps = {
  onEnded: () => {},
  disableTitle: false,
  className: '',
};

PlayableArea.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
  disableTitle: PropTypes.bool,
  onEnded: PropTypes.func,
  className: PropTypes.string,
};

export default observer(PlayableArea);
