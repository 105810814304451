import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import MultiSelectable from './MultiSelectable';
import DirectionToggleSet from './DirectionToggleSet';
import ZoomToggle from './ZoomToggle';
import CanvasViewToggle from './CanvasViewToggle';
import SearchInputs from '../../../modules/search';
import ControlBarExpander from './ControlBarExpander';
import StarredList from './StarredList';
import Loader from '../../../../layouts/shared/Loader';
import CanvasReset from './CanvasReset';

const ControlBar = observer(({ tree }) => {
  const [showNav, setShowNav] = useState(false);
  const { t } = useTranslation();

  const toggle = () => setShowNav(!showNav);

  useEffect(() => {
    if (!tree.store.rootStore.users.userSignedIn)
      tree.updateCurrentState({
        controlBarView: 'expanded',
      });
  }, []);

  if (tree.fetchingNodes)
    return <Loader text={t('control_bar.fetching_tree')} />;

  return (
    <div
      className={classnames(
        'control-bar',
        `control-bar-${tree.controlBarView}`,
        { 'nav-mobile': showNav }
      )}
    >
      <ControlBarExpander tree={tree} />
      <div className={`ml-2 control-bar-${tree.controlBarView}`}>
        <Button onClick={toggle} color="success" className="control-nav-toggle">
          <FontAwesomeIcon icon="bars" />
        </Button>
        <div className="control-nav-wrapper">
          {!tree.store.rootStore.app.embedded && (
            <CanvasViewToggle tree={tree} />
          )}
          <CanvasReset tree={tree} />
          <div className="control-bar-divider" />
          <DirectionToggleSet tree={tree} />
          <div className="control-bar-divider" />
          <ZoomToggle tree={tree} />
          {tree?.rootNode?.canChange && (
            <>
              <div className="control-bar-divider" />
              <MultiSelectable tree={tree} />
              <div className="control-bar-divider" />
              <StarredList tree={tree} />
            </>
          )}
        </div>
        <div className="control-bar-divider" />
        <div className="control-bar-search">
          <SearchInputs tree={tree} sidebar disable={['dueDate']} />
        </div>
      </div>
    </div>
  );
});

ControlBar.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default ControlBar;
