import React, { useState } from 'react';

import { Button } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { isEmpty, map } from 'lodash';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import Attachment from './Attachment';
import NodeAttachment from '../../../../data/entities/node_attachment';
import Form from './Form';

const NodeAttachments = ({ node, summary, treeAttachmentsView }) => {
  const [formObj, setFormObj] = useState(null);
  const handleAdd = () =>
    setFormObj(
      new NodeAttachment(
        { id: null, nodeId: node.id },
        node.store.rootStore.nodeAttachments
      )
    );

  const { t } = useTranslation();

  return (
    <div className="node-attachments">
      {!isEmpty(formObj) ? (
        <Form attachment={formObj} reset={() => setFormObj(null)} />
      ) : (
        <>
          {!summary && !treeAttachmentsView && (
            <Button onClick={handleAdd} color="success w-100">
              {t('sidebar.attachments.add')}
            </Button>
          )}
          {treeAttachmentsView && (
            <h5 className="text-muted">{t('sidebar.attachments.tree')}</h5>
          )}
          <div className="attachment-list">
            {isEmpty(node.attachments) ? (
              <div className="text-left text-muted mt-3">
                {!treeAttachmentsView && t('sidebar.attachments.none')}
                {treeAttachmentsView && t('sidebar.attachments.tree_none')}
              </div>
            ) : (
              map(node.attachments, (a, i) => (
                <Attachment
                  summary={summary}
                  treeAttachmentsView={treeAttachmentsView}
                  attachment={a}
                  key={i}
                  edit={() =>
                    setFormObj(
                      new NodeAttachment(
                        {
                          id: a.id,
                          attachmentTitle: a.attachmentTitle,
                          attachmentInstructions: a.attachmentInstructions,
                          featured: a.featured,
                        },
                        node.store.rootStore.nodeAttachments
                      )
                    )
                  }
                />
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

NodeAttachments.defaultProps = {
  summary: false,
};

NodeAttachments.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
  summary: PropTypes.bool,
  treeAttachmentsView: PropTypes.instanceOf(Boolean).isRequired,
};

export default observer(NodeAttachments);
