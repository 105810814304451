import {
  action,
  computed,
  extendObservable,
  flow,
  makeAutoObservable,
  observable,
} from 'mobx';
import {
  camelCase,
  filter,
  find,
  includes,
  isEmpty,
  isEqual,
  map,
  reject,
  uniqBy,
  sortBy,
} from 'lodash';

import client from '../axiosClient';
import Group from '../entities/group';
import {
  applySearchQuery,
  INSTANCE_WIDE_GROUPS_ID,
} from '../../helpers/shared_helpers';
import selectableStore from '../mixins/selectable_store';

class GroupStore {
  /* eslint-disable */
  @observable records = [];
  @observable activeGroupId = '';
  @observable searchQuery = '';
  @observable sortType = 'name';
  @observable sortMode = 'asc';

  storeType;
  /* eslint-enable */

  constructor(rootStore, type) {
    this.rootStore = rootStore;
    this.storeType = type;

    makeAutoObservable(this);
    extendObservable(this, selectableStore);
  }

  getById(id) {
    return find(this.records, { id });
  }

  getBySlug(slug) {
    return find(
      this.records,
      group => isEqual(group.slug, slug) && !isEqual(group.id, 'default')
    );
  }

  getByIds(ids) {
    return filter(this.records, r => includes(ids, r.id));
  }

  groupableByIds(ids) {
    return filter(this.groupables, groupable => includes(ids, groupable.id));
  }

  @computed
  get filteredGroups() {
    return sortBy(applySearchQuery(this.searchQuery, this.records), [
      this.sortType,
    ]);
  }

  @computed
  get filteredSystem() {
    return filter(this.filteredGroups, { scope: INSTANCE_WIDE_GROUPS_ID });
  }

  @computed
  get filteredExceptSystem() {
    return filter(
      this.filteredGroups,
      group => !isEqual(group.scope, INSTANCE_WIDE_GROUPS_ID)
    );
  }

  @computed
  get defaultGroup() {
    return find(this.records, { id: 'default' });
  }

  @computed
  get groupables() {
    if (this.isNodeType) return this.rootStore.trees.records;
    if (this.isContactType) return this.rootStore.users.records;

    return [];
  }

  @computed
  get isNodeType() {
    return isEqual(this.storeType, 'node');
  }

  @computed
  get isContactType() {
    return isEqual(this.storeType, 'user');
  }

  @computed
  get activeGroup() {
    return find(this.records, { id: this.activeGroupId });
  }

  @computed
  get hasActiveGroup() {
    return !isEmpty(this.activeGroup);
  }

  @computed
  get hasRecords() {
    return !isEmpty(this.records);
  }

  @computed
  get groupableStore() {
    return this.isNodeType ? this.rootStore.trees : this.rootStore.users;
  }

  @action
  selectActiveGroup(activeGroupId) {
    this.activeGroupId = activeGroupId;
  }

  @action
  deselectActiveGroup() {
    this.activeGroupId = '';
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setRecords(records) {
    this.records = uniqBy([...this.records, ...records], 'id');
  }

  @action
  newRecord(record) {
    return new Group(record, this);
  }

  @action
  addToRecords(record) {
    this.records.push(this.newRecord(record));
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @flow
  *searchGroups(inputValue = '') {
    const response = yield* this.searchOrFetchGroups(inputValue);

    return map(response, res => res.asSelectOption);
  }

  @flow
  *searchOrFetchGroups(inputValue = '') {
    const response = yield client.get(
      `/api/v1/groups.json?group_type=${this.storeType}&query=${inputValue}`
    );
    if (isEmpty(response)) return;
    return map(response.data.groups, group => new Group(group, this));
  }

  @flow
  *fetchGroups() {
    const response = yield* this.searchOrFetchGroups();
    if (isEmpty(response)) return;
    const allTreesGroup = new Group(
      { name: 'All', scope: 'public', id: 'default' },
      this
    );

    if (isEqual(this.storeType, 'node')) {
      this.update({
        records: [allTreesGroup],
      });
    }

    this.setRecords(response);

    const systemGroup = find(this.records, { scope: INSTANCE_WIDE_GROUPS_ID });

    if (systemGroup) {
      systemGroup.open();
    } else if (isEqual(this.storeType, 'node')) {
      allTreesGroup.open();
    }
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *addItemsToMyGroups(items, groups) {
    const response = yield client.post(
      '/api/v1/groups/add_items_to_groups.json',
      {
        type: this.storeType,
        items,
        groups,
      }
    );
    // process the update of the contacts with their groups here.
    return response.data.success;
  }

  @flow
  *addByRecordIds(group, recordIds) {
    const response = yield client.post('/api/v1/groups/group.json', {
      record_ids: recordIds,
      group,
      type: this.storeType,
    });

    if (response.data.success) {
      const addedGroup = response.data.group;

      if (group.__isNew__) this.addToRecords(addedGroup);

      const groupables = this.groupableByIds(recordIds);

      map(groupables, groupable => {
        if (!isEmpty(find(groupable.assignedGroups, { id: addedGroup.id })))
          return;

        groupable.update({
          assignedGroups: [...groupable.assignedGroups, addedGroup],
        });
      });

      this.selectActiveGroup(addedGroup.id);
    }
  }

  @flow
  *destroyByRecordIds(groupId, recordIds) {
    const response = yield client.put('/api/v1/groups/ungroup.json', {
      group_id: groupId,
      record_ids: recordIds,
      type: this.storeType,
    });

    if (response.data.success) {
      const groupables = this.groupableByIds(recordIds);

      map(groupables, groupable => {
        const params = {
          assignedGroups: reject(groupable.assignedGroups, g =>
            isEqual(g.id, groupId)
          ),
        };
        if (isEqual(this.activeGroupId, groupId)) params.selected = false;
        groupable.update(params);
      });
    }
  }

  @flow
  *updateMultiple(groupIds, params, additionalParams = {}) {
    const response = yield client.put('/api/v1/groups/bulk_update.json', {
      ...params,
      ...additionalParams,
      group_ids: groupIds,
    });

    if (response.data.success) {
      map(groupIds, groupId => {
        const group = this.getById(groupId);
        if (isEmpty(group)) return;

        group.update(params);

        if (additionalParams.with_groupables)
          map(group.records, record => {
            if (!includes(response.data.affected_groups, record.id)) return;
            record.update(params);
          });
      });
    }
  }
}

export default GroupStore;
