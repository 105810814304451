import React, { useState } from 'react';

import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { isEmpty, isEqual, map } from 'lodash';
import Select from 'react-select/async/dist/react-select.esm';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import RichEditor from '../rich_text_editor';
import { customStyles } from '../../../helpers/shared_helpers';

const Share = ({ record, type }) => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState(null);
  const iMsg = t('sidebar.share.personalised_message', {
    name: record.name,
    type: type || 'tree',
  });

  const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    email: '',
    access_type: '',
    message: iMsg,
  };

  const [params, setParams] = useState(INITIAL_STATE);
  const paramsKeys = ['firstname', 'lastname', 'email'];

  const handleChange = e =>
    setParams({ ...params, [e.target.name]: e.target.value });

  const handleSubmit = e => {
    e.preventDefault();

    debugger;
    let anyBlank = false;

    if (isEmpty(userId)) {
      map(paramsKeys, p => {
        anyBlank ||= isEmpty(params[p]);
      });
    }

    if (anyBlank) return;

    if (!isEmpty(userId)) {
      map(userId, u => {
        record
          .inviteCollaborator({
            ...params,
            message: params.message,
            invitation_id: u.value,
          })
          .then(() => {
            console.log('Done inviting');
          });
      });
      setParams(INITIAL_STATE);
      setUserId(null);
    } else {
      record
        .inviteCollaborator({
          ...params,
          message: params.message,
          invitation_id: null,
        })
        .then(() => {
          setParams(INITIAL_STATE);
          setUserId(null);
        });
    }
    map(userId, u => {});
  };

  return (
    <div>
      <h5 className="text-muted">
        {t('sidebar.share.section_labels.existing')}
      </h5>
      <Form onSubmit={handleSubmit}>
        <FormGroup className={classnames({ 'm-0': isEmpty(userId) })}>
          <Select
            isMulti
            placeholder={t('sidebar.share.inputs.search_placeholder')}
            styles={customStyles}
            value={userId}
            loadOptions={inputValue =>
              record.store.rootStore.users.searchNetwork(
                inputValue,
                false,
                true
              )
            }
            onChange={e => {
              setParams(INITIAL_STATE);
              setUserId(e);
            }}
            isClearable
          />
        </FormGroup>
        {isEmpty(userId) && (
          <>
            <div className="or-divider mt-0">
              {t('sidebar.share.section_labels.or')}
            </div>
            <h5 className="text-muted">
              {t('sidebar.share.section_labels.new')}
            </h5>
            <Row>
              {map(paramsKeys, (p, i) => (
                <Col key={i} xl={isEqual(p, 'email') ? 12 : 6}>
                  <FormGroup>
                    <Input
                      type={isEqual(p, 'email') ? 'email' : 'text'}
                      placeholder={`Enter ${p}..`}
                      value={params[p]}
                      name={p}
                      onChange={handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              ))}
            </Row>
          </>
        )}
        <FormGroup>
          <Input
            type="select"
            name="access_type"
            onChange={handleChange}
            value={params.access_type}
            required
          >
            <option value="">Access Type</option>
            <option value="edit_and_share">Edit and share</option>
            <option value="view_only">View only</option>
          </Input>
        </FormGroup>

        <RichEditor
          defaultValue=""
          value={params.message}
          onChange={st =>
            handleChange({ target: { name: 'message', value: st } })
          }
        />

        <Button color="success" type="submit" className="w-100 mt-3">
          Invite
        </Button>
      </Form>
    </div>
  );
};

Share.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired,
};

export default Share;
