import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import useStore from '../../data/store';
import UserContactsItem from './contacts/UserContactsItem';
import ListPageLayout from '../shared/ListPageLayout';
import ContactGroup from './components/users/ContactGroup';
import GroupContacts from './contacts/GroupContacts';
import ControlBar from '../shared/control_bar';

const UserContacts = () => {
  const { users: store, userGroups } = useStore();

  useEffect(() => {
    store.currentUser.fetchMyContacts();
    userGroups.fetchGroups();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="user-contacts">
      <div className="card-common">
        <ListPageLayout
          controlBarExpanded={store.controlBarExpanded}
          layoutClassName="contacts-view-layout"
          actionsClassName="control-bar"
          showControlBar={store.hasSelected}
          actionsWidget={
            <ControlBar controlType="user" selectedStore={store} />
          }
        >
          <div style={{ clear: 'both' }}>
            <h3>{t('labels.contacts')}</h3>
            {userGroups.hasActiveGroup ? (
              <GroupContacts />
            ) : (
              <div className="d-flex flex-wrap align-items-start">
                {store.hasRecords ? (
                  <>
                    {map(userGroups.records, (group, i) => (
                      <ContactGroup group={group} key={i} />
                    ))}
                    {map(store.usersWithoutGroup, (contact, i) => (
                      <UserContactsItem contact={contact} key={i} />
                    ))}
                  </>
                ) : (
                  <div className="no-contacts">{t('contacts.empty')}</div>
                )}
              </div>
            )}
          </div>
        </ListPageLayout>
      </div>
    </div>
  );
};
export default observer(UserContacts);
