import { makeAutoObservable, action, observable, flow, computed } from 'mobx';
import { find, isEmpty, isEqual, map, uniqBy } from 'lodash';

import Collaborator from '../entities/collaborator';
import client from '../axiosClient';

class CollaboratorStore {
  /* eslint-disable */
  @observable records = [];
  storeType;
  /* eslint-enable */

  constructor(rootStore, type) {
    this.rootStore = rootStore;
    this.storeType = type;

    makeAutoObservable(this);
  }

  getById(id) {
    return find(this.records, { id });
  }

  accessTypes = () => ({
    view_only: 'Can View',
    edit_and_share: 'Can Edit & Share',
    revoke: 'Revoke Access',
  });

  @action
  resetRecords() {
    this.records = [];
  }

  @computed
  get modelName() {
    return isEqual(this.storeType, 'boards') ? 'Board' : 'Node';
  }

  @action
  setRecords(records) {
    this.records = uniqBy(
      [...this.records, ...map(records, r => this.newRecord(r))],
      'id'
    );
  }

  @action
  newRecord(record) {
    const { users: userStore } = this.rootStore;

    userStore.checkAndAdd(record.user);
    if (!isEmpty(record.invited_by)) userStore.checkAndAdd(record.invited_by);

    return new Collaborator(record, this);
  }

  @action
  addToRecords(record) {
    this.records.push(this.newRecord(record));
  }

  @flow
  *fetchCollaborators(id, type = 'all', inputValue = '') {
    if (!this.rootStore.users.userSignedIn) return { data: { success: false } };
    return yield client.get(
      `/api/v1/collaborators.json?record_type=${this.modelName}&record_id=${id}&type=${type}&query=${inputValue}`
    );
  }

  @flow
  *invite(recordId, params) {
    const response = yield client.post(
      `/api/v1/collaborators.json?record_type=${this.modelName}&record_id=${recordId}`,
      params
    );

    if (response.data.success) {
      this.resetRecords();
      this.setRecords(response.data.collaborators);
      this.rootStore.notification(
        'success',
        'Invitation has been sent to the user(s)'
      );
      return true;
    }

    this.rootStore.notification('danger', response.data.message);

    return false;
  }
}

export default CollaboratorStore;
