import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmbedTutorial from './EmbedTutorial';

const Embed = ({ node }) => (
  <div className="node-embed">
    <div className="quiz-toggle d-flex justify-content-between align-items-center">
      <Label className="m-0">Node Embed</Label>
      <Button
        onClick={() => node.update({ embed: !node.embed }, true)}
        color="link m-0 p-0"
      >
        <FontAwesomeIcon
          icon={node.embed ? 'toggle-on' : 'toggle-off'}
          style={{ fontSize: 24 }}
        />
      </Button>
    </div>
    {node.embed && <EmbedTutorial node={node} />}
  </div>
);

Embed.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Embed);
