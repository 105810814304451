import React, { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { Redirect as RedirectTo, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const GroupShow = ({ match }) => {
  if (isEmpty(match?.params?.id)) return <RedirectTo to="/" />;

  return (
    <RedirectTo
      to={{ pathname: '/public/trees', state: { group: match.params.id } }}
    />
  );
};

GroupShow.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default observer(GroupShow);
