import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import NodeAssignees from './NodeAssignees';

const NodeAdditionalDetails = observer(({ node }) => {
  if (node.fetching || node.isOrphanContainerNode) return null;
  return (
    <div className="cell-additional-details">
      {!isEmpty(node.dueDate) && (
        <div className="cell-due-date">
          Due: {moment(node.dueDate).format('YYYY-MM-DD')}
        </div>
      )}
      <div className="cell-assignee-list">
        <NodeAssignees node={node} />
      </div>
    </div>
  );
});

NodeAdditionalDetails.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default NodeAdditionalDetails;
