import { camelCase, isEmpty, isEqual, join, map } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { sanitizedName } from '../../helpers/node_helpers';

class BoardItem {
  /* eslint-disable */
  id;
  status;
  name;
  rootNode;
  parent;
  @observable fetched = false;
  /* eslint-enable */

  constructor(value, board) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
        this.board = board;
      }.bind(this)
    );
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  ensureFetched() {
    if (this.fetched) return true;
    this.node.fetch();
    this.update({ fetched: true });
  }

  @computed
  get cardJson() {
    this.ensureFetched();
    return {
      id: this.node.id,
      title: sanitizedName(this.node.name),
      label: this.cardLabel,
      description: this.formattedDescription,
      draggable: this.node.canChange,
    };
  }

  @computed
  get formattedDescription() {
    if (isEmpty(this.parent.id) || isEqual(this.parent.id, this.rootNode.id))
      return this.rootNode.name;

    return `${this.rootNode.name}...${this.parent.name}`;
  }

  @computed
  get cardLabel() {
    const rtn = [];
    if (!isEmpty(this.node.dueDateFormatted))
      rtn.push(this.node.dueDateFormatted);
    if (!isEmpty(this.node.assigneesSummary))
      rtn.push(`[${this.node.assigneesSummary}]`);

    return join(rtn, ' - ');
  }

  @computed
  get node() {
    const n = this.board.store.rootStore.nodes.getById(this.id);

    return isEmpty(n)
      ? this.board.store.rootStore.nodes.addNode({
          id: this.id,
          name: this.name,
          status: this.status,
        })
      : n;
  }

  @action
  destroy() {
    this.board.boardItems.splice(this.board.boardItems.indexOf(this), 1);
  }
}

export default BoardItem;
