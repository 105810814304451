import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import classnames from 'classnames';
import jQuery from 'jquery';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import Loader from './shared/Loader';
import useStore from '../data/store';
import PublicNav from './PublicNav';
import PrivateNav from './PrivateNav';
import AmalatiEmbedComponent from '../views/shared/_amalati_embed';

const isBeta = jQuery('meta[name="beta"]')[0]?.content === 'yes';

const AppLayout = ({
  children,
  loading,
  className,
  scrollable,
  style,
  publiclyVisible,
  sidebarNp,
}) => {
  const { app, users: userStore } = useStore();
  useEffect(() => {
    if (!app.embedded) {
      app.setHeader('appNav', publiclyVisible ? <PublicNav key={0}/> : <PrivateNav key={0}/>);
    }
  }, [publiclyVisible]);

  const history = useHistory();
  return (
    <div
      style={style}
      className={classnames({
        'main-app main-layout': true,
        beta: isBeta,
        [className]: true,
        scrollable,
        'sidebar-np': sidebarNp,
      })}
    >
      <header className="fixed-top">{app.headers}</header>
      {loading && <Loader />}
      {!loading && (
        <main>
          {children}
          {!app.embedded &&
            !isEmpty(app.onboardingNodeId) &&
            userStore.showGeneralOnBoarding && (
              <AmalatiEmbedComponent
                nodeId={app.onboardingNodeId}
                onComplete={() =>
                  userStore.currentUser.onboard(
                    { onboarding: 'general' },
                    () => {
                      if (!isEmpty(app.gettingStartedTreeId))
                        history.push(`/trees/${app.gettingStartedTreeId}`);
                    }
                  )
                }
              />
            )}
        </main>
      )}
    </div>
  );
};

AppLayout.defaultProps = {
  loading: true,
  className: '',
  scrollable: true,
  style: {},
  publiclyVisible: false,
  sidebarNp: true,
};

AppLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  className: PropTypes.string,
  scrollable: PropTypes.bool,
  style: PropTypes.instanceOf(Object),
  publiclyVisible: PropTypes.bool,
  sidebarNp: PropTypes.bool,
};

export default observer(AppLayout);
