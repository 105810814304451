import React from 'react';
import { Col, Row, TabPane } from 'reactstrap';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';

const SettingsPane = observer(({ title, tabId, widget }) => (
  <TabPane tabId={tabId}>
    <Row>
      <Col sm="12">
        <h4 className="text-muted">{title}</h4>
        <hr />
        {widget}
      </Col>
    </Row>
  </TabPane>
));

SettingsPane.propTypes = {
  title: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  widget: PropTypes.node,
};

export default SettingsPane;
