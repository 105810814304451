import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const NodeCollapsible = observer(({ node }) => {
  const { t } = useTranslation();

  if (node.fetching) return null;

  if (node.isExternal)
    return (
      <Button
        className="btn-collapse"
        onClick={() => node.createAndSetTab()}
        title={t('nodes.actions.tooltip.popout')}
      >
        <FontAwesomeIcon icon="share-square" data-toggle="tooltip" />
      </Button>
    );

  if (!node.hasChildren) return null;

  return (
    <Button className="btn-collapse" onClick={() => node.expandNode()}>
      <FontAwesomeIcon
        icon={node.expanded ? 'chevron-up' : 'chevron-down'}
        data-toggle="tooltip"
        title={t('nodes.actions.tooltip.collapse')}
      />
    </Button>
  );
});

NodeCollapsible.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default NodeCollapsible;
