import React from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

const ListPageLayout = ({
  filterWidget,
  children,
  actionsWidget,
  showControlBar,
  actionsClassName,
  layoutClassName,
  controlBarExpanded,
}) => (
  <div
    className={classnames('list-page-layout', {
      [layoutClassName]: !isEmpty(layoutClassName),
    })}
  >
    <div className="list-filter">{filterWidget}</div>
    <div className="list-view">{children}</div>
    <div
      className={classnames('list-actions', {
        [actionsClassName]: !isEmpty(actionsClassName),
        show: showControlBar,
        'action-expanded': controlBarExpanded,
      })}
    >
      {showControlBar && actionsWidget}
    </div>
  </div>
);

ListPageLayout.defaultProps = {
  showControlBar: false,
  actionsClassName: '',
  layoutClassName: '',
  controlBarExpanded: false,
};

ListPageLayout.propTypes = {
  filterWidget: PropTypes.object,
  children: PropTypes.node,
  actionsWidget: PropTypes.object,
  showControlBar: PropTypes.bool,
  actionsClassName: PropTypes.string,
  layoutClassName: PropTypes.string,
  controlBarExpanded: PropTypes.bool,
};

export default ListPageLayout;
