import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { Label } from 'reactstrap';
import useStore from '../../data/store';

const AmalatiEmbedComponent = ({
  className,
  labelText,
  onComplete,
  show,
  nodeId,
  hostName,
  labelID,
  onClick,
  onStop,
}) => {
  const [embed, setEmbed] = useState(null);
  const { app: appStore } = useStore();

  useEffect(() => {
    const node = new window.AmalatiEmbed({
      nodeId,
      apiKey: appStore.amalatiEmbedApiKey,
      hostName,
      onStop: () => {
        if (isFunction(onStop)) {
          onStop();
        }
      },
    });

    node.init();

    setEmbed(node);

    if (show) node.start(onComplete);

    return () => node.destroy();
  }, []);

  useEffect(() => {
    if (embed) {
      if (show) {
        embed.start(onComplete);
      } else {
        embed.stop();
      }
    }
  }, [show]);

  return (
    <>
      {isFunction(onClick) && (
        <Label tag="span" id={labelID} onClick={onClick} className={className}>
          {labelText}
        </Label>
      )}
    </>
  );
};

AmalatiEmbedComponent.defaultProps = {
  className: '',
  labelText: 'Start',
  hostName: 'https://app.amalati.com',
  show: true,
  labelID: '',
};

AmalatiEmbedComponent.propTypes = {
  nodeId: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string,
  onComplete: PropTypes.func,
  show: PropTypes.bool,
  hostName: PropTypes.string,
  labelID: PropTypes.string,
  onClick: PropTypes.func,
  onStop: PropTypes.func,
};

export default AmalatiEmbedComponent;
