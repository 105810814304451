import React, { useState } from 'react';

import {
  Collapse,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { isEmpty } from 'lodash';
import useStore from '../data/store';
import { KEYCLOAK_AUTH_URL } from '../helpers/shared_helpers';
import BrandNavbar from './shared/BrandNavbar';
import EmbeddedPromoNavbar from './shared/EmbeddedPromoNavbar';
import GuestAlert from '../views/guest/GuestAlert';

const PublicNav = () => {
  const { users: userStore, app } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Navbar dark expand="lg">
      <BrandNavbar />
      {app.embedded && <EmbeddedPromoNavbar />}
      {!app.embedded && (
        <>
          <GuestAlert />
          {app.isGuestUrl && app.update({ guestAlert: true })}
          <NavbarToggler className="text-success" onClick={toggle}>
            <FontAwesomeIcon icon="bars" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {!isEmpty(app.contactId) && (
                <NavItem>
                  <NavLink
                    className="text-success"
                    style={{ cursor: 'pointer' }}
                    onClick={() => app.update({ showBookingCalendar: true })}
                  >
                    Book a call with our team{' '}
                  </NavLink>
                </NavItem>
              )}

              <NavItem className="mr-2">
                <NavLink
                  className="text-danger"
                  style={{ cursor: 'pointer' }}
                  onClick={() => app.update({ guestAlert: true })}
                >
                  {t('nav.links.guest_mode')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/public/trees">
                  {t('nav.links.public_trees')}
                </NavLink>
              </NavItem>
              {userStore.userSignedIn ? (
                <NavItem>
                  <NavLink tag={Link} to="/trees">
                    {t('nav.links.trees')}
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem>
                  <NavLink href={KEYCLOAK_AUTH_URL} id="link_sign_in_up">
                    {t('nav.links.sign_in_up')}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </>
      )}
    </Navbar>
  );
};

export default observer(PublicNav);
