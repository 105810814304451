import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import Switch from '../../../shared/Switch';
import useStore from '../../../../data/store';

const Form = ({ product }) => {
  const [quantity, setQuantity] = useState(1);
  const { products: store } = useStore();
  useEffect(() => {
    if (product.userAccount.hasSubscription && product.isUserProduct) {
      setQuantity(product.userAccount.subscription.quantity);
    }
  }, [product]);

  const handleAdd = () => setQuantity(quantity + 1);
  const handleSubscription = () => {
    product.userAccount.subscribe({
      product_id: product.id,
      quantity,
      interval: store.selectedPlanInterval,
    });
  };
  const handleSub = () => {
    if (quantity <= 1) return;

    setQuantity(quantity - 1);
  };

  const discount = product.discount(quantity);
  const { t } = useTranslation();
  const monthly = isEqual(store.selectedInterval, 'month');
  const totalPrice = product.formattedProductPrice(quantity);
  const unitPrice = totalPrice / quantity;
  return (
    <div className="d-flex flex-column flex-grow-1">
      {product.isPaid && (
        <>
          <Row>
            <Col md={7} className="quantity-and-price">
              <div className="quantity-toggle">
                <Button
                  color="success"
                  disabled={isEqual(quantity, 1)}
                  onClick={handleSub}
                >
                  <FontAwesomeIcon icon="minus" />
                </Button>

                <Input
                  value={`${quantity} ${quantity > 1 ? 'users' : 'user'}`}
                  onChange={() => {}}
                />

                <Button color="success" onClick={handleAdd}>
                  <FontAwesomeIcon icon="plus" />
                </Button>
              </div>
            </Col>
            <Col md={5} className="sidebar-tab-togglers pt-2">
              <Switch
                handleToggle={() =>
                  store.update({
                    selectedPlanInterval: monthly ? 'year' : 'month',
                  })
                }
                isOn={monthly}
                text={t(`account.plan.${monthly ? 'monthly' : 'yearly'}`)}
              />
            </Col>
          </Row>
        </>
      )}
      <Row className="text-center flex-grow-1">
        <Col md={12}>
          <div className="product-price">
            £{totalPrice}
            <span className="text-muted" style={{ fontSize: 14 }}>
              {product.isPaid && '/'}
              {product.interval}
              {product.isPaid && (
                <div>
                  {t('account.plan.works_out')} £{unitPrice}/
                  {t('account.plan.user')}
                </div>
              )}
            </span>
          </div>
        </Col>
        {product.isPaid && (
          <Col
            md={12}
            style={{ fontSize: 12 }}
            className="text-muted text-center ml-2 pt-2 w-25"
          >
            {discount > 0 ? (
              <>
                <strong>Includes {discount}% discount</strong>
              </>
            ) : (
              t('account.plan.volume_discount', { discount: '20%' })
            )}
            <div>
              <strong>
                {t(`account.plan.${store.selectedInterval}_savings`)}
              </strong>
            </div>
          </Col>
        )}
        <Col md={12} className="mt-auto">
          {product.isPaid ? (
            <Button
              color="success w-100"
              disabled={product.isUserProduct}
              onClick={() => handleSubscription()}
            >
              {t(`account.plan.actions.subscribe`)}
            </Button>
          ) : (
            <Alert className="mb-0" color="success">
              {t(`account.plan.current`)}
            </Alert>
          )}
        </Col>
      </Row>
    </div>
  );
};

Form.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Form);
