import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';

import ControlBarButton from './Button';

const ControlBarExpander = observer(({ tree }) => {
  const pValue = () =>
    isEqual(tree.controlBarView, 'expanded') ? 'minimized' : 'expanded';

  const expanderButtonClick = () => {
    const params = {
      controlBarView: pValue(),
    };
    if (tree.store.rootStore.users.userSignedIn) {
      tree.updateCurrentState(params);
    } else {
      tree.update(params);
    }
  };

  return (
    <div className="control-bar-btn">
      <ControlBarButton
        tree={tree}
        paramKey=""
        paramValue=""
        active
        handleClickOverride={expanderButtonClick}
      >
        <FontAwesomeIcon
          icon={
            isEqual(tree.controlBarView, 'minimized')
              ? 'angle-double-right'
              : 'angle-double-left'
          }
          data-toggle="tooltip"
          title="Expand or minimize the control bar"
        />
      </ControlBarButton>
    </div>
  );
});

ControlBarExpander.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default ControlBarExpander;
