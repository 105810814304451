import React, { useState } from 'react';

import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { isEmpty, isEqual } from 'lodash';

import useStore from '../../data/store';
import { KEYCLOAK_AUTH_URL } from '../../helpers/shared_helpers';

const SaveUser = () => {
  const {
    app,
    users: { currentUser },
  } = useStore();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const toggle = () => app.update({ saveUser: false });

  const handleSubmit = e => {
    e.preventDefault();

    if (
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(password) ||
      isEmpty(passwordConfirmation)
    )
      return;

    if (password.length < 8) {
      return app.rootStore.notification(
        'danger',
        t(`users.guest.save_user_modal.errors.password`)
      );
    }

    if (!isEqual(password, passwordConfirmation)) {
      return app.rootStore.notification(
        'danger',
        t(`users.guest.save_user_modal.errors.password_confirmation`)
      );
    }

    currentUser
      .saveGuest({
        user: {
          firstname: firstName,
          lastname: lastName,
          password,
          password_confirmation: passwordConfirmation,
        },
      })
      .then(res => {
        app.rootStore.notification(
          res.success ? 'success' : 'danger',
          t(`users.guest.save_user_modal.errors.${res.message}`)
        );

        if (res.success) window.location.href = KEYCLOAK_AUTH_URL;
      });
  };

  if (isEmpty(currentUser)) return null;

  return (
    <Modal isOpen={app.saveUser} toggle={toggle} backdrop="static">
      <Button className="toggle-modal" onClick={toggle}>
        &times;
      </Button>
      <ModalHeader>{t('users.guest.save_user_modal.heading')}</ModalHeader>
      <ModalBody>
        <Alert color="info">{t('users.guest.save_user_modal.info')}</Alert>
        <Form className="common-inputs" onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <Input
                  placeholder={t('form_fields_firstname')}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <Input
                  required
                  placeholder={t('form_fields_lastname')}
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Input
              placeholder={t('form_fields_email')}
              value={currentUser.email}
              type="email"
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Input
              required
              type="password"
              placeholder={t('form_fields_password')}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <small className="text-muted">
              Password should be at least 8 character
            </small>
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              required
              placeholder={t('form_fields_confirm_password')}
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
            />
          </FormGroup>
          <Button className="w-100 mt-3" color="success" type="submit">
            {t('form.actions.submit')}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default observer(SaveUser);
