import {
  action,
  computed,
  extendObservable,
  flow,
  makeObservable,
  observable,
} from 'mobx';
import { isEqual, map, reject } from 'lodash';

import client from '../axiosClient';
import BaseEntity from './base_entity';
import selectable from '../mixins/selectable';

class Group extends BaseEntity {
  /* eslint-disable */
  id;
  @observable name;
  @observable scope;
  slug;
  owner;
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);
    extendObservable(this, selectable);

    this.initialize(value);
  }

  @computed
  get isOwner() {
    if (!this.store.rootStore.users.userSignedIn) return false;
    if (this.store.rootStore.users.currentUser.isInstanceAdmin) return true;
    return isEqual(this.store.rootStore.users.currentUser.id, this.owner);
  }

  @computed
  get updateUrl() {
    return `/api/v1/groups/${this.id}.json`;
  }

  @computed
  get asSelectOption() {
    return { label: this.name, value: this.id };
  }

  @computed
  get records() {
    switch (this.store.storeType) {
      case 'node':
        return this.store.rootStore.trees.getByGroupId(undefined, this.id);
      default:
        return [];
    }
  }

  @computed
  get checkIfSelectable() {
    return this.store.rootStore.trees.hasSelected || !this.isOwner;
  }

  @computed
  get isOpened() {
    return isEqual(this.store.activeGroupId, this.id);
  }

  @computed
  get default() {
    return isEqual(this.id, 'default');
  }

  @action
  destroy(updateServer = false) {
    if (this.isOpened) this.close();

    this.store.records.splice(this.store.records.indexOf(this), 1);
    map(this.store.groupables, groupable =>
      groupable.update({
        assignedGroups: reject(groupable.assignedGroups, { id: this.id }),
      })
    );

    if (updateServer) this.destroyOnServer();
  }

  @action
  open() {
    this.store.deselectAll();
    this.store.groupableStore.deselectAll();
    this.store.selectActiveGroup(this.id);
    this.store.rootStore.trees.fetch()
  }

  @action
  close() {
    this.store.deselectAll();
    this.store.groupableStore.deselectAll();
    this.store.deselectActiveGroup();
    this.store.defaultGroup.open();
  }

  @flow
  *destroyOnServer() {
    yield client.delete(`/api/v1/groups/${this.id}.json`);
  }
}

export default Group;
