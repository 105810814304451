import { action, computed, flow, makeObservable, observable } from 'mobx';
import {
  camelCase,
  filter,
  find,
  includes,
  indexOf,
  isEmpty,
  isEqual,
  isFunction,
  join,
  lowerCase,
  map,
  sortBy,
} from 'lodash';
import jQuery from 'jquery';

import Tree from './tree';
import client from '../axiosClient';
import { formatDate, parameterize } from '../../helpers/shared_helpers';
import { getUserIdsFromAssignees } from '../../helpers/node_assignee_helpers';
import Tag from './tag';
import {
  deselectAllText,
  sanitizedDescription,
  selectAllText,
} from '../../helpers/node_helpers';
import Board from './board';

class Node {
  /* eslint-disable */
  id; root; rootId; createdAt; bodyId; treeOwner; new = false;   store;


  @observable parentId;
  @observable name;

  @observable updatedAt;
  @observable description;
  @observable scope;
  @observable dueDate;
  @observable estimate;
  @observable goal;
  @observable position;
  @observable status = 'pending';
  @observable expanded;
  @observable nodeType = 'general';
  @observable publicationStatus = 'published_all';
  @observable linkedNodeId;

  // Default
  @observable protectedCanShare = false;
  @observable protectedCanAccess = false;
  @observable protectedCanDelete = false;
  @observable protectedCanChange = false;
  @observable isOwner = false;
  @observable selected = false;

  @observable removing = false;
  @observable fetching = false;
  @observable fetched = false;
  @observable orphanNode;
  @observable dragging;
  @observable temporaryName = '';
  @observable starred = false;
  @observable ref;
  @observable focused = false;
  @observable externalised = false;
  @observable backgroundUrl = 'random';
  @observable quiz = { enabled: false, questions: [] };
  @observable quizAnswers = [];
  @observable treeName;
  @observable embed = false;
  @observable poppedOutFromId = null;
  @observable linkedNode = {};

  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'comments'))
          return store.rootStore.comments.setRecords(value[k]);

        if (isEqual(k, 'tags'))
          return store.rootStore.tags.setRecords(value[k]);

        if (isEqual(k, 'node_assignees'))
          return store.rootStore.nodeAssignees.setRecords(value[k]);

        if (isEqual(k, 'attachments'))
          return store.rootStore.nodeAttachments.setRecords(value[k]);

        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.bodyId = `cell-${value.id}`;
    this.store = store;
  }

  @computed
  get eventJson() {
    return {
      id: this.id,
      title: this.name,
      start: this.dueDate ? new Date(this.dueDate) : null,
      end: this.dueDate ? new Date(this.dueDate) : null,
    };
  }

  @computed
  get hasDescription() {
    return !isEmpty(sanitizedDescription(this.description || ''));
  }

  @computed
  get isPlayable() {
    return this.hasFeaturedVideo || this.hasDescription;
  }

  @computed
  get canChange() {
    if (this.store.rootStore.app.embedded) return false;
    return this.protectedCanChange;
  }

  @computed
  get canShare() {
    if (this.store.rootStore.app.embedded) return false;
    return this.protectedCanShare;
  }

  @computed
  get canDelete() {
    if (this.store.rootStore.app.embedded) return false;
    return this.protectedCanDelete;
  }

  @computed
  get canAccess() {
    if (this.store.rootStore.app.embedded) return false;
    return this.protectedCanAccess;
  }

  @computed
  get isTag() {
    return this.name.startsWith('#');
  }

  @computed
  get hasFeaturedAttachment() {
    return !isEmpty(this.featuredAttachment);
  }

  @computed
  get hasFeaturedVideo() {
    return this.hasFeaturedAttachment && this.featuredAttachment.isVideo;
  }

  @computed
  get featuredAttachment() {
    return find(this.attachments, { featured: true });
  }

  @computed
  get assigneesSummary() {
    return join(
      map(this.assignees, ass => ass.user.initials),
      ','
    );
  }

  @computed
  get children() {
    if (this.isExternal) return [];
    if (this.isTag) return this.tag.nodes;

    return this.childNodes;
  }

  @computed
  get tagName() {
    return this.name.substring(1);
  }

  @computed
  get tag() {
    return (
      find(this.tree.tags, t =>
        isEqual(lowerCase(t.name), lowerCase(this.tagName))
      ) || new Tag({ id: '', name: this.tagName }, this.store.rootStore.tags)
    );
  }

  @computed
  get hasChildren() {
    return this.children.length > 0;
  }

  @computed
  get computedStatus() {
    if (this.children.length === 0) {
      return this.status;
    }

    const statusCounts = this.getStatusCounts;

    if (isEmpty(statusCounts)) {
      return this.status;
    }
    if (statusCounts.blocked > 0) {
      return 'blocked';
    }
    if (statusCounts.started > 0) {
      return 'started';
    }
    if (statusCounts.completed > 0) {
      if (statusCounts.completed === this.children.length) {
        return 'completed';
      }
      return 'started';
    }

    return this.status;
  }

  @computed
  get computedStatuses() {
    const statuses = {};
    statuses[this.status] = 1;

    const singleStatus = { count: 1, statuses };

    if (this.children.length === 0) return singleStatus;

    const statusCounts = this.getStatusCounts;

    if (isEmpty(statusCounts)) {
      return singleStatus;
    }

    return { count: this.children.length, statuses: statusCounts };
  }

  @computed
  get orphans() {
    return filter(this.store.records, { parentId: null, root: false });
  }

  @computed
  get isOrphan() {
    return isEmpty(this.parentId) && !this.root;
  }

  @computed
  get hasOrphans() {
    return this.orphans.length > 0;
  }

  @computed
  get isOrphanContainerNode() {
    return isEqual(this.id, 'orphan');
  }

  @computed
  get treeId() {
    return this.tree.id || this.rootId;
  }

  @computed
  get nodeActionsPopoverId() {
    return `node_actions_${this.id}`;
  }

  @computed
  get tree() {
    return (
      this.store.rootStore.trees.getById(this.rootId) ||
      new Tree({ id: this.rootId }, this.store.rootStore.trees)
    );
  }

  @computed
  get tags() {
    return filter(this.store.rootStore.tags.records, { nodeId: this.id });
  }

  @computed
  get comments() {
    return filter(this.store.rootStore.comments.records, { nodeId: this.id });
  }

  @computed
  get collaborators() {
    return this.tree.collaborators;
  }

  @computed
  get assignees() {
    return filter(this.store.rootStore.nodeAssignees.records, {
      nodeId: this.id,
    });
  }

  @computed
  get isAssignedToMe() {
    return !isEmpty(
      find(this.assignees, {
        userId: this.store.rootStore.users.currentUser.id,
      })
    );
  }

  @computed
  get attachments() {
    return filter(this.store.rootStore.nodeAttachments.records, {
      nodeId: this.id,
    });
  }

  @computed
  get parent() {
    return this.store.getById(this.parentId);
  }

  @computed
  get parentAsSelectOption() {
    if (isEmpty(this.parent)) return null;

    return { label: this.parent.name, value: this.parent.id };
  }

  @computed
  get isVisible() {
    if (this.root) return true;

    if (isEmpty(this.parent)) return false;
    if (!this.parent.expanded) return false;

    return this.parent.isVisible;
  }

  @computed
  get isInViewport() {
    const el = document.getElementById(`cell-${this.id}`);
    if (!el) return false;

    const rect = el.getBoundingClientRect();

    if (!this.isVisible) return false;

    return (
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left <
        (window.innerWidth ||
          document.documentElement.clientWidth) /* or $(window).width() */ &&
      rect.top <
        (window.innerHeight ||
          document.documentElement.clientHeight) /* or $(window).height() */
    );
  }

  @computed
  get showPlaceholders() {
    return (
      this.tree.drag.dragging &&
      this.isInViewport &&
      !this.dragging &&
      !this.isOrphanContainerNode
    );
  }

  @computed
  get childNodes() {
    return sortBy(
      filter(this.store.records, r => isEqual(r.parentId, this.id)),
      ['position']
    );
  }

  @computed
  get dueDateFormatted() {
    return formatDate(this.dueDate);
  }

  @computed
  get hasAssignedToMe() {
    const userIds = getUserIdsFromAssignees(this.assignees);
    return includes(userIds, this.store.rootStore.users.currentUser.id);
  }

  @computed
  get hasTags() {
    return !isEmpty(this.tags);
  }

  @computed
  get getStatusCounts() {
    const statusCounts = {
      blocked: 0,
      started: 0,
      completed: 0,
      pending: 0,
    };

    this.children.map(node => {
      if (node.isTag) return false;

      const nStatus = node.computedStatus;
      if (nStatus === undefined) return false;

      const sCount = statusCounts[nStatus] || 0;
      statusCounts[nStatus] = sCount + 1;

      return false;
    });

    return statusCounts;
  }

  @computed
  get isExternal() {
    if (isEqual(this.tree.activeTab.nodeId, this.id)) return false;

    return this.externalised;
  }

  @computed
  get computedPublicationStatus() {
    const pubStatuses = ['draft', 'published_restricted', 'published_all'];
    const comparatorPubStatus =
      this.parent?.computedPublicationStatus || this.publicationStatus;
    const idxComparatorPubStatus = indexOf(pubStatuses, comparatorPubStatus);
    const idxMyPubStatus = indexOf(pubStatuses, this.publicationStatus);
    if (idxMyPubStatus < idxComparatorPubStatus) return this.publicationStatus;
    return comparatorPubStatus;
  }

  @computed
  get summarise() {
    const summaryStr = [];
    summaryStr.push(this.name);
    summaryStr.push(this.description);
    if (this.children.length > 0) {
      summaryStr.push('<ul>');
    }
    map(this.children, c => {
      summaryStr.push(`<li>${c.summarise}</li>`);
    });
    if (this.children.length > 0) {
      summaryStr.push('</ul>');
    }
    return summaryStr.join('<br/>');
  }

  @computed
  get masterTree() {
    return this.tree.store.masterTree;
  }

  @computed
  get hasThumbnail() {
    return !isEmpty(this.thumbnailUrl);
  }

  @computed
  get showPlayButton() {
    if (!this.tree.isTutorial) return false;
    if (this.root)
      return !isEmpty(this.store.rootStore.playlist.videos) || this.isPlayable;

    return this.isPlayable;
  }

  @computed
  get thumbnailUrl() {
    if (
      this.root &&
      this.tree.isTutorial &&
      !this.hasFeaturedAttachment &&
      !isEmpty(this.store.rootStore.playlist.videos)
    ) {
      if (this.store.rootStore.playlist.videos[0].featuredAttachment)
        return this.store.rootStore.playlist.videos[0].featuredAttachment
          .previewLink;

      return null;
    }

    if (this.hasFeaturedAttachment) return this.featuredAttachment.previewLink;

    return null;
  }

  @computed
  get enabledQuizQuestions() {
    return filter(this.quiz.questions, { enabled: true });
  }

  @computed
  get quizCompleted() {
    if (!this.quizEnabled) return true;

    return isEqual(this.quizAnswers.length, this.enabledQuizQuestions.length);
  }

  @computed
  get showQuiz() {
    return this.quizEnabled && !this.quizCompleted;
  }

  @computed
  get quizEnabled() {
    return this.quiz.enabled && !isEmpty(this.enabledQuizQuestions);
  }

  @action
  revealTab() {
    this.masterTree.rootNode.createAndSetTab(this.rootId);
  }

  @action
  expandParent() {
    if (isEmpty(this.parent)) return null;
    if (!this.parent.expanded) this.parent.toggleExpand();

    this.parent.expandParent();
  }

  @action
  expandNode() {
    this.store.clearExpandedNodes();
    this.toggleExpand();
  }

  @action
  focusOn(sidebar = false, selectText = true, disableReveal = false) {
    deselectAllText();
    map(this.store.records, r => r.blur());
    this.focused = true;
    this.toggleSelected(sidebar, disableReveal ? true : this.isInViewport);
    const ref = jQuery(`#cell-${this.id} .cell-edit`)[0];
    if (selectText) selectAllText(ref);
  }

  @action
  createAndSetTab(id = this.linkedNodeId) {
    const tab = find(this.tree.store.masterTree.tabs, {
      nodeId: id,
    });

    if (!isEmpty(tab)) return tab.setActive();

    const newTab = { nodeId: this.linkedNodeId, active: true, name: this.name };

    this.tree.store.masterTree.update({
      tabs: [
        ...map(this.tree.store.masterTree.tabs, t => ({
          ...t.asJSON,
          active: false,
        })),
        newTab,
      ],
    });

    this.tree.store.setActiveTree(newTab.nodeId);
    this.tree.store.masterTree.updateTabs();
  }

  @action
  blur() {
    this.focused = false;
  }

  @action
  handleNameUpdate(name, updateServer) {
    let newName = name;

    while (this.hasTags && newName.startsWith('#')) {
      newName = newName.substring(1);
    }

    this.name = newName;

    if (updateServer) {
      this.updateNodeOnServer({ name: newName });
    }
  }

  @action
  update(params, updateServer = false, cb = null) {
    map(
      Object.keys(params),
      function(k) {
        if (isEqual(k, 'name')) {
          return this.handleNameUpdate(params[k], updateServer);
        }

        if (isEqual(k, 'comments'))
          return this.store.rootStore.comments.setRecords(params[k]);

        if (isEqual(k, 'tags'))
          return this.store.rootStore.tags.setRecords(params[k]);

        if (isEqual(k, 'node_assignees'))
          return this.store.rootStore.nodeAssignees.setRecords(params[k]);

        if (isEqual(k, 'attachments'))
          return this.store.rootStore.nodeAttachments.setRecords(params[k]);

        this[camelCase(k)] = params[k];
      }.bind(this)
    );

    if (updateServer && !includes(Object.keys(params), 'name')) {
      this.updateNodeOnServer(parameterize(params)).then(() => {
        if (isFunction(cb)) cb();
      });
    }
  }

  @action
  deepDestroy() {
    if (!isEmpty(this.childNodes))
      map(this.childNodes, node => node.deepDestroy());

    this.softDestroy();
  }

  @action
  softDestroy() {
    if (
      !isEmpty(this.store.rootStore.playlist.active) &&
      isEqual(this.store.rootStore.playlist.active.id, this.id)
    )
      this.store.playlist.reset();
    this.store.records.splice(this.store.records.indexOf(this), 1);
  }

  @action
  setChildrenAsOrphans() {
    map(this.childNodes, n => n.update({ parentId: null }));
  }

  @action
  toggleSelected(enableSidebar = false, disableReveal = false) {
    if (this.tree.multiSelect) {
      if (this.root) return;

      this.update({ selected: !this.selected });
      return;
    }

    if (enableSidebar) {
      this.store.rootStore.sidebars.addSidebar({
        type: 'node',
        nodeIds: [this.id],
        treeName: this.tree.name,
      });
      this.store.markAsViewed(this.id);
    }

    this.tree.deselectAllNodes();
    this.update({ selected: true });

    if (!disableReveal) this.reveal();
  }

  @action
  deleteAndSetOrphans() {
    const tab = find(this.tree.tabs, { nodeId: this.id });
    if (!isEmpty(tab)) tab.destroy();

    if (!this.isTag) {
      this.setChildrenAsOrphans();
    }

    this.softDestroy();
  }

  @action
  setFetched() {
    this.fetching = false;
    this.fetched = true;
    this.new = false;
  }

  @action
  refreshAssignees() {
    this.store.rootStore.nodeAssignees
      .fetchingNodeAssignees(this.id, false)
      .then(records => {
        map(this.assignees, a => a.destroy());
        this.store.rootStore.nodeAssignees.setRecords(records);
      });
  }

  @action
  refreshAttachments() {
    this.store.rootStore.nodeAttachments
      .fetchNodeAttachments(this.id, false)
      .then(records => {
        map(this.attachments, a => a.destroyRecord());
        this.store.rootStore.nodeAttachments.setRecords(records);
      });
  }

  @action
  refreshComments() {
    this.store.rootStore.comments
      .fetchNodeComments(this.id, false)
      .then(records => {
        map(this.comments, a => a.softDestroy());

        this.store.rootStore.comments.setRecords(records);
      });
  }

  @action
  refreshTags() {
    this.store.rootStore.tags.fetchNodeTags(this.id, false).then(records => {
      map(this.tags, a => a.destroy());

      this.store.rootStore.tags.setRecords(records);
    });
  }

  @action
  blurInput() {
    this.blur();

    jQuery(`#cell-${this.id} .cell-edit`).trigger('blur');
  }

  @action
  popout(res, reveal = true) {
    this.store.rootStore.sidebars.clearAll();
    const newNode = new Node(
      { ...res.data.node, new: true, protectedCanChange: true },
      this.store
    );
    this.store.records.push(newNode);

    if (reveal) newNode.createAndSetTab();
    newNode.fetch();

    this.deepDestroy();
  }

  @action
  updateStatus(status) {
    this.store.updateNodes([this.id], {
      action_type: 'work',
      status,
    });
  }

  @action
  undoPopout(res) {
    this.store.rootStore.sidebars.clearAll();

    const tabs = filter(this.tree.store.masterTree.tabs, {
      nodeId: this.linkedNodeId,
    });

    if (!isEmpty(tabs)) map(tabs, tab => tab.destroy());

    // TODO: Experimental, This has to change when two or more nodes share the same tree
    const tree = this.tree.store.getById(this.linkedNodeId);
    if (tree) {
      tree.resetAssociations();
      tree.softDestroy();
    }

    map(res.data.nodes, node => {
      const n = this.store.getById(node.id);

      if (isEmpty(n)) {
        const newNode = new Node(node, this.store);
        this.store.records.push(newNode);
        newNode.fetch();
      } else {
        n.update(node);
      }
    });

    this.softDestroy();
  }

  @flow
  *createBoard() {
    if (!this.isTag) return;
    if (!this.canChange) return false;

    const description = `Board created from ${this.name} ${
      this.isTag ? `with tag ${this.tag.name}` : ''
    }`;
    const board = new Board(
      {
        isNew: true,
        name: `${this.name}'s Board`,
        description,
        criteria: {
          trees: [{ value: this.rootId }],
          tags: [{ value: this.tag.id }],
        },
      },
      this.store.rootStore.boards
    );

    return yield board.submit();
  }

  @flow
  *refresh() {
    const response = yield client.get(`/api/v1/nodes/${this.id}.json`);
    if (response.data.success) {
      this.update(response.data.node);
    }
  }

  @flow
  *fetch() {
    this.fetching = true;

    const response = yield client.get(`/api/v1/nodes/${this.id}.json`);

    try {
      if (response.data.success) {
        this.update(response.data.node);

        this.store.rootStore.nodeAssignees
          .fetchingNodeAssignees(this.id)
          .then(() => {
            this.store.rootStore.tags
              .fetchNodeTags(this.id)
              .then(() => {
                this.store.rootStore.comments
                  .fetchNodeComments(this.id)
                  .then(() => {
                    this.store.rootStore.nodeAttachments
                      .fetchNodeAttachments(this.id)
                      .then(() => {
                        this.setFetched();
                      })
                      .catch(() => this.setFetched());
                  })
                  .catch(() => this.setFetched());
              })
              .catch(() => this.setFetched());
          })
          .catch(() => this.setFetched());
      } else {
        this.setFetched();
      }
    } catch (e) {
      this.setFetched();
    }
  }

  @flow
  *destroy() {
    this.removing = true;
    const response = yield* this.store.updateNodes([this.id], {
      action_type: 'remove_all',
    });
    return response.data.success;
  }

  @flow
  *toggleExpand(forceCollapse = false) {
    // eslint-disable-next-line no-nested-ternary
    const expanded = forceCollapse ? 0 : this.expanded ? 0 : 1;
    const response = yield client.put(
      `/api/v1/nodes/${this.treeId}/current_state.json`,
      { ex_n_id: this.id, ex_n: expanded }
    );
    if (response.data.success) {
      const newValue = forceCollapse ? false : !this.expanded;
      this.store.toggleNodeExpand(this.id, newValue);
      this.update({ expanded: newValue });
      if (newValue) {
        setTimeout(() => this.reveal(), 400);
      }
    }
  }

  @flow
  *addChild(position = null) {
    if (this.tree.selectedNode) this.tree.selectedNode.blurInput();

    const response = yield client.post('/api/v1/nodes.json', {
      parent_id: this.id,
      name: 'Untitled',
      position,
    });

    if (response.data.success) {
      this.expanded = true;

      const addedNode = new Node(
        { ...response.data.node, new: true, protectedCanChange: true },
        this.store
      );

      this.store.records.push(addedNode);

      if (!isEmpty(response.data.sibling_nodes)) {
        map(response.data.sibling_nodes, n => {
          const node = this.store.getById(n.id);
          node.update({ position: n.position });
        });
      }

      setTimeout(() => {
        addedNode.fetch();
        addedNode.focusOn(false);
      }, 100);

      return;
    }

    this.store.rootStore.users.update({ showEnhance: response.data.message });
  }

  @flow
  *updateNodeOnServer(params) {
    if (!this.store.rootStore.users.userSignedIn)
      return { data: { success: false } };
    const response = yield client.put(`/api/v1/nodes/${this.id}.json`, params);

    this.store.rootStore.users.update({ showEnhance: response.data.message });

    return response;
  }

  @flow
  *updatePosition(params) {
    this.update(params);

    const response = yield client.put(
      `/api/v1/nodes/${this.id}/update_position.json`,
      params
    );
    if (response.data.success) {
      map(response.data.nodes, n => {
        const node = this.store.getById(n.id);
        node.update(n);
      });
    }
  }

  @flow
  *toggleStarred() {
    const response = yield client.post(
      `/api/v1/nodes/${this.id}/toggle_starred.json`
    );
    if (response.data.success) {
      this.update({ starred: !this.starred });
      if (!this.selected) {
        this.toggleSelected(false, true);
      }
    }
  }

  @flow
  *inviteCollaborator(params) {
    const response = yield* this.store.rootStore.nodeCollaborators.invite(
      this.id,
      params
    );

    return response;
  }

  @flow
  *reveal() {
    yield* this.expandParent();
    const elem = jQuery(`#cell-${this.id}:not(.tagged-node)`)[0];

    const scrollProperties = {
      inline: 'center',
      behavior: 'smooth',
      block: 'center',
    };
    this.focusOn(false, false, true);
    if (elem) {
      elem.scrollIntoView(scrollProperties);

      // jQuery('.tree-container-wrapper')[0].scrollTo(0, 0);
    }
  }

  @flow
  *pasteNodes(deep = false) {
    if (!this.store.hasCopiedNodes || this.masterTree.pastingNodes)
      return false;

    this.masterTree.update({ pastingNodes: true });

    const response = yield client.post(
      `/api/v1/nodes/${this.id}/paste_nodes.json`,
      {
        node_ids: this.store.copiedNodeIds,
        deep,
      }
    );

    this.store.rootStore.users.update({ showEnhance: response?.data?.message });

    this.masterTree.update({ pastingNodes: false });

    if (response.data.success) {
      map(response.data.nodes, n => {
        this.store.addNode({ ...n, fetched: true });
      });

      return true;
    }

    return false;
  }

  @action
  smartNavigate() {
    this.focusOn(false, false);
    this.collapseAllUnexpanded();
  }

  @action
  collapseAllUnexpanded() {
    const collapsedNodes = map(this.store.records, r => {
      if (!includes(this.store.expandedNodes, r.id)) {
        r.update({ expanded: false }, false);
        return r.id;
      }
    });
  }
}

export default Node;
