export const getBenefits = type => {
  switch (type) {
    case 'free':
      return [
        'free.shares',
        'free.trees',
        'free.nodes',
        'free.storage',
        'free.boards',
        'free.support',
      ];
    default:
      return [
        'unlimited.shares',
        'unlimited.trees',
        'unlimited.nodes',
        'unlimited.storage',
        'unlimited.boards',
        'unlimited.support',
        'unlimited.moneyback',
      ];
  }
};
