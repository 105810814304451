import React, { useState } from 'react';

import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../../../data/store';

const Profile = () => {
  const { users: store } = useStore();
  const { t } = useTranslation();

  const [userInfo, setUserInfo] = useState({
    firstname: store.currentUser.firstname,
    lastname: store.currentUser.lastname,
  });

  const handleSubmit = e => {
    e.preventDefault();

    if (isEmpty(userInfo.firstname) || isEmpty(userInfo.lastname)) return;

    store.currentUser.updateProfile(userInfo);
  };

  return (
    <div className="profile-form sidebar-common-inputs">
      <h3>{t('settings.profile.heading')}</h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label>{t('form_fields_firstname')}</Label>
              <Input
                value={userInfo.firstname}
                onChange={e =>
                  setUserInfo({ ...userInfo, firstname: e.target.value })
                }
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label>{t('form_fields_lastname')}</Label>
              <Input
                value={userInfo.lastname}
                onChange={e =>
                  setUserInfo({ ...userInfo, lastname: e.target.value })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>{t('form_fields_email')}</Label>
          <Input disabled value={store.currentUser.email} />
        </FormGroup>

        <Button color="success" className="mt-2" size="lg" type="submit">
          {store.currentUser.updatingProfile ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin" />
          ) : (
            t('form.actions.update')
          )}
        </Button>
      </Form>
    </div>
  );
};

export default observer(Profile);
