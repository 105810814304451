import { observable, makeObservable, action } from 'mobx';
import { camelCase, isEmpty, isEqual, isString, map } from 'lodash';

class Criteria {
  /* eslint-disable */
  @observable trees = [];
  @observable assignedTo = [];
  @observable dueDateFrom;
  @observable dueDateTo;
  @observable tags = [];
  board;
  /* eslint-enable */

  constructor(value, board) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'assigned_to')) {
          this.assignedTo = map(value[k], a => {
            if (isEqual(a, '$CURRENT_USER$')) {
              return board.store.rootStore.users.currentUser.asSelectOption;
            }
            return a;
          });
          return;
        }

        if (isEqual(k, 'due_date_to') && !isEmpty(value[k])) {
          this.dueDateTo = new Date(value[k]);
          return;
        }

        if (isEqual(k, 'due_date_from') && !isEmpty(value[k])) {
          this.dueDateFrom = new Date(value[k]);
          return;
        }

        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.board = board;
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }
}

export default Criteria;
