import React from 'react';

import { observer } from 'mobx-react';

import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import useStore from '../../../data/store';

const HelmetSettings = () => {
  const { trees, app } = useStore();

  return (
    <Helmet>
      <body
        className={classnames(
          'tree-view-canvas',
          `canvas-${trees.activeTree?.canvas}`,
          {
            embedded: app.embedded,
          }
        )}
      />
    </Helmet>
  );
};

export default observer(HelmetSettings);
