import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import classnames from 'classnames';

import { Button } from 'reactstrap';
import { isEqual, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useStore from '../../../data/store';
import Panel from './panel';

const Sidebar = observer(() => {
  const { sidebars, playlist } = useStore();
  const [width, setWidth] = useState(0);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const sidebar = sidebars.activeSidebar;
  const { t } = useTranslation();

  const handleSidebarClose = () => {
    sidebars.clearAll();
  };

  const handleScroll = () => {
    if (window.innerWidth < 550) {
      return setSidebarWidth(250);
    }

    setSidebarWidth(400);
  };

  useEffect(() => {
    handleScroll();

    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    setWidth(sidebars.records.length * sidebarWidth);
  }, [sidebars.records.length, sidebarWidth]);

  const activeSidebarIndex = sidebar?.index || 0;
  const transformValue = activeSidebarIndex * -sidebarWidth;
  const sidebarActions = () => {
    const actions = [];

    if (sidebar?.prevSidebarPresent) {
      actions.push(
        <Button className="mb-1" onClick={() => sidebar.gotoPrev()}>
          <FontAwesomeIcon
            icon="arrow-left"
            data-toggle="tooltip"
            title={t('sidebar.tabs.back.description')}
          />
        </Button>
      );
    }
    map(sidebar.tabs, (b, i) =>
      actions.push(
        <Button
          color={isEqual(b[0], sidebar.activeTab) ? 'success' : 'secondary'}
          className={classnames({
            active: isEqual(b[0], sidebar.activeTab),
          })}
          key={i}
          onClick={() => sidebar.update({ activeTab: b[0] })}
        >
          <FontAwesomeIcon
            icon={b[1]}
            data-toggle="tooltip"
            title={t(`sidebar.tabs.${b[0]}.description`)}
          />
        </Button>
      )
    );
    return actions;
  };

  return (
    <div
      className={classnames({
        'cell-sidebar': true,
        'sidebar-active': sidebars.showSidebar && !playlist.hasActive,
      })}
    >
      <div className="side-bar">
        {sidebar && (
          <div className="sidebar-btn-actions ">
            <div className="node-presenter-tabs actions-left-close-right">
              {sidebarActions()}
              <Button
                className="close-button mt-1"
                onClick={handleSidebarClose}
              >
                <FontAwesomeIcon
                  icon="times"
                  data-toggle="tooltip"
                  title={t('sidebar.tabs.close.description')}
                />
              </Button>
            </div>
          </div>
        )}
        <div
          className="sidebar-overflow-container"
          style={{ width: sidebarWidth, overflow: 'hidden' }}
        >
          <div
            className="d-flex sidebar-panel-container"
            style={{
              width,
              transform: `translateX(${transformValue}px)`,
              transition: 'all ease-in-out 400ms',
            }}
          >
            {map(sidebars.records, (sb, i) => (
              <Panel key={i} sidebar={sb} sidebarWidth={sidebarWidth} />
            ))}
          </div>
        </div>
      </div>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="back-drop" onClick={() => sidebars.clearAll()} />
    </div>
  );
});

export default Sidebar;
