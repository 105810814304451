import React, { useEffect } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { includes, isEmpty } from 'lodash';
import TreeShow from '../trees/show';
import BoardsIndex from '../boards';
import Sidebar from '../modules/sidebar';
import useStore from '../../data/store';
import Settings from '../settings';
import AuthRoute from './AuthRoute';
import PublicAuth from '../public/auth';
import Redirect from '../redirect';
import EnhancedUpgrade from '../modules/enhanced_upgrade';
import TreesIndex from '../trees';
import GroupShow from '../groups/show';
import BookingCalender from '../modules/promo_popup/BookingCalendar';
import SaveUser from '../guest/SaveUser';
import CalendarView from '../calendar';

const Routes = () => {
  const { users } = useStore();
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    users.fetchCurrentUser().then(res => {
      if (res) {
        users.rootStore.cable.startListeningToUserChannel();
        i18n.changeLanguage(users.currentUser.locale);
        return;
      }

      i18n.changeLanguage('en');
    });
  }, []);

  if (users.fetchingCurrentUser) return null;

  const redirectRoute = localStorage.getItem('redirectURL');

  if (!isEmpty(redirectRoute) && users.userSignedIn) {
    localStorage.removeItem('redirectURL');

    history.push(redirectRoute);
  }

  const skipPublic = includes(['/r/go_unlimited'], window.location.pathname);

  return (
    <>
      <Switch>
        <Route exact path="/" component={TreesIndex} />
        <Route exact path="/public/auth" component={PublicAuth} />
        <Route exact path="/public/trees" component={TreesIndex} />
        <Route exact path="/trees/:id" component={TreeShow} />
        <Route exact path="/groups/:id" component={GroupShow} />

        <AuthRoute exact path="/trees" component={TreesIndex} />
        <AuthRoute exact path="/calendar" component={CalendarView} />
        <AuthRoute exact path="/boards" component={BoardsIndex} />
        <AuthRoute exact path="/settings" component={Settings} />
        <AuthRoute
          exact
          path="/r/:id"
          component={Redirect}
          skipPublic={skipPublic}
        />
      </Switch>
      <Sidebar />
      <BookingCalender />
      <EnhancedUpgrade />
    </>
  );
};

export default observer(Routes);
