import React from 'react';

import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const UserInfo = observer(({ user, children }) => {
  if (isEmpty(user)) return null;
  return (
    <div className="cell-collaborator">
      <div className="initials">{user.initials}</div>
      <div className="details">
        <div className="name">
          {user.fullName}
          {user.isYou && <span className="text-muted ml-1">(You)</span>}
        </div>
        {children}
      </div>
    </div>
  );
});

UserInfo.propTypes = {
  user: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};

export default UserInfo;
