import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNull } from 'lodash';
import ContentContainer from './ContentContainer';
import NodeTypeSelector from '../../nodes/node_type_selector';
import NodeParentSelector from '../../nodes/node_parent_selector';
import NodeSetAsTree from '../../nodes/node_set_as_tree';
import NodeShareableLink from '../../nodes/node_shareable_link';
import NodeDeleteAction from '../../../trees/show_components/node/NodeDeleteAction';
import TreeBackgroundSelector from '../../../trees/show_components/node/TreeBackgroundSelector';
import {
  canDeleteNode,
  handleNodeToBoard,
} from '../../../../helpers/node_helpers';
import NodePublicationStatus from '../../nodes/node_publication_status';
import NodeEmbed from '../../nodes/embed';
import LinkedNodeSelector from '../../nodes/linked_node_selector';

const NodeSettings = observer(({ sidebar }) => {
  const [creatingBoard, setCreatingBoard] = useState(false);

  const sNode = sidebar.node;
  const { t } = useTranslation();
  const history = useHistory();

  const handleBoard = () => {
    handleNodeToBoard(sNode, creatingBoard, setCreatingBoard, history);
  };

  const showPopout = () =>
    !sNode.externalised ||
    (sNode.externalised && !isNull(sNode.poppedOutFromId));

  const showLinkedNodeSelector = () =>
    !sNode.externalised ||
    (sNode.externalised && isNull(sNode.poppedOutFromId));

  return (
    <ContentContainer title={t('sidebar.tabs.settings.label')}>
      <div className="sidebar-common-inputs">
        {!sNode.root && <NodePublicationStatus nodes={sidebar.nodes} />}
        <div className="mt-2">
          {sNode.root ? (
            <>
              <NodeTypeSelector node={sNode} />
            </>
          ) : (
            <>
              <NodeParentSelector node={sNode} />
              {showPopout() && <NodeSetAsTree node={sNode} />}
              {!sNode.externalised && <hr />}
              {showLinkedNodeSelector() && <LinkedNodeSelector node={sNode} />}
            </>
          )}
        </div>
        <NodeShareableLink node={sNode} />
        {sNode.tree.userIsInstanceAdmin &&
          sNode.tree.isTutorial &&
          sNode.canChange && (
            <>
              <hr />
              <NodeEmbed node={sNode} />
            </>
          )}
        {sNode.root && sNode.canChange && (
          <>
            <hr />
            <TreeBackgroundSelector tree={sNode} />
          </>
        )}
        {sNode.canChange && sNode.isTag && (
          <>
            <Button
              disabled={creatingBoard}
              onClick={handleBoard}
              color="success"
              size="sm"
              className="w-100"
            >
              {creatingBoard && (
                <FontAwesomeIcon icon="spinner" className="fa-spin" />
              )}{' '}
              {t('boards.from_node.action')}
            </Button>
            <p className="small text-muted">
              {t('boards.from_node.description', { tag: sNode.tag.name })}
            </p>
          </>
        )}
        {canDeleteNode(sNode) && !sNode.root && (
          <>
            <hr />
            <NodeDeleteAction node={sNode} sidebar />
          </>
        )}
      </div>
    </ContentContainer>
  );
});

export default NodeSettings;
