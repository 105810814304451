import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { isEqual, map } from 'lodash';
import GroupSelector from '../../modules/groups/group_selector';
import MultipleNodeRemove from '../../modules/nodes/multiple_node_remove';

const ControlBar = ({ selectedStore, controlType }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedStore.controlBarExpanded)
      selectedStore.toggleControlBarExpanded();
  }, [selectedStore.hasSelected]);

  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center">
        <div className="text-light flex-grow-1">
          <strong>{selectedStore.selectedRecordsCount}</strong> Records Selected
        </div>

        <Button
          color="outline-light"
          onClick={() => selectedStore.toggleControlBarExpanded()}
        >
          {selectedStore.controlBarExpanded ? 'Less Options' : 'More Options'}
        </Button>
        <Button
          color="light ml-2"
          onClick={() => selectedStore.deselectAll()}
          className="w-auto px-4 deselect-btn"
        >
          <FontAwesomeIcon icon="times" />
          <span className="btn-text">{t('labels.deselect_all')}</span>
        </Button>
        {isEqual(controlType, 'node') && (
          <MultipleNodeRemove
            color="light ml-2"
            nodeIds={map(selectedStore.selectedRecords, n => n.id)}
            className="w-auto px-4 ml-2"
            nodeType="tree"
          />
        )}
      </div>
      <hr />
      {selectedStore.controlBarExpanded && (
        <GroupSelector
          recordType={controlType}
          records={selectedStore.selectedRecords}
        />
      )}
    </div>
  );
};

ControlBar.propTypes = {
  selectedStore: PropTypes.instanceOf(Object).isRequired,
  controlType: PropTypes.string.isRequired,
};

export default observer(ControlBar);
