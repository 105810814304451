import React, { useState } from 'react';

import { Input, Button, Popover, PopoverBody, Form } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { isDate, isEmpty, isEqual } from 'lodash';
import { DateRangePicker } from 'react-date-range';
import { observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { getStatuses } from '../../../helpers/node_helpers';
import {
  customControlStyle,
  customStyles,
} from '../../../helpers/shared_helpers';
import { tagsAsOptions } from '../../../helpers/tag_helpers';
import {
  showTagSelector,
  showStatusSelector,
  showNameSelector,
  showDueDateSelector,
} from '../../../helpers/search_helpers';

const SearchInputs = observer(
  ({ menuPlacement, searchText, tree, sidebar, disable }) => {
    const [showPopover, setShowPopover] = useState(false);
    const { t } = useTranslation();

    const { search } = tree;

    const togglePopover = () => setShowPopover(!showPopover);
    const handleChange = value => search.update(value);
    const handleSubmit = e => {
      e.preventDefault();

      search.fetch(sidebar);
    };

    if (isEmpty(search)) return null;
    const statuses = getStatuses(t);

    return (
      <Form onSubmit={handleSubmit} className="search-inputs">
        <div className="responsive-wrapper">
          {showNameSelector(disable) && (
            <Input
              type="search"
              placeholder={t('search_inputs.placeholder.query')}
              value={search.query}
              onChange={e => handleChange({ query: e.target.value })}
            />
          )}
          {showTagSelector(disable) && (
            <div className="tag-search">
              <Select
                styles={customStyles}
                menuPlacement={menuPlacement}
                value={search.tags}
                isMulti
                placeholder={t('search_inputs.placeholder.tags')}
                options={tagsAsOptions(tree.tags)}
                name="tags"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={val => handleChange({ tags: val })}
              />
            </div>
          )}
          {showStatusSelector(disable) && (
            <Select
              styles={customStyles}
              placeholder={t('search_inputs.placeholder.status')}
              menuPlacement={menuPlacement}
              isMulti
              name="statuses"
              className="basic-multi-select"
              classNamePrefix="select"
              value={search.statuses}
              onChange={val => handleChange({ statuses: val })}
              options={statuses}
            />
          )}
          {showDueDateSelector(disable) && (
            <div className="dt-range-pick">
              <Input
                value={search.dateRangeValue}
                disabled
                id={`date-range-picker-popover-${menuPlacement}`}
                onClick={togglePopover}
              />
              {isDate(search.dueDateStart) && (
                <Button
                  color="link"
                  onClick={() =>
                    handleChange({ dueDateStart: null, dueDateEnd: null })
                  }
                >
                  &times;
                </Button>
              )}
            </div>
          )}

          {showDueDateSelector(disable) && (
            <Popover
              className="no-max-width"
              toggle={togglePopover}
              isOpen={showPopover}
              target={`date-range-picker-popover-${menuPlacement}`}
              trigger="legacy"
              placement="bottom-start"
            >
              <PopoverBody>
                <DateRangePicker
                  ranges={[
                    {
                      startDate: search.dueDateStart || new Date(),
                      endDate: search.dueDateEnd || new Date(),
                      key: 'dueDate',
                    },
                  ]}
                  onChange={d =>
                    handleChange({
                      dueDateStart: d.dueDate.startDate,
                      dueDateEnd: d.dueDate.endDate,
                    })
                  }
                />
              </PopoverBody>
            </Popover>
          )}
        </div>

        <Button type="submit">
          <FontAwesomeIcon
            icon="search"
            className="mr-1"
            data-toggle="tooltip"
            title={t('search_inputs.tooltip')}
          />
          {searchText}
        </Button>
      </Form>
    );
  }
);

SearchInputs.defaultProps = {
  menuPlacement: 'top',
  searchText: '',
  sidebar: false,
  disable: [],
};

SearchInputs.propTypes = {
  menuPlacement: PropTypes.string,
  searchText: PropTypes.string,
  sidebar: PropTypes.bool,
  disable: PropTypes.instanceOf(Array),
};

export default SearchInputs;
