import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';

import { useTranslation } from 'react-i18next';
import { canDeleteNode } from '../../../../helpers/node_helpers';
import UnsplashImagePicker from '../../../shared/UnsplashImagePicker';
import useStore from '../../../../data/store';

const TreeBackgroundSelector = () => {
  const { trees } = useStore();
  return <UnsplashImagePicker record={trees.activeTree.rootNode} />;
};

export default TreeBackgroundSelector;
