import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { find, map } from 'lodash';
import Select from 'react-select';
import useStore from '../../../data/store';
import { customStylesForFilter } from '../../../helpers/shared_helpers';

const FilterTabs = () => {
  const { users: userStore, trees: store } = useStore();

  const tabs = userStore.userSignedIn
    ? ['all_trees', 'starred', 'shared_with_me', 'my_trees', 'public_trees']
    : ['all_trees'];

  const { t } = useTranslation();

  const options = map(tabs, tab => ({
    label: t(`trees.filter_tabs.${tab}`),
    value: tab,
  }));

  return (
    <div className="search-sort-widget">
      <Select
        type="select"
        value={find(options, { value: store.activeTab })}
        options={options}
        onChange={e => {
          store.update({ activeTab: e.value });
          store.fetch();
        }}
        styles={customStylesForFilter}
      />
    </div>
  );
};

export default observer(FilterTabs);
