import React from 'react';

import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '../../../data/store';
import { GARDEN_LOGO } from '../../../assets/images';
import AppLayout from '../../../layouts/App';

const PublicAuth = () => {
  const { users: userStore } = useStore();
  const { t } = useTranslation();

  return (
    <AppLayout loading={userStore.fetchingCurrentUser} publiclyVisible>
      <Container>
        <Row>
          <Col xl={{ size: 6, offset: 3 }}>
            <Card>
              <CardBody>
                <div className="m-auto" style={{ maxWidth: 400 }}>
                  <img src={GARDEN_LOGO} alt="logo" className="mw-100" />
                </div>
                <div className="mt-5 d-flex flex-column">
                  <Button tag={Link} to="/public/trees" color="success">
                    {t('nav.links.goto_public_trees')}
                  </Button>
                  {!userStore.userSignedIn && (
                    <Button
                      href="/users/auth/keycloakopenid"
                      color="success"
                      className="mt-3"
                    >
                      {t('nav.links.sign_in_up')}
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default PublicAuth;
