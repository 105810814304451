import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const en = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: 'ago',
  suffixFromNow: 'from now',
  seconds: 'less than a minute',
  minute: 'about a minute',
  minutes: '%d minutes',
  hour: 'about an hour',
  hours: 'about %d hours',
  day: 'a day',
  days: '%d days',
  month: 'about a month',
  months: '%d months',
  year: 'about a year',
  years: '%d years',
  wordSeparator: ' ',
};

const strings = locale => {
  switch (locale) {
    default:
      return en;
  }
};

const formatter = locale => buildFormatter(strings(locale));

export default formatter;
