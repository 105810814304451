import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Quiz from '../../nodes/quiz';
import ContentContainer from './ContentContainer';

const NodeQuiz = ({ sidebar }) => {
  const sNode = sidebar.node;
  const { t } = useTranslation();

  return (
    <ContentContainer title={t('sidebar.tabs.summary.label')}>
      <Quiz node={sNode} />
    </ContentContainer>
  );
};

NodeQuiz.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default observer(NodeQuiz);
