import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Button, Container } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';
import { isNull } from 'lodash';
import TreeList from './index_components/TreeList';
import useStore from '../../data/store';
import AppLayout from '../../layouts/App';
import ListPageLayout from '../shared/ListPageLayout';
import FilterWidget from './index_components/FilterWidget';
import GroupTreeList from './index_components/GroupTreeList';
import ControlBar from '../shared/control_bar';
import Sidebar from './index_components/Sidebar';
import {flowResult} from "mobx";

const TreesIndex = observer(() => {
  const { trees: store, users: userStore, nodeGroups, app } = useStore();

  const location = useLocation();

  useEffect(
    () => () => {
      app.update({ showGallerySidebar: false });
    },
    []
  );

  useEffect(() => {
    store.update({
      activeTab: userStore.userSignedIn ? 'all_trees' : 'public_trees',
    });

    console.log('TreesIndex: useEffect: store.fetch(1)');
    store.fetch();
    console.log('TreesIndex: useEffect: store.fetch(2)');
    nodeGroups.fetchGroups().then(() => {
      const group = nodeGroups.getBySlug(location?.state?.group);

      if (group) group.open();
    });
  }, []);
  return (
    <AppLayout
      scrollable={false}
      publiclyVisible={!userStore.userSignedIn}
      loading={store.fetching}
      className={classnames({
        'no-trees': !store.hasRecords && userStore.userSignedIn,
      })}
    >
      <Container fluid>
        <div className="trees-sidebar-layout">
          <Sidebar />
          <div className="trees-list-area">
            <ListPageLayout
              controlBarExpanded={store.controlBarExpanded}
              layoutClassName="trees-view-layout"
              actionsClassName="control-bar"
              actionsWidget={
                <ControlBar selectedStore={store} controlType="node" />
              }
              showControlBar={
                store.hasSelected ||
                (nodeGroups.hasSelected &&
                  userStore.currentUser.isInstanceAdmin)
              }
              filterWidget={<FilterWidget />}
            >
              {store.rootStore.nodeGroups.hasActiveGroup ? (
                <GroupTreeList
                  tab={store.activeTab}
                  store={store}
                  group={store.rootStore.nodeGroups.activeGroup}
                />
              ) : (
                <TreeList store={store} tab={store.activeTab} />
              )}
            </ListPageLayout>
          </div>
        </div>
      </Container>
    </AppLayout>
  );
});

TreesIndex.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default TreesIndex;
