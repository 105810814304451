import { action, observable, flow, makeObservable, computed } from 'mobx';
import { camelCase, isEmpty, map } from 'lodash';

import client from '../axiosClient';
import { parameterize } from '../../helpers/shared_helpers';

class UserState {
  /* eslint-disable */
  @observable laneIds = [];
  @observable lanes;
  board;
  /* eslint-enable */

  constructor(value, board) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.board = board;
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );

    this.updateServer(params);
  }

  @flow
  *updateServer(params) {
    yield client.put(`/api/v1/boards/${this.board.id}/user_state.json`, {
      data: parameterize(params),
    });
  }
}

export default UserState;
