import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Select from 'react-select/async-creatable';
import makeAnimated from 'react-select/animated';

import { FormGroup, Label } from 'reactstrap';
import {
  recordsAsSelectOptions,
  handleRecordChange,
  customStyles,
} from '../../../../helpers/shared_helpers';

const animatedComponents = makeAnimated();

const Form = observer(({ records, groups, recordType, store }) => {
  const options = recordsAsSelectOptions(groups);

  const handleChange = e => {
    handleRecordChange(records, e, options, store, false);
  };

  const { t } = useTranslation();

  return (
    <FormGroup className="mb-0">
      <Label className="text-light">{t(`labels.groups_${recordType}`)}</Label>
      <Select
        creatable
        value={options}
        isMulti
        menuPlacement="top"
        placeholder={t(`labels.groups_select_placeholder`)}
        components={animatedComponents}
        onChange={handleChange}
        loadOptions={val => store.searchGroups(val)}
        styles={customStyles}
      />
    </FormGroup>
  );
});

Form.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
  groups: PropTypes.instanceOf(Array).isRequired,
  store: PropTypes.instanceOf(Object).isRequired,
  recordType: PropTypes.string.isRequired,
};

export default Form;
