import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import useStore from '../../data/store';
import Kanban from './index_components/Kanban';
import Toolbar from './index_components/Toolbar';
import AppLayout from '../../layouts/App';
import { imageBackgroundStyle } from '../../helpers/unsplash_helper';

const BoardsIndex = observer(() => {
  const { boards: store, sidebars } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    store.fetchMyBoards();
    return () => {
      sidebars.clearAll();
      store.update({ records: [] });
    };
  }, []);

  return (
    <AppLayout
      loading={store.fetching}
      className="boards-view"
      sidebarNp={false}
    >
      <div className="main-boards">
        <Toolbar />
        {!isEmpty(store.activeBoard) && (
          <>
            <div
              style={imageBackgroundStyle(store.activeBoard)}
              className="overlay-bg"
            />
            <Kanban board={store.activeBoard} />
          </>
        )}
        {isEmpty(store.activeBoard) && (
          <div className="ml-3 pt-3 border-top text-center">
            <h3 className="text-muted">{t('boards.not_selected')}</h3>
          </div>
        )}
      </div>
    </AppLayout>
  );
});

export default BoardsIndex;
