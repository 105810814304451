import React from 'react';

import { observer } from 'mobx-react';
import { Label } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import Select from 'react-select/async-creatable';
import makeAnimated from 'react-select/animated';
import {
  customStyles,
  recordsAsSelectOptions,
  handleRecordChange,
} from '../../../../helpers/shared_helpers';
import useStore from '../../../../data/store';

const animatedComponents = makeAnimated();

const Form = observer(({ nodes, tags }) => {
  const { tags: store } = useStore();

  const { rootId } = nodes[0];
  const options = recordsAsSelectOptions(tags);

  const handleChange = e => {
    handleRecordChange(nodes, e, options, store, false);
  };

  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-3">
        <Label>{t('sidebar.details.tags')}</Label>
        <Select
          creatable
          value={options}
          isMulti
          components={animatedComponents}
          onChange={handleChange}
          loadOptions={val => store.searchTags(rootId, val)}
          styles={customStyles}
        />
      </div>
    </div>
  );
});

export default Form;
