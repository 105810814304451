import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import useStore from '../../../data/store';
import { checkNodeIdInParamsAndRevealIfPresent } from '../../../helpers/node_helpers';

const HandleFetching = ({ tree }) => {
  const { trees } = useStore();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched && trees.activeTree && tree.isActive) {
      setFetched(true);

      tree.fetchTreeAndNodes().then(res => {
        if (res.success) {
          checkNodeIdInParamsAndRevealIfPresent(tree);

          tree.fetchMyCollaborators();

          tree.fetchNodes().then(() => {
            tree.update({ fetchingNodes: false });
            setTimeout(() => {
              tree.rootNode.reveal();
            }, 400);
          });
        }
      });
    }
  }, [trees.activeTree, tree]);

  return null;
};

HandleFetching.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default observer(HandleFetching);
