import { makeAutoObservable, action, observable, computed } from 'mobx';

import { isEmpty, find, map, filter, includes } from 'lodash';
import Sidebar from '../entities/sidebar';

class SidebarStore {
  @observable records = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  findByIndex(index) {
    return this.records[index];
  }

  findByNodeId(id) {
    return filter(this.records, r => includes(r.nodeIds, id));
  }

  hasType(type) {
    return !isEmpty(find(this.records, { type }));
  }

  @computed
  get activeSidebar() {
    return find(this.records, { active: true });
  }

  @computed
  get showSidebar() {
    return !isEmpty(this.records);
  }

  @action
  closeAll() {
    map(this.records, r => r.update({ active: false }));
  }

  @action
  clearAll() {
    this.records = [];
    const { activeTree } = this.rootStore.trees;

    if (!isEmpty(activeTree) && !activeTree.multiSelect) {
      activeTree.deselectAllNodes();
    }
  }

  @action
  addSidebar(params) {
    const clonedParams = { ...params };
    delete clonedParams.activeTab;

    if (find(this.records, clonedParams)) return;
    this.closeAll();

    this.records.push(new Sidebar({ ...params, active: true }, this));
  }
}

export default SidebarStore;
