import React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContentContainer from './ContentContainer';
import UnsplashImagePicker from '../../../shared/UnsplashImagePicker';

const BoardSettings = ({ sidebar }) => {
  const { t } = useTranslation();

  const deleteBoard = () => {
    confirmAlert({
      title: t('alert.titles.attention'),
      message: t('boards.actions.delete.alert_message'),
      buttons: [
        {
          label: t('alert.buttons.yes'),
          onClick: () => {
            sidebar.board.destroy().then(() => sidebar.destroy());
          },
        },
        {
          label: t('alert.buttons.no'),
        },
      ],
    });
  };
  return (
    <ContentContainer title={t('sidebar.tabs.settings.label')}>
      <UnsplashImagePicker record={sidebar.board} />
      <Button
        onClick={deleteBoard}
        color="danger"
        className="w-100"
        data-toggle="tooltip"
        title={t('boards.actions.delete.tooltip')}
      >
        {t('general.delete')}
      </Button>
    </ContentContainer>
  );
};

BoardSettings.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default observer(BoardSettings);
