import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { isEqual, isNull } from 'lodash';
import { Button, Col, Row, Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import fa from 'react-timeago/lib/language-strings/fa';
import useStore from '../../../../data/store';
import NodePresenter from '../presenter';
import AutoplayCountDown from '../../player/AutoplayCountDown';
import EmbedNodePresenter from '../presenter/embed';

const PlaylistButton = observer(
  ({ onClick, text, icon, className, disabled }) => (
    <Button
      color="success"
      onClick={onClick}
      disabled={disabled}
      className={classNames('btn-block', {
        'w-auto': true,
        [className]: true,
      })}
    >
      <FontAwesomeIcon icon={icon} />
      {text}
    </Button>
  )
);

const Playlist = ({ embed }) => {
  const { playlist } = useStore();

  const [countDown, setCountDown] = useState(null);

  let timeout = null;

  const handleEnded = () => {
    if (embed) return;
    if (!playlist.hasNext) return null;

    setCountDown(10);
  };

  useEffect(() => {
    if (!embed && !playlist.hasActive) {
      setCountDown(null);
    }
  }, [playlist.active]);

  useEffect(() => {
    if (!embed && !isNull(countDown) && !playlist.active?.showQuiz) {
      if (isEqual(countDown, 0)) {
        setCountDown(null);
        playlist.gotoNext();
      } else {
        timeout = setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      }
    }
  }, [countDown]);

  const cancelCountDown = () => {
    if (embed && playlist.active?.showQuiz) return;

    clearTimeout(timeout);
    setCountDown(null);
  };

  const handlePrev = () => {
    cancelCountDown();
    playlist.gotoPrev();
  };

  const startQuiz = () => {
    playlist.update({ playingQuiz: true });
  };

  const endQuiz = () => {
    playlist.update({ playingQuiz: false });
  };

  const handleNext = () => {
    cancelCountDown();
    playlist.gotoNext();
  };

  if (!playlist.hasActive) return null;

  const Presenter = embed ? EmbedNodePresenter : NodePresenter;

  const showNextBtn = () => {
    if (playlist.playingQuiz) return playlist.hasNext;
    if (playlist.active.showQuiz) return false;

    return playlist.hasNext;
  };

  const showPrevBtn = () => !playlist.playingQuiz && playlist.hasPrevious;

  const postMessageToParentWindow = () => {
    window.parent.postMessage({ message: 'finish' }, '*');
  };

  const handleFinish = () => {
    if (embed) return postMessageToParentWindow();

    playlist.reset();
    playlist.update({ loading: false });
  };

  return (
    <div className={classNames('playlist', { 'playlist-embed': embed })}>
      <Presenter node={playlist.active} onEnded={handleEnded}>
        <Row className="playlist-controls">
          <Col>
            {playlist.playingQuiz && (
              <PlaylistButton
                onClick={endQuiz}
                icon="step-backward"
                text="Previous"
              />
            )}
            {showPrevBtn() && (
              <PlaylistButton
                onClick={handlePrev}
                icon="step-backward"
                text="Previous"
              />
            )}
          </Col>
          <Col>
            {embed ? (
              <div className="progress-embed">
                <Progress
                  className="w-100"
                  color="success"
                  value={
                    ((playlist.activeIndex + 1) * 100) / playlist.videos.length
                  }
                  style={{ height: '3px' }}
                />
              </div>
            ) : (
              <>
                {playlist.active && !playlist.active.showQuiz && (
                  <AutoplayCountDown
                    countDown={countDown}
                    playlist={playlist}
                    cancelCountDown={cancelCountDown}
                  />
                )}
              </>
            )}
          </Col>
          <Col>
            {playlist.active.showQuiz && !playlist.playingQuiz ? (
              <PlaylistButton
                className="ml-auto"
                onClick={startQuiz}
                icon="step-forward"
                text="Next"
              />
            ) : (
              <>
                {!playlist.hasNext && (
                  <PlaylistButton
                    className="ml-auto"
                    disabled={!playlist.active.quizCompleted}
                    onClick={handleFinish}
                    icon="step-forward"
                    text="Finish"
                  />
                )}
              </>
            )}
            {showNextBtn() && (
              <PlaylistButton
                className="ml-auto"
                onClick={handleNext}
                disabled={!playlist.active.quizCompleted}
                icon="step-forward"
                text="Next"
              />
            )}
          </Col>
        </Row>
      </Presenter>
    </div>
  );
};

Playlist.defaultProps = {
  embed: false,
};

Playlist.propTypes = {
  embed: PropTypes.bool,
};

export default observer(Playlist);
