import React, { useState } from 'react';

import { Button, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RichEditor from '../../rich_text_editor';
import { commentEditorToolbar } from '../../../../helpers/shared_helpers';

const CommentForm = ({ node }) => {
  const [comment, setComment] = useState('');
  const { comments: commentStore } = node.store.rootStore;

  const handleSubmit = e => {
    e.preventDefault();
    if (isEmpty(comment)) return;

    commentStore.addNodeComment(node.id, comment).then(res => {
      setComment('');
    });
  };
  const { t } = useTranslation();
  return (
    <div className="sidebar-common-inputs">
      <Form onSubmit={handleSubmit}>
        <div>
          <RichEditor
            placeholder={t('sidebar.comments.form.placeholder')}
            label={t('sidebar.comments.form.label')}
            onChange={m => setComment(m)}
            value={comment}
            mentions={node.tree.collaboratorsAsUsers}
            toolbar={commentEditorToolbar}
          />
        </div>
        <Button color="success" className="btn-block">
          <FontAwesomeIcon
            icon="paper-plane"
            data-toggle="tooltip"
            title={t('sidebar.comments.form.tooltips.submit')}
            className="mr-2"
          />
          {t('sidebar.comments.form.tooltips.submit')}
        </Button>
      </Form>
    </div>
  );
};

CommentForm.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default CommentForm;
