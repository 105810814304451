import React from 'react';

import { Label } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import {
  customStyles,
  getIdsFromRecords,
} from '../../../../helpers/shared_helpers';
import {
  getNodesValue,
  getPublicationStatuses,
} from '../../../../helpers/node_helpers';
import useStore from '../../../../data/store';

const NodePublicationStatus = observer(({ nodes }) => {
  const { nodes: store } = useStore();
  const publicationStatus = getNodesValue(nodes, 'computedPublicationStatus');

  const handleChange = selectedVal =>
    store.updateNodes(getIdsFromRecords(nodes), {
      publication_status: selectedVal.value,
      action_type: 'node',
    });
  const { t } = useTranslation();
  const statuses = getPublicationStatuses(t);
  return (
    <div className="pt-1">
      <Label>{t('sidebar.details.publication_status')}</Label>
      <Select
        options={statuses}
        value={find(statuses, { value: publicationStatus })}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
});

NodePublicationStatus.propTypes = {
  nodes: PropTypes.instanceOf(Array).isRequired,
};

export default NodePublicationStatus;
