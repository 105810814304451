// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react';
import ReactDOM from 'react-dom';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import EntryPoint from '../client/views/entry_point';

library.add(fas, far);

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <EntryPoint />,
    document.body.appendChild(document.createElement('div'))
  );
});
