import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual, map } from 'lodash';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TreeTabs = ({ tree }) => (
  <div className="tree-tabs">
    {map(tree.tabs, (tab, i) => (
      <div
        key={i}
        className={classNames('tree-tab', {
          active: isEqual(tree.activeTab.nodeId, tab.nodeId),
        })}
      >
        <Button onClick={() => tab.setActive()}>{tab.tabName}</Button>
        {!tree.store.rootStore.app.embedded && i > 0 && (
          <Button onClick={() => tab.destroy()}>
            <FontAwesomeIcon icon="times" />
          </Button>
        )}
      </div>
    ))}
  </div>
);

TreeTabs.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default observer(TreeTabs);
