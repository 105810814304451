import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import PlayableArea from './PlayableArea';
import NodeDescription from '../node_description';
import useStore from '../../../../data/store';

const EmbedNodePresenter = ({ node, children, onEnded }) => {
  const nodeOnlyText = !node.hasFeaturedVideo && !node.hasThumbnail;
  const { playlist } = useStore();

  return (
    <div
      className={classnames('node-presenter node-presenter-embed', {
        'no-description': !node.hasDescription,
        'no-attachment-quiz': !playlist.playingQuiz && nodeOnlyText,
      })}
    >
      <PlayableArea
        className={classnames({
          'h-100': nodeOnlyText && !playlist.playingQuiz,
        })}
        disableTitle={!nodeOnlyText || playlist.playingQuiz}
        node={node}
        onEnded={onEnded}
      >
        {children}
      </PlayableArea>
      {(!nodeOnlyText || playlist.playingQuiz) && node.hasDescription && (
        <div className="node-collapsible-area">
          <div className="scrollable-collapsible-area">
            <h2>{node.name}</h2>
            <NodeDescription node={node} summary />
          </div>
        </div>
      )}
    </div>
  );
};

EmbedNodePresenter.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node,
  onEnded: PropTypes.func,
};

export default observer(EmbedNodePresenter);
