import React from 'react';

import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ContentEditable from 'react-contenteditable';
import UserInfo from '../../../shared/users/Info';

const NodeComment = observer(({ comment, node }) => {
  const { currentUser } = node.store.rootStore.users;
  const { t } = useTranslation();

  return (
    <div className="cell-comment">
      <UserInfo user={comment.user}>
        <ContentEditable
          target="_blank"
          className="comment-details"
          html={comment.comment}
          disabled
        />
        {(isEqual(comment.user.id, currentUser.id) || node.treeOwner) && (
          <Button
            onClick={() => comment.destroy()}
            color="link"
            className="remove-comment"
          >
            <FontAwesomeIcon
              icon="trash"
              data-toggle="tooltip"
              title={t('general.delete_item')}
            />
          </Button>
        )}
      </UserInfo>
    </div>
  );
});

NodeComment.propTypes = {
  comment: PropTypes.instanceOf(Object).isRequired,
  node: PropTypes.instanceOf(Object).isRequired,
};

export default NodeComment;
