import React from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Button } from 'reactstrap';
import NodeAssign from '../../nodes/node_assign';
import Tags from '../../nodes/nodes_tags';
import NodeStatus from '../../nodes/node_status';
import NodeDueDate from '../../nodes/node_due_date';
import MultipleNodeRemove from '../../nodes/multiple_node_remove';

const MultiNodeProperties = ({ sidebar }) => {
  if (isEmpty(sidebar.nodes)) return null;

  return (
    <div>
      <NodeAssign nodes={sidebar.nodes} viewType={sidebar.viewType} />
      <Tags nodes={sidebar.nodes} viewType={sidebar.viewType} />
      <NodeStatus nodes={sidebar.nodes} />
      <NodeDueDate nodes={sidebar.nodes} />
      <Button
        onClick={() => sidebar.node.store.copyNodes(sidebar.nodeIds)}
        className="mb-3 w-100"
        color="info"
      >
        Copy {sidebar.nodes.length} nodes
      </Button>
      <MultipleNodeRemove nodeIds={sidebar.nodeIds} />
    </div>
  );
};

MultiNodeProperties.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default MultiNodeProperties;
