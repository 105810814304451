import React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash';

const NodeSetAsTree = ({ node }) => {
  const { t } = useTranslation();
  const handleClick = () =>
    confirmAlert({
      title: t('feature_info.title'),
      message: t('sidebar.popout.feature.message'),
      buttons: [
        {
          label: t('sidebar.popout.feature.ok_button'),
          onClick: () =>
            node
              .updateNodeOnServer({ externalised: true, popout: true })
              .then(res => {
                if (res.data.success) node.popout(res);

                node.store.rootStore.sidebars.clearAll();
              }),
        },
        {
          label: t('alert.buttons.cancel'),
        },
      ],
    });

  const handleUndo = () =>
    confirmAlert({
      title: t('feature_info.title'),
      message: t('sidebar.popout.undo_feature.message'),
      buttons: [
        {
          label: t('sidebar.popout.undo_feature.ok_button'),
          onClick: () =>
            node
              .updateNodeOnServer({ externalised: false, popout: true })
              .then(res => {
                if (res.data.success) node.undoPopout(res);

                node.store.rootStore.sidebars.clearAll();
              }),
        },
        {
          label: t('alert.buttons.cancel'),
        },
      ],
    });

  if (node.isTag) return null;

  return (
    <div>
      <hr />
      {node.externalised ? (
        <>
          {!isNull(node.poppedOutFromId) && (
            <Button className="w-100" color="success" onClick={handleUndo}>
              {t('sidebar.popout.undo')}
            </Button>
          )}
        </>
      ) : (
        <Button className="w-100" color="success" onClick={handleClick}>
          {t('sidebar.popout.popout')}
        </Button>
      )}
    </div>
  );
};

NodeSetAsTree.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(NodeSetAsTree);
