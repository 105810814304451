import {computed, makeAutoObservable, makeObservable, observable} from 'mobx';
import { camelCase, isEmpty, isEqual, map } from 'lodash';
import moment from 'moment';

class Notification {
  /* eslint-disable */
  id;
  @observable viewedAt;
  message;
  createdAt;
  /* eslint-enable */

  constructor(value, store) {
    makeAutoObservable(this);
    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  @computed
  get formattedDate() {
    return moment(this.createdAt).format('DD MMM YYYY');
  }

  @computed
  get isViewed() {
    return !isEmpty(this.viewedAt);
  }
}

export default Notification;
