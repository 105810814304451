import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { isNull } from 'lodash';
import NodeAttachment from '../../../../data/entities/node_attachment';
import Form from '../node_attachments/Form';
import Attachment from '../node_attachments/Attachment';

const FeaturedAttachment = ({ node }) => {
  const [formObj, setFormObj] = useState(null);

  const initialize = () =>
    setFormObj(
      new NodeAttachment(
        {
          id: null,
          nodeId: node.id,
          attachmentTitle: 'Featured Attachment',
          featured: true,
        },
        node.store.rootStore.nodeAttachments
      )
    );

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div>
      {node.hasFeaturedAttachment ? (
        <Attachment attachment={node.featuredAttachment} />
      ) : (
        <>
          {!isNull(formObj) && (
            <Form featured attachment={formObj} reset={initialize} />
          )}
        </>
      )}
    </div>
  );
};

FeaturedAttachment.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(FeaturedAttachment);
