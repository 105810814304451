import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { map } from 'lodash';

import UserInfo from '../users/Info';

const Collaborator = observer(({ collaborator }) => {
  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);
  const accessTypes = collaborator.store.accessTypes();
  const popoverTarget = `access-type-${collaborator.id}`;

  return (
    <UserInfo user={collaborator.user}>
      {collaborator.isOwner ? (
        <span>Owner</span>
      ) : (
        <>
          {collaborator.canBeChanged ? (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span onClick={toggle} id={popoverTarget} className="access-type">
                {accessTypes[collaborator.accessType]}
                <FontAwesomeIcon icon="caret-down" className="ml-1" />
              </span>
              <Popover
                trigger="legacy"
                placement="bottom"
                toggle={toggle}
                isOpen={show}
                target={popoverTarget}
              >
                <PopoverHeader>Change Permission</PopoverHeader>
                <PopoverBody>
                  {map(Object.keys(accessTypes), (at, i) => (
                    <Button
                      onClick={() => {
                        collaborator.update({ accessType: at });
                        toggle();
                      }}
                      key={i}
                      color="link"
                    >
                      {accessTypes[at]}
                    </Button>
                  ))}
                </PopoverBody>
              </Popover>
            </>
          ) : (
            <>
              {collaborator.user.isYou ? (
                <span>You</span>
              ) : (
                <span>
                  Invited by{' '}
                  {`${collaborator.invitedBy.firstname} ${collaborator.invitedBy.lastname}`}
                </span>
              )}
              {(collaborator.areYouInvitor || collaborator.user.isYou) && (
                <span>
                  <b>({accessTypes[collaborator.accessType]})</b>
                </span>
              )}
            </>
          )}
        </>
      )}
    </UserInfo>
  );
});

export default Collaborator;
