import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import useStore from '../../data/store';

const GuestRestriction = ({ children }) => {
  const {
    users: { currentUser },
  } = useStore();
  const { t } = useTranslation();

  if (currentUser.guest)
    return (
      <div
        className="card-common text-left"
        dangerouslySetInnerHTML={{
          __html: t('users.guest.access_limit'),
        }}
      />
    );

  return children;
};

GuestRestriction.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(GuestRestriction);
