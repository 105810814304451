import React from 'react';
import { Button } from 'reactstrap';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual, map } from 'lodash';
import { showSiblingHandles } from '../../../../helpers/node_helpers';

const NodeSiblingHandlers = ({ node }) => {
  if (!showSiblingHandles(node)) return null;

  const handleAdd = dir => {
    node.parent.addChild(
      isEqual(dir, 'left') ? node.position - 0.5 : node.position + 0.5
    );
  };

  return (
    <div>
      {map(['left', 'right'], (dir, i) => (
        <Button
          onClick={() => handleAdd(dir)}
          key={i}
          className={`sibling-btn sibling-${dir}`}
          color="link"
        >
          <FontAwesomeIcon icon="plus" />
        </Button>
      ))}
    </div>
  );
};

NodeSiblingHandlers.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default NodeSiblingHandlers;
