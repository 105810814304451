import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, isEmpty, isEqual, map, pullAt } from 'lodash';

const Quiz = ({ node }) => {
  const [quizObj, setQuizObj] = useState(node.quiz);

  useEffect(() => {
    setQuizObj(node.quiz);
  }, [node.quiz]);

  const { t } = useTranslation();

  const { enabled, questions } = quizObj;

  const optionObj = { title: '', is_correct: false };

  const questionObj = {
    title: '',
    options: [optionObj, optionObj, optionObj],
    enabled: false,
  };

  const handleAdd = () => {
    setQuizObj({
      ...quizObj,
      questions: [...quizObj.questions, questionObj],
    });
  };

  const handleQuestionChange = (value, index) => {
    setQuizObj({
      ...quizObj,
      questions: map(questions, (question, i) => {
        if (isEqual(i, index))
          return {
            ...question,
            ...value,
          };
        return question;
      }),
    });
  };

  const handleOptionsUpdate = (options, params, index) => {
    const hasActiveKey = Object.keys(params).includes('is_correct');

    return map(options, (opt, i) => {
      if (isEqual(i, index))
        return {
          ...opt,
          ...params,
        };
      return { ...opt, is_correct: hasActiveKey ? false : opt.is_correct };
    });
  };

  const handleSave = () => {
    let hasUnsaved = false;

    if (!isEmpty(quizObj.questions)) {
      map(quizObj.questions, question => {
        if (isEmpty(question.title)) {
          hasUnsaved = true;
          return;
        }
        if (!isEmpty(find(question.options, opt => isEmpty(opt.title)))) {
          hasUnsaved = true;
          return;
        }
        if (isEmpty(find(question.options, opt => opt.is_correct))) {
          hasUnsaved = true;
        }
      });
    }

    if (hasUnsaved) {
      node.store.rootStore.notification(
        'danger',
        'Question with incomplete details exists. Please complete all details to continue.'
      );

      return;
    }

    node.update({ quiz: quizObj }, true, () => {
      node.store.rootStore.notification('success', 'Quiz Saved!!');
    });
  };

  const handleOptionRemove = index => {
    const q = [...quizObj.questions];
    pullAt(q, [index]);

    setQuizObj({ ...quizObj, questions: q });
  };

  return (
    <div>
      <div className="quiz-toggle d-flex justify-content-between align-items-center">
        <Label className="m-0">
          {enabled ? t('nodes.quiz.enabled') : t('nodes.quiz.disabled')}
        </Label>
        <Button
          onClick={() => setQuizObj({ ...quizObj, enabled: !quizObj.enabled })}
          color="link m-0 p-0"
        >
          <FontAwesomeIcon
            icon={enabled ? 'toggle-on' : 'toggle-off'}
            style={{ fontSize: 24 }}
          />
        </Button>
      </div>
      <hr />
      <FormGroup>
        <Label>{t('nodes.quiz.title')}</Label>
        <Input
          placeholder={t('nodes.quiz.title_placeholder')}
          value={quizObj.title || ''}
          onChange={e => setQuizObj({ ...quizObj, title: e.target.value })}
        />
      </FormGroup>
      <hr />
      <div className="mt-2">
        {map(questions, (question, i) => (
          <Card className="question-form my-2">
            <CardBody>
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <Label className="m-0">
                    {t('nodes.quiz.question_title')}
                  </Label>
                  <Button
                    onClick={() =>
                      handleQuestionChange({ enabled: !question.enabled }, i)
                    }
                    color="link m-0 p-0"
                  >
                    <FontAwesomeIcon
                      icon={question.enabled ? 'toggle-on' : 'toggle-off'}
                      style={{ fontSize: 24 }}
                    />
                  </Button>
                </div>
                <Input
                  value={question.title}
                  onChange={e =>
                    handleQuestionChange({ title: e.target.value }, i)
                  }
                />
              </FormGroup>
              <FormGroup className="question-options">
                <Label>{t('nodes.quiz.question_options')}</Label>
                {map(question.options, (opt, index) => (
                  <FormGroup className="d-flex" key={index}>
                    <Input
                      value={opt.title}
                      onChange={e =>
                        handleQuestionChange(
                          {
                            options: handleOptionsUpdate(
                              question.options,
                              { title: e.target.value },
                              index
                            ),
                          },
                          i
                        )
                      }
                    />
                    <Button
                      color="link p-2 m-0"
                      onClick={() =>
                        handleQuestionChange(
                          {
                            options: handleOptionsUpdate(
                              question.options,
                              { is_correct: true },
                              index
                            ),
                          },
                          i
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={{
                          prefix: opt.is_correct ? 'fas' : 'far',
                          iconName: 'circle',
                        }}
                      />
                    </Button>
                  </FormGroup>
                ))}
              </FormGroup>
              <Button
                onClick={() => handleOptionRemove(i)}
                color="danger btn-block"
              >
                Remove
              </Button>
            </CardBody>
          </Card>
        ))}
      </div>
      <Button onClick={handleAdd} color="success btn-block">
        {t('nodes.quiz.add_question')}
      </Button>
      <Button onClick={handleSave} color="success btn-block">
        {t('nodes.quiz.save')}
      </Button>
    </div>
  );
};

Quiz.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Quiz);
