import React from 'react';

import { Badge, UncontrolledTooltip, Card, Button } from 'reactstrap';
import { Initial } from 'react-initial';
import PropTypes from 'prop-types';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Starred from '../../modules/starred';
import { textForScope } from '../../../helpers/tree_helpers';
import TimeAgo from '../../shared/TimeAgo';
import useStore from '../../../data/store';

const Tree = observer(({ tree }) => {
  const { t } = useTranslation();
  const { users: userStore, nodeGroups } = useStore();
  const history = useHistory();

  const handleDoubleClick = () => {
    history.push(`/trees/${tree.id}`);
  };

  const friendlyId = `tree_${tree.id.replaceAll('-', '_')}`;

  const cardClickProps = {
    onClick: () => {
      if (!userStore.userSignedIn) return;
      if (nodeGroups.hasSelected) nodeGroups.activeGroup.toggleSelect();

      tree.toggleSelect();
    },
    onDoubleClick: handleDoubleClick,
  };

  return (
    <div className="tree-card">
      <Card
        className={classNames({
          selected: tree.selected,
          'with-thumbnail': tree.showFeaturedPreview,
        })}
      >
        <Button {...cardClickProps} className="overlay-link" />
        <div className="tree-info">
          <div {...cardClickProps} className="tree-icon">
            {tree.isTutorial && (
              <Link
                to={{
                  pathname: `/trees/${tree.id}`,
                  state: { playlist: true },
                }}
              >
                <FontAwesomeIcon icon="play-circle" />
              </Link>
            )}
            {tree.showFeaturedPreview ? (
              <img src={tree.treeCoverUrl} alt="" />
            ) : (
              <Initial name={tree.name} fontSize={40} charCount={2} />
            )}
          </div>
          <div className="tree-details">
            <h4 {...cardClickProps} id={friendlyId}>
              {tree.name}
            </h4>
            <UncontrolledTooltip target={friendlyId}>
              {tree.name}
            </UncontrolledTooltip>
            <div className="created-by">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('trees.tree_card.created_by_at', {
                    owner: tree.isOwner ? 'me' : tree.createdBy,
                  }),
                }}
              />{' '}
              {moment(tree.createdAt).format('DD MMM YYYY')}
            </div>
          </div>
          <div className="trees-property-badges">
            <Badge
              pill
              size="sm"
              color="secondary"
              className="text-uppercase scope"
              title={textForScope(tree.scope, t).description}
            >
              {textForScope(tree.scope, t).title}
            </Badge>
            {tree.isTutorial && (
              <Badge
                pill
                size="sm"
                color="info"
                className="text-uppercase"
                title={t('tree_types.tutorial')}
              >
                {tree.nodeType}
              </Badge>
            )}
          </div>
          <div className="tree-card-footer">
            <div className="tree-actions">
              {userStore.userSignedIn && (
                <>
                  <Starred record={tree} />
                  <Button
                    color="link"
                    className="copy p-0"
                    onClick={() =>
                      confirmAlert({
                        title: t('trees.confirm_alerts.copy_tree.title'),
                        message: t('trees.confirm_alerts.copy_tree.message'),
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => tree.duplicate(),
                          },
                          {
                            label: 'No',
                          },
                        ],
                      })
                    }
                  >
                    <FontAwesomeIcon
                      icon="copy"
                      size="lg"
                      title={t('trees.tooltips.copy')}
                    />
                  </Button>
                </>
              )}
              {tree.isOwner && (
                <Button
                  color="link"
                  className="archive p-0"
                  onClick={() =>
                    confirmAlert({
                      title: t('trees.confirm_alerts.delete_tree.title'),
                      message: t('trees.confirm_alerts.delete_tree.message'),
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => tree.destroy(),
                        },
                        {
                          label: 'No',
                        },
                      ],
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon="trash"
                    size="lg"
                    title={t('trees.tooltips.archive')}
                  />
                </Button>
              )}
            </div>
            <div style={{ marginRight: 10 }} className="tree-updated ml-auto">
              <FontAwesomeIcon icon="sitemap" />
              {`${tree.nodes} ${tree.nodes > 1 ? 'nodes' : 'node'}`}
            </div>
            <div className="tree-updated">
              <FontAwesomeIcon icon="sync-alt" />
              <TimeAgo date={tree.updatedAt} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
});

Tree.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default Tree;
