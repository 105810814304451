import { action, flow, makeAutoObservable, observable } from 'mobx';

import { map } from 'lodash';
import Comment from '../entities/comment';
import client from '../axiosClient';

class CommentStore {
  @observable records = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setRecords(records) {
    this.records = [...this.records, ...map(records, r => this.newRecord(r))];
  }

  @action
  newRecord(record) {
    const { users: userStore } = this.rootStore;

    userStore.checkAndAdd(record.user);

    return new Comment(record, this);
  }

  @flow
  *fetchNodeComments(id, setRecords = true) {
    const response = yield client.get(`/api/v1/nodes/${id}/comments.json`);

    if (response.data?.success) {
      if (setRecords) this.setRecords(response.data.results);

      return response.data.results;
    }

    return [];
  }

  @flow
  *addNodeComment(id, comment) {
    const response = yield client.post(`/api/v1/nodes/${id}/comment.json`, {
      comment,
    });

    if (response.data.success) {
      this.records.push(new Comment(response.data.comment, this));
    }
  }
}

export default CommentStore;
