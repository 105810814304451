import { action, flow, makeAutoObservable, observable } from 'mobx';

import { find, map, uniqBy } from 'lodash';

import client from '../axiosClient';
import NodeAttachment from '../entities/node_attachment';

class NodeAttachmentStore {
  @observable records = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getById(id) {
    return find(this.records, { id });
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setRecords(records) {
    this.records = uniqBy(
      [...this.records, ...map(records, r => this.newRecord(r))],
      'id'
    );
  }

  @action
  newRecord(record) {
    const { users: userStore } = this.rootStore;

    userStore.checkAndAdd(record.user);

    return new NodeAttachment(record, this);
  }

  @flow
  *fetchNodeAttachments(nodeId, setRecords = true) {
    const response = yield client.get(
      `/api/v1/node_attachments.json?node_id=${nodeId}`
    );

    if (response.data.success) {
      if (setRecords) this.setRecords(response.data.results);

      return response.data.results;
    }

    return [];
  }
}

export default NodeAttachmentStore;
