import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Card, CardFooter, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import useStore from '../../../data/store';

const BoardItem = observer(({ onClick, id, title, label }) => {
  const { boards } = useStore();
  const board = boards.activeBoard;
  const boardItem = board.boardItemById(id);

  if (isEmpty(boardItem)) return null;

  return (
    <Card>
      <CardTitle onClick={onClick} className="d-flex justify-content-between">
        <h4>{title}</h4>
        <span>{label}</span>
      </CardTitle>
      <CardFooter>
        <Link
          target="_blank"
          to={`/trees/${boardItem.node.rootId}?n=${boardItem.node.id}`}
        >
          {boardItem.formattedDescription}
        </Link>
      </CardFooter>
    </Card>
  );
});

BoardItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
};

BoardItem.defaultProps = {
  onClick: () => {},
  title: 'no title',
  label: '',
};

export default BoardItem;
