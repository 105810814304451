import React from 'react';
import { observer } from 'mobx-react';
import { filter, find, isEmpty, map } from 'lodash';
import { Button, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Collaborator from '../../shared/collaborators/InfoAndActions';

const SharedWith = observer(({ record, inviteFunction }) => {
  if (isEmpty(record.collaborators)) return null;
  const { t } = useTranslation();

  const owner = find(record.collaborators, { isOwner: true });
  const allCollaborators = filter(record.collaborators, { isOwner: false });

  return (
    <div>
      <h5 className="text-muted">{t('sidebar.tabs.shared_with.label')}</h5>
      <Collaborator collaborator={owner} />
      {map(allCollaborators, (c, i) => (
        <Collaborator key={i} collaborator={c} />
      ))}
    </div>
  );
});

export default SharedWith;
