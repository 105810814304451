import React from 'react';

import { observer } from 'mobx-react';
import { find, isEqual, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import classnames from 'classnames';

import useStore from '../../../../data/store';
import { getBenefits } from '../../../../helpers/product_helpers';
import Form from './Form';
import Switch from '../../../shared/Switch';

const Product = () => {
  const { products: store } = useStore();
  const { t } = useTranslation();

  const productGroup = store.groupByType[store.selectedPlanType];
  const benefits = getBenefits(store.selectedPlanType);
  const selectedPlan = find(productGroup, { interval: store.selectedInterval });
  return (
    <Row className="ml-1">
      <Col md={5} className="product-info border-right">
        <h5>{t('account.plan.benefits.title')}</h5>
        <ul className="product-benefits">
          {map(benefits, (benefit, i) => (
            <li key={i}>{t(`account.plan.benefits.${benefit}`)}</li>
          ))}
        </ul>
      </Col>
      <Col className="d-flex flex-column flex-grow-1">
        <div className="plans d-flex flex-column flex-grow-1">
          <Form product={selectedPlan} />
        </div>
      </Col>
    </Row>
  );
};

export default observer(Product);
