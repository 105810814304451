import React from 'react';

import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Switch = ({ isOn, handleToggle, text }) => (
  <>
    <input
      checked={isOn}
      onChange={handleToggle}
      className={classnames('react-switch-checkbox', { checked: isOn })}
      id="react-switch-new"
      type="checkbox"
    />
    <Label className="react-switch-label" htmlFor="react-switch-new">
      {text && <b>{text}</b>}
      <span className="react-switch-button" />
    </Label>
  </>
);

Switch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default Switch;
