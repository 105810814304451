import React from 'react';

import { Col, Row } from 'reactstrap';

import Profile from './components/users/Profile';
import Password from './components/users/Password';

const UserProfile = () => (
  <Row>
    <Col lg={6}>
      <div className="card-common">
        <Profile />
      </div>
    </Col>
    <Col lg={6}>
      <div className="card-common">
        <Password />
      </div>
    </Col>
  </Row>
);

export default UserProfile;
