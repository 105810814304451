import React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import UserContactsItem from './UserContactsItem';
import useStore from '../../../data/store';

const GroupContacts = () => {
  const { userGroups, users: store } = useStore();
  const records = store.getByGroupId(userGroups.activeGroupId);
  const { t } = useTranslation();

  return (
    <>
      <div className="group-header d-flex align-items-center mb-4">
        <Button color="success" onClick={() => userGroups.activeGroup.close()}>
          <FontAwesomeIcon icon="chevron-left" />
        </Button>
        <h4 className="m-0 ml-3">{userGroups.activeGroup.name}</h4>
      </div>
      <div className="d-flex flex-wrap align-items-start">
        {isEmpty(records) ? (
          <div className="no-contacts">{t('contacts.empty')}</div>
        ) : (
          map(records, (contact, i) => (
            <UserContactsItem contact={contact} key={i} />
          ))
        )}
      </div>
    </>
  );
};

export default observer(GroupContacts);
