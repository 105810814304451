import React from 'react';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { map } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';

const TreeCollaborators = ({ tree }) => (
  <div className="tree-collaborators">
    {map(tree.collaborators, (collaborator, i) => (
      <div
        className={collaborator.user.onlineStatus}
        key={i}
        id={`tc-${collaborator.id}`}
      >
        {collaborator.user.initials}
        <UncontrolledTooltip target={`tc-${collaborator.id}`}>
          {collaborator.user.fullName}
        </UncontrolledTooltip>
      </div>
    ))}
  </div>
);

TreeCollaborators.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default observer(TreeCollaborators);
