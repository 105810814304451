import { action, computed } from 'mobx';
import { filter, isEmpty } from 'lodash';

const selectable = {
  selected: false,
  toggleSelect: action(function(prevent = false) {
    if (prevent) return;
    this.update({ selected: !this.selected });
  }),
};

export default selectable;
