import {
  makeObservable,
  observable,
  flow,
  action,
  computed,
  extendObservable,
} from 'mobx';
import {
  filter,
  map,
  camelCase,
  find,
  isEqual,
  includes,
  uniqBy,
  isEmpty,
  isNull,
  startsWith,
  flatten,
  findIndex,
} from 'lodash';

import client from '../axiosClient';
import Drag from './drag';
import TreeTab from './tree_tab';
import selectable from '../mixins/selectable';

const handleTabs = (tree, tabs) => {
  if (isEmpty(tabs)) {
    tree.tabs = [
      new TreeTab(tree, tree, {
        root: true,
        active: true,
        nodeId: tree.id,
        name: tree.name,
      }),
    ];
    return;
  }

  tree.tabs = map(
    tabs,
    tab => new TreeTab(tree.store.createOrFindTree(tab.node_id), tree, tab)
  );
};

class Tree {
  /* eslint-disable */
  id;
  updatedAt;
  isOwner;
  name;
  createdBy;
  store;
  canAccess;
  treeBackgroundUrl;
  @observable featuredPreview;
  @observable nodes;
  @observable isInstanceOrWorld;
  @observable scope;
  @observable starred = false;
  @observable nodeType = 'general';

  // canvas properties
  @observable direction;
  @observable zoom;
  @observable canvas;
  @observable dragging;
  @observable controlBarView;
  @observable tabs = null;

  @observable removing = false;
  @observable fetching = false;
  @observable fetchingNodes = false;
  @observable fetchedNodes = false;
  @observable fetched = false;
  @observable multiSelect = false;
  @observable userIsInstanceAdmin = false;
  @observable fetchedCollaborators = false;
  @observable fetchingCollaborators = false;
  @observable search = {};
  @observable drag;
  @observable redirect = false;
  @observable assignedGroups = [];
  @observable unAuthorized = false;
  @observable pastingNodes = false;
  @observable protectedCanChange = false;
  /* eslint-enable */

  constructor(value, store) {
    extendObservable(this, selectable);
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'tabs')) {
          return handleTabs(this, value[k]);
        }
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    if (isNull(this.tabs)) handleTabs(this, []);

    this.store = store;
    this.drag = new Drag(this);
  }

  @computed
  get showFeaturedPreview() {
    return !isEmpty(this.featuredPreview) || this.hasBackgroundUrl;
  }

  @computed
  get canChange() {
    if (this.store.rootStore.app.embedded) return false;

    return this.protectedCanChange;
  }

  @computed
  get treeCoverUrl() {
    return this.featuredPreview || this.treeBackgroundUrl;
  }

  @computed
  get isActive() {
    return isEqual(this.store.activeTree?.id, this.id);
  }

  @computed
  get isMaster() {
    return isEqual(this.store.masterTree?.id, this.id);
  }

  @computed
  get isTutorial() {
    return isEqual(this.nodeType, 'tutorial');
  }

  @computed
  get backgroundUrl() {
    return this.rootNode?.backgroundUrl || this.treeBackgroundUrl;
  }

  @computed
  get hasBackgroundUrl() {
    return startsWith(this.backgroundUrl, 'http');
  }

  @computed
  get treeNodes() {
    return filter(this.store.rootStore.nodes.records, { rootId: this.id });
  }

  @computed
  get summarise() {
    return this.rootNode.summarise;
  }

  @computed
  get rootNode() {
    return find(this.treeNodes, r => isEqual(r.id, this.id));
  }

  @computed
  get selectedNodes() {
    return filter(this.treeNodes, { selected: true });
  }

  @computed
  get selectedNode() {
    return this.selectedNodes[0];
  }

  @computed
  get backgroundImageAttribution() {
    if (!this.hasBackgroundUrl) return {};
    const attribution = {};
    const params = new URLSearchParams(decodeURI(this.backgroundUrl));
    attribution.username = params.get('username');
    if (params.get('user_full_name')) {
      attribution.fullname = params
        .get('user_full_name')
        .split('+')
        .join(' ');
    }
    return attribution;
  }

  @computed
  get tags() {
    return uniqBy(flatten(map(this.treeNodes, node => node.tags)), 'id');
  }

  @computed
  get starredNodes() {
    return filter(this.treeNodes, { starred: true });
  }

  @computed
  get activeTab() {
    const tab = find(this.tabs, { active: true });
    return isEmpty(tab) ? this.tabs[0] : tab;
  }

  @computed
  get collaborators() {
    return filter(this.store.rootStore.nodeCollaborators.records, {
      collaborationId: this.id,
    });
  }

  @computed
  get collaboratorsAsUsers() {
    return map(this.collaborators, c => ({
      name: c.user.fullName,
      id: c.user.id,
    }));
  }

  @computed
  get comments() {
    return flatten(map(this.treeNodes, node => node.comments));
  }

  @computed
  get assignees() {
    return flatten(map(this.treeNodes, node => node.assignees));
  }

  @computed
  get attachments() {
    return flatten(map(this.treeNodes, node => node.attachments));
  }

  @computed
  get tabsAsJSON() {
    return map(this.tabs, tab => tab.asJSON);
  }

  @computed
  get tabIndex() {
    if (isEmpty(this.store.masterTree)) return 0;
    if (isEmpty(this.store.masterTree.tabs)) return 0;

    const index = findIndex(this.store.masterTree.tabs, { nodeId: this.id });

    return index === -1 ? 0 : index;
  }

  @action
  toggleMultiSelect() {
    this.update({ multiSelect: !this.multiSelect });
  }

  @action
  disableMultiSelect() {
    this.update({ multiSelect: false });
  }

  @action
  showSettings() {
    this.store.rootStore.sidebars.addSidebar({
      type: 'tree',
      nodeIds: [this.id],
      treeName: this.name,
    });
  }

  @action
  update(params) {
    if (includes(Object.keys(params), 'multiSelect')) {
      this.deselectAllNodes();
    }
    map(
      Object.keys(params),
      function(k) {
        if (isEqual(k, 'tabs')) return handleTabs(this, params[k]);

        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  deselectAllNodes() {
    map(this.store.rootStore.nodes.records, r => {
      r.update({ selected: false });
    });
  }

  @action
  defaultExpand() {
    map(this.treeNodes, node => node.toggleExpand(true));
    this.rootNode.toggleExpand(false);
  }

  @action
  refresh() {
    this.resetAssociations();
    this.fetchTreeAndNodes();
  }

  @action
  revoke() {
    this.redirect = true;
  }

  @action
  resetAssociations() {
    this.store.rootStore.sidebars.clearAll();

    map(this.collaborators, col => col.destroy());
    map(this.comments, com => com.softDestroy());
    map(this.assignees, ass => ass.destroy());
    map(this.attachments, att => att.destroyRecord());
    map(this.tags, tag => tag.destroy());
    map(this.treeNodes, node => node.softDestroy());
  }

  @action
  updateTabs() {
    this.updateCurrentState({ tabs: this.tabsAsJSON });
  }

  @action
  clearTabs() {
    this.tabs = [];
  }

  @action
  softDestroy() {
    this.store.records.splice(this.store.records.indexOf(this), 1);
  }

  @flow
  *fetchNodes() {
    if (this.fetchingNodes || this.fetchedNodes) return;

    this.fetchingNodes = true;

    map(this.treeNodes, node => node.update({ fetching: true }));

    const response = yield client.get(
      `/api/v1/nodes/${this.id}/tree_nodes.json`
    );

    if (response.data.success) {
      map(response.data.nodes, n => {
        const node = this.store.rootStore.nodes.getById(n.id);
        node.update({ ...n, fetched: true, fetching: false });
      });

      this.rootNode.update({
        ...response.data.node,
        fetched: true,
        fetching: false,
      });

      this.fetchingNodes = false;
      this.fetchedNodes = true;
    }
  }

  @flow
  *fetchTreeAndNodes(embed = false, apiKey = null) {
    if (this.fetched || this.fetching) return { success: true };

    this.fetching = true;

    const response = yield client.get(
      `/api/v1/nodes/${this.id}/${embed ? 'embed_nodes' : 'root_nodes'}.json`,
      {
        params: {
          api_key: embed ? apiKey : '',
        },
      }
    );

    if (response.data.success) {
      this.update(response.data.tree);
      this.store.rootStore.nodes.setRecords(response.data.nodes);

      this.fetched = true;
    } else {
      this.unAuthorized = true;
    }

    this.fetching = false;

    return response.data;
  }

  @flow
  *destroy() {
    this.removing = true;
    const response = yield* this.store.rootStore.nodes.updateNodes([this.id], {
      action_type: 'remove_all',
      node_type: 'tree',
    });

    return response.data.success;
  }

  @flow
  *duplicate() {
    const response = yield client.get(
      `/api/v1/nodes/${this.id}/duplicate.json`
    );
    if (response.data.success) {
      this.store.records.unshift(new Tree(response.data.new_tree, this.store));
      return true;
    }

    this.store.rootStore.users.update({ showEnhance: response.data.message });
  }

  @flow
  *updateCurrentState(params) {
    let doUpdate = true;
    // do not store current state if we in embedded mode NOR for public trees
    if (this.rootNode.canChange) {
      const response = yield client.put(
        `/api/v1/nodes/${this.id}/current_state.json`,
        params
      );
      doUpdate = response.data.success;
    }
    if (doUpdate) {
      this.update(params);
    }
  }

  @flow
  *fetchMyCollaborators() {
    if (this.fetchingCollaborators || this.fetchedCollaborators) return;

    this.fetchingCollaborators = true;
    const response = yield* this.store.rootStore.nodeCollaborators.fetchCollaborators(
      this.id,
      'all'
    );

    if (response.data.success) {
      this.store.rootStore.nodeCollaborators.setRecords(response.data.results);
      this.fetchedCollaborators = true;
      this.fetchingCollaborators = false;
    }
  }

  @flow
  *toggleStarred() {
    const response = yield client.post(
      `/api/v1/nodes/${this.id}/toggle_starred.json`
    );
    if (response.data.success) {
      this.update({ starred: !this.starred });
      this.store.refreshStarred();
    }
  }
}

export default Tree;
