import { makeObservable, observable, action, flow, computed } from 'mobx';
import { camelCase, isEmpty, isEqual, map } from 'lodash';

import { parameterize } from '../../helpers/shared_helpers';
import client from '../axiosClient';

class Collaborator {
  /* eslint-disable */
  id;
  isOwner;
  userId;
  invitedById;
  store;
  collaborationId;
  collaborationType;

  @observable accessType;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'user')) {
          this.userId = value[k].id;
          return;
        }
        if (isEqual(k, 'invited_by')) {
          this.invitedById = value[k]?.id;
          return;
        }

        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  @computed
  get user() {
    return this.store.rootStore.users.getById(this.userId);
  }

  @computed
  get invitedBy() {
    return this.store.rootStore.users.getById(this.invitedById);
  }

  @computed
  get tree() {
    return this.store.rootStore.trees.activeTree;
  }

  @computed
  get areYouInvitor() {
    if (isEmpty(this.invitedById)) return false;

    return isEqual(this.invitedById, this.store.rootStore.users.currentUser.id);
  }

  @computed
  get canBeChanged() {
    const { currentUser } = this.store.rootStore.users;

    return isEqual(currentUser.id, this.invitedById) || this.isOwner;
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );

    this.updateServer(parameterize(params));
  }

  @action
  destroy() {
    this.store.records.splice(this.store.records.indexOf(this), 1);
  }

  @flow
  *updateServer(params) {
    const response = yield client.put(`/api/v1/collaborators/${this.id}.json`, {
      ...params,
    });

    if (response.data.success && isEqual(params.access_type, 'revoke')) {
      this.destroy();
    }
  }
}

export default Collaborator;
