import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { isEqual } from 'lodash';
import useStore from '../../../../data/store';
import Form from './Form';
import { getCommonValues } from '../../../../helpers/shared_helpers';

const GroupSelector = observer(({ records, recordType }) => {
  const rootStore = useStore();

  const store = isEqual(recordType, 'node')
    ? rootStore.nodeGroups
    : rootStore.userGroups;

  const groups = store.getByIds(getCommonValues(records, 'groups'));

  return (
    <div className="groups-selector">
      <Form
        records={records}
        groups={groups}
        recordType={recordType}
        store={store}
      />
    </div>
  );
});

GroupSelector.propTypes = {
  records: PropTypes.instanceOf(Object).isRequired,
  recordType: PropTypes.string.isRequired,
};

export default GroupSelector;
