import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import useStore from '../../../data/store';
import { revealNodeInDomWithSidebar } from '../../../helpers/node_helpers';

const SearchedNode = ({ nodeId }) => {
  const { nodes: store } = useStore();

  const node = store.getById(nodeId);

  const handleClick = () => {
    revealNodeInDomWithSidebar(node);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      onClick={handleClick}
      className={classnames({
        'searched-cell-info': true,
        [`status-${node.status}`]: true,
      })}
    >
      {node.name}
    </div>
  );
};

SearchedNode.propTypes = {
  nodeId: PropTypes.string.isRequired,
};

export default SearchedNode;
