import React from 'react';

import { Label } from 'reactstrap';
import Select from 'react-select';
import { find } from 'lodash';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../../helpers/shared_helpers';
import { scopes } from '../../../helpers/tree_helpers';

const TreeScopeSelector = observer(({ node }) => {
  const handleChange = selectedValue => {
    const val = selectedValue.value;

    node.updateNodeOnServer({ scope: val }).then(res => {
      if (res.data.success) {
        node.tree.update({ scope: val });
      }
    });
  };

  const { userIsInstanceAdmin } = node.tree;

  const { t } = useTranslation();
  const myScopes = scopes(userIsInstanceAdmin, t);

  return (
    <div className="status-selector">
      <Label>{t('sidebar.tree_scope')}</Label>
      <Select
        options={myScopes}
        value={find(myScopes, { value: node.tree.scope })}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
});

TreeScopeSelector.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default TreeScopeSelector;
