import React from 'react';

import { observer } from 'mobx-react';
import Draggable from 'react-draggable';

import { isEmpty, isEqual } from 'lodash';
import { getNodeBodyClassNames } from '../../../../helpers/node_helpers';

const NodeDraggable = observer(({ node, children }) => {
  const { drag, zoom } = node.tree;

  const handleStart = () => {
    node.blurInput();
    drag.update({ dragging: true, draggingNode: node.id });
    node.update({ dragging: true, selected: false });
  };

  const handleStop = () => {
    const parentId = isEqual(drag.type, 'parent')
      ? drag.nodeId
      : drag.node?.parentId;

    if (!isEmpty(parentId)) {
      node.updatePosition({ parent_id: parentId, position: drag.position });
    }

    drag.update({ dragging: false, draggingNode: null });
    node.update({ dragging: false });
  };

  const handleDrag = () => {
    drag.checkIntersection();
  };

  const renderBody = () => (
    <div id={node.bodyId} className={getNodeBodyClassNames(node)}>
      {children}
    </div>
  );

  if (node.isOrphanContainerNode || node.root) return renderBody();

  return (
    <Draggable
      onStop={handleStop}
      handle={`#button-move-${node.id}`}
      onStart={handleStart}
      onDrag={handleDrag}
      position={node.dragging ? null : { x: 0, y: 0 }}
      scale={zoom}
    >
      {renderBody()}
    </Draggable>
  );
});

export default NodeDraggable;
