import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import ControlBarButton from './Button';

const DirectionToggleSet = observer(({ tree }) => {
  const { t } = useTranslation();

  return (
    <div className="control-bar-btn change-direction">
      <ControlBarButton
        paramKey="direction"
        paramValue="left"
        tree={tree}
        title={t('control_bar.tooltip.direction_toggle_left')}
        active={isEqual('left', tree.direction)}
      >
        <FontAwesomeIcon icon="sitemap" rotation={270} data-toggle="tooltip" />
      </ControlBarButton>
      <ControlBarButton
        paramKey="direction"
        paramValue="up"
        tree={tree}
        title={t('control_bar.tooltip.direction_toggle_center')}
        active={isEqual('up', tree.direction)}
      >
        <FontAwesomeIcon icon="sitemap" data-toggle="tooltip" />
      </ControlBarButton>
      <ControlBarButton
        paramKey="direction"
        paramValue="right"
        tree={tree}
        title={t('control_bar.tooltip.direction_toggle_right')}
        active={isEqual('right', tree.direction)}
      >
        <FontAwesomeIcon icon="sitemap" rotation={90} data-toggle="tooltip" />
      </ControlBarButton>
    </div>
  );
});

DirectionToggleSet.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default DirectionToggleSet;
