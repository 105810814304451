import React from 'react';

import { observer } from 'mobx-react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { useTranslation } from 'react-i18next';

import Plans from './Plans';
import useStore from '../../../../data/store';

const PlansModal = () => {
  const { products: store } = useStore();
  const { t } = useTranslation();

  return (
    <Modal
      size="lg"
      isOpen={store.showModal}
      toggle={() => store.togglePlans()}
      backdrop="static"
    >
      <ModalHeader toggle={() => store.togglePlans()}>
        {t('account.plans.heading')}
      </ModalHeader>
      <ModalBody style={{ backgroundColor: '#f3f3f3' }}>
        <Plans />
      </ModalBody>
    </Modal>
  );
};

export default observer(PlansModal);
