import { action, makeAutoObservable } from 'mobx';
import { isEmpty, isEqual, map } from 'lodash';

import consumer from '../../../channels/consumer';

class ActionCableStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.consumer = consumer;

    makeAutoObservable(this);
  }

  startListeningToUserChannel() {
    if (!this.rootStore.users.userSignedIn) return;

    const obj = this;
    let timeout = null;
    this.consumer.subscriptions.create(
      { channel: 'UserChannel', id: obj.rootStore.users.currentUser.id },
      {
        connected() {
          timeout = setInterval(() => {
            if (!obj.rootStore.users.userSignedIn) clearInterval(timeout);
            obj.rootStore.users.currentUser.heartbeat();
          }, 15000);
        },

        disconnected() {
          clearInterval(timeout);
        },

        received(data) {
          setTimeout(() => {
            switch (data.type) {
              case 'notification':
                obj.rootStore.notifications.addNewNotification(
                  data.notification
                );
                // obj.rootStore.notification('info', data.notification.message);
                return;
              case 'node':
                return obj.handleNodeUpdates(data);
              case 'collaborator':
                return obj.handleCollaboratorUpdates(data);
              case 'board':
                return obj.handleBoardUpdates(data);
              default:
                return null;
            }
          }, 1000);
        },
      }
    );
  }

  @action
  handleBoardUpdates(data) {
    const board = this.rootStore.boards.getById(data.id);

    switch (data.action) {
      case 'refresh':
        if (!isEmpty(board)) board.refreshState();
        return null;
      default:
        return null;
    }
  }

  @action
  handleCollaboratorUpdates(data) {
    switch (data.collaboration_type) {
      case 'Node':
        return this.handleTreeUpdates(data);
      default:
        return null;
    }
  }

  @action
  handleTreeUpdates(data) {
    const tree = this.rootStore.trees.activeTree;
    if (!isEqual(tree.id, data.collaboration_id)) return;

    switch (data.action) {
      case 'refresh':
        return tree.refresh();
      case 'revoke':
        return tree.revoke();
      default:
        return null;
    }
  }

  @action
  handleNodeUpdates(data) {
    const nodeStore = this.rootStore.nodes;
    const nodes = nodeStore.getByIds(data.id || []);
    let node;
    let targetNode;

    switch (data.action) {
      case 'update':
        return map(nodes, n => !isEmpty(n) && n.refresh());

      case 'destroy':
        map(nodes, n => !isEmpty(n) && n.deleteAndSetOrphans());
        this.handleNodePositionUpdates(data.sibling_nodes);
        return null;

      case 'create':
        node = nodeStore.addNode({ id: data.id[0] });
        if (!isEmpty(node)) node.fetch();
        this.handleNodePositionUpdates(data.sibling_nodes);
        return null;

      case 'create_multiple':
        map(data.nodes, n => {
          nodeStore.addNode({ ...n, fetched: true });
        });

        return null;

      case 'reorder':
        map(data.nodes, n => {
          const rNode = nodeStore.getById(n.id);
          if (isEmpty(rNode)) return;
          rNode.update(n);
        });
        this.handleNodePositionUpdates(data.sibling_nodes);
        return null;

      case 'association_refresh':
        return this.handleAssociationRefresh(data, nodes);

      case 'externalised':
        if (data.other_data.externalised) {
          node = nodeStore.getById(data.other_data.node.id);

          if (!isEmpty(node)) return;

          targetNode = nodeStore.getById(data.other_data.affected_node_id);

          targetNode.popout(
            { data: { node: data.other_data.node } },
            isEqual(data.user_id, this.rootStore.users.currentUser.id)
          );
        } else {
          targetNode = nodeStore.getById(data.other_data.affected_node_id);

          if (isEmpty(targetNode)) return;

          targetNode.undoPopout({ data: { nodes: data.other_data.nodes } });
        }

        return;

      default:
        return null;
    }
  }

  @action
  handleNodePositionUpdates(nodes) {
    const nodeStore = this.rootStore.nodes;

    map(nodes, s => {
      const node = nodeStore.getById(s.id);
      if (isEmpty(node)) return;
      if (!isEqual(node.position, s.position)) {
        node.update({ position: s.position });
      }
    });
  }

  @action
  handleAssociationRefresh = (data, nodes) => {
    switch (data.refresh_type) {
      case 'assignees':
        return map(nodes, n => n.refreshAssignees());
      case 'tags':
        return map(nodes, n => n.refreshTags());
      case 'comments':
        return map(nodes, n => n.refreshComments());
      case 'attachments':
        return map(nodes, n => n.refreshAttachments());
      default:
        return null;
    }
  };
}

export default ActionCableStore;
