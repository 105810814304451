import React, { useState } from 'react';

import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { truncate } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import Player from '../../player';

const Attachment = ({ attachment, edit, summary, treeAttachmentsView }) => {
  const { t } = useTranslation();
  const [play, setPlay] = useState(false);

  const deleteAttachment = () => {
    confirmAlert({
      title: t('alert.titles.attention'),
      message: t('sidebar.attachments.delete'),
      buttons: [
        {
          label: t('alert.buttons.yes'),
          onClick: () => attachment.destroy(),
        },
        {
          label: t('alert.buttons.no'),
        },
      ],
    });
  };

  return (
    <div className="node-attachment">
      {attachment.isDocument ? (
        <a
          title={attachment.filename}
          className="p-0 attachment-icon text-center"
          href={attachment.attachmentUrl}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon="file-alt" />
          <div>{truncate(attachment.filename, { length: 10 })}</div>
        </a>
      ) : (
        <>
          <Button
            onClick={() => setPlay(true)}
            color="link"
            title={attachment.filename}
            className="p-0 attachment-icon text-center"
          >
            <FontAwesomeIcon icon="file-video" />
            <div>{truncate(attachment.filename, { length: 10 })}</div>
          </Button>
          <Modal isOpen={play}>
            <ModalHeader toggle={() => setPlay(false)}>
              <Player url={attachment.attachmentUrl} />
            </ModalHeader>
          </Modal>
        </>
      )}
      <div className="attachment-info">
        <div className="attachment-name">
          <span className="text-muted">
            {treeAttachmentsView && `${attachment.node.name} :: `}
          </span>
          {attachment.attachmentTitle}
        </div>
        {!summary && (
          <div className="actions align-items-center">
            <Button tag="a" href={attachment.attachmentUrl} target="_blank">
              <FontAwesomeIcon
                icon="download"
                data-toggle="tooltip"
                title={t('sidebar.attachments.tooltips.download')}
              />
            </Button>
            {!attachment.featured && !treeAttachmentsView && (
              <Button onClick={edit}>
                <FontAwesomeIcon
                  icon="edit"
                  data-toggle="tooltip"
                  title={t('sidebar.attachments.tooltips.change')}
                />
              </Button>
            )}
            {!treeAttachmentsView && (
              <Button onClick={deleteAttachment}>
                <FontAwesomeIcon
                  icon="trash"
                  data-toggle="tooltip"
                  title={t('sidebar.attachments.tooltips.delete')}
                />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Attachment.defaultProps = {
  summary: false,
  treeAttachmentsView: false,
  edit: () => {},
};

Attachment.propTypes = {
  attachment: PropTypes.instanceOf(Object).isRequired,
  edit: PropTypes.func,
  summary: PropTypes.bool,
  treeAttachmentsView: PropTypes.bool,
};

export default observer(Attachment);
