import { computed, makeObservable, observable } from 'mobx';
import { camelCase, isEmpty, map } from 'lodash';
import moment from 'moment';

class Subscription {
  /* eslint-disable */
  id;
  expiresAt;
  @observable quantity;

  /* eslint-enable */

  constructor(value, account) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.account = account;
  }

  @computed
  get availableLicenses() {
    return this.quantity - this.account.assignedUsers;
  }
}

export default Subscription;
