import { action, computed, flow, makeObservable, observable } from 'mobx';
import { camelCase, isEmpty, isEqual, map } from 'lodash';
import Subscription from './subscription';
import client from '../axiosClient';
import { redirectTo } from '../../helpers/shared_helpers';

class Account {
  /* eslint-disable */
  productId;
  subscription;
  @observable assignedUsers;
  store;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);
    this.store = store;
    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'subscription') && !isEmpty(value[k])) {
          this.subscription = new Subscription(value[k], this);
          return;
        }
        this[camelCase(k)] = value[k];
      }.bind(this)
    );
  }

  @computed
  get hasSubscription() {
    return !isEmpty(this.subscription);
  }

  @computed
  get product() {
    return this.store.rootStore.products.getById(this.productId);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *subscribe(params) {
    const response = yield client.post(
      `/api/v1/billing/subscribe.json`,
      params
    );
    if (response.data.success) {
      const stripe = Stripe(response.data.stripe_pub_key);
      stripe
        .redirectToCheckout({
          sessionId: response.data.session_id,
        })
        .then(function(result) {
          console.log(result);
        })
        .catch(function(err) {
          console.log(err);
        });
      return true;
    }
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *manage() {
    const response = yield client.get(
      `/api/v1/billing/manage_subscription.json`
    );
    if (response.data.success) {
      redirectTo(response.data.url);
      return true;
    }
  }

  @flow
  *manageAccountUser(userId, linkAction) {
    const response = yield client.post(
      `/api/v1/billing/manage_account_user.json`,
      { user_id: userId, link_action: linkAction }
    );

    if (response.data.success) {
      const user = this.store.getById(userId);
      const isLinked = isEqual(linkAction, 'link');
      user.update({ managedByYou: isLinked, hasActiveSubscription: isLinked });

      const count = isLinked ? +1 : -1;
      this.update({ assignedUsers: this.assignedUsers + count });
    }

    return response.data.success;
  }
}

export default Account;
