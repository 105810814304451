import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const NodeThumbnail = ({ node }) => {
  const { t } = useTranslation();
  if (node.showPlayButton || node.hasThumbnail) {
    return (
      <div
        className={classnames('node-thumbnail', {
          'has-play-btn-on-img': node.showPlayButton && node.hasThumbnail,
        })}
      >
        {node.hasThumbnail && <img src={node.thumbnailUrl} alt="thumbnail" />}

        {node.showPlayButton && (
          <Button
            title={t('nodes.actions.tooltip.play')}
            onClick={() => node.store.rootStore.playlist.start(node)}
          >
            <FontAwesomeIcon icon="play" data-toggle="tooltip" />
          </Button>
        )}
      </div>
    );
  }

  return null;
};

NodeThumbnail.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(NodeThumbnail);
