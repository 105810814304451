import { filter, isEmpty, map } from 'lodash';

const SelectableStore = {
  controlBarExpanded: false,

  get selectedRecords() {
    return filter(this.records, { selected: true });
  },
  get hasSelected() {
    return !isEmpty(this.selectedRecords);
  },
  get selectedRecordsCount() {
    return this.selectedRecords.length;
  },

  deselectAll() {
    map(this.records, record => record.update({ selected: false }));
  },
  toggleControlBarExpanded() {
    this.update({ controlBarExpanded: !this.controlBarExpanded });
  },
};

export default SelectableStore;
