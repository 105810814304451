import React from 'react';

import { map } from 'lodash';
import { Card, CardBody } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TimeAgo from '../../shared/TimeAgo';

const NotificationList = ({ records, title, showTitle }) => {
  console.log(records);
  return (
    <div className="notification-list">
      {showTitle && <h5>{title}</h5>}
      {map(records, (record, i) => (
        <Card
          key={i}
          className={classNames('mb-2', {
            'notification-viewed': record.isViewed,
          })}
        >
          <CardBody>
            <div
              key={i}
              className="notification-container small"
              dangerouslySetInnerHTML={{ __html: record.message }}
            />
            <div className="small text-muted mt-2">
              <TimeAgo date={record.createdAt} />
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

NotificationList.propTypes = {
  records: PropTypes.instanceOf(Array).isRequired,
  showTitle: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default observer(NotificationList);
