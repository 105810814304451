import React, { useState, useEffect, Children, cloneElement } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import useStore from '../../../data/store';

const TreesWrapper = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { trees } = useStore();

  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));

    return () =>
      window.removeEventListener('resize', () =>
        setWindowWidth(window.innerWidth)
      );
  }, []);

  const activeTreeIndex = trees.activeTree?.tabIndex || 0;
  const transformValue = activeTreeIndex * -windowWidth;

  return (
    <div className="tree-container-wrapper w-100">
      <div className="d-flex">
        {Children.map(children, child => cloneElement(child, { windowWidth }))}
      </div>
    </div>
  );
};

TreesWrapper.propTypes = {
  children: PropTypes.node,
};

export default observer(TreesWrapper);
