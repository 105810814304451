import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';

import useStore from '../../../data/store';
import { KEYCLOAK_AUTH_URL } from '../../../helpers/shared_helpers';

const PromoPopup = () => {
  const { app } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (app.shouldShowPromoPopup)
      setTimeout(() => app.update({ showPromoPopup: true }), 25 * 1000);
  }, []);

  useEffect(() => {
    app.update({ promoPopupShown: true });
  }, []);

  if (app.showPromoPopup) {
    confirmAlert({
      closeOnClickOutside: false,
      title: t('alert.promopopup.title'),
      message: t('alert.promopopup.message'),
      buttons: [
        {
          label: t('alert.promopopup.options.book_call'),
          className: 'btn btn-success',
          onClick: () => {
            app.update({ showBookingCalendar: true });
          },
        },
        {
          label: t('alert.promopopup.options.signup'),
          className: 'btn btn-info',
          onClick: () => {
            window.open(KEYCLOAK_AUTH_URL, '_blank');
          },
        },
        {
          label: t('alert.promopopup.options.nothing'),
          className: 'btn btn-danger',
          onClick: () => {},
        },
      ],
    });
  }
  return <></>;
};
export default observer(PromoPopup);
