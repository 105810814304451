import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';

import { useTranslation } from 'react-i18next';

const NodeDeleteAction = observer(({ node, sidebar }) => {
  if (node.fetching || node.isOrphanContainerNode) return null;
  const { t } = useTranslation();
  const deleteNode = () => {
    confirmAlert({
      title: t('alert.titles.attention'),
      message: t('sidebar.delete_node.alert_message'),
      buttons: [
        {
          label: t('alert.buttons.yes'),
          onClick: () => node.destroy(),
        },
        {
          label: t('alert.buttons.no'),
        },
      ],
    });
  };

  return (
    <>
      {sidebar ? (
        <Button onClick={deleteNode} color="danger w-100">
          {t('general.delete')}
        </Button>
      ) : (
        <Button className="btn-remove" onClick={deleteNode}>
          <FontAwesomeIcon
            icon="trash"
            data-toggle="tooltip"
            title={t('general.delete_item')}
          />
        </Button>
      )}
    </>
  );
});

NodeDeleteAction.defaultProps = {
  sidebar: false,
  callback: () => {},
};

NodeDeleteAction.propTypes = {
  node: PropTypes.instanceOf(Object),
  sidebar: PropTypes.bool,
  callback: PropTypes.func,
};

export default NodeDeleteAction;
