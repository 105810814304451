import React from 'react';

import { Button, InputGroup } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { find, isEqual, map } from 'lodash';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StoreSortWidget = ({ stores, options, styles }) => {
  const { t } = useTranslation();

  const createSelectOptions = (opts, type) =>
    map(opts, option => ({
      value: option,
      label: t(`sort_by.${type}.${option}`),
    }));

  const handleChange = params => {
    map(stores, s => {
      s.update(params);
    });
  };

  const typeOpts = createSelectOptions(options, 'options');
  const store = stores[0];
  const isAscending = isEqual(store.sortMode, 'asc');

  return (
    <InputGroup className="filter-sort-widget">
      <div className="sort-type">
        <Select
          type="select"
          value={find(typeOpts, { value: store.sortType })}
          options={typeOpts}
          onChange={e => handleChange({ sortType: e.value, sortMode: 'asc' })}
          styles={styles}
        />
      </div>
      <Button
        color="light ml-1"
        onClick={() => handleChange({ sortMode: isAscending ? 'desc' : 'asc' })}
      >
        <FontAwesomeIcon
          icon={isAscending ? 'sort-amount-down-alt' : 'sort-amount-down'}
        />
      </Button>
    </InputGroup>
  );
};

StoreSortWidget.defaultProps = {
  options: [],
};

StoreSortWidget.propTypes = {
  stores: PropTypes.instanceOf(Array).isRequired,
  options: PropTypes.instanceOf(Array),
  styles: PropTypes.instanceOf(Object),
};

export default observer(StoreSortWidget);
