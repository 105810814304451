import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Comment from './Comment';
import Form from './Form';

const NodeComments = observer(({ node }) => {
  useEffect(() => {
    const scrollContainer = document.getElementsByClassName(
      'cell-comments-list'
    )[0];
    scrollContainer.scrollTop = scrollContainer.scrollHeight;
  }, [node.comments]);
  const { t } = useTranslation();
  return (
    <div className="cell-comments">
      <div className="cell-comments-form">
        <Form node={node} />
      </div>
      <div className="border-top mt-3 pt-3 cell-comments-list">
        {isEmpty(node.comments) ? (
          <div>{t('sidebar.comments.none')}</div>
        ) : (
          map(node.comments, (comment, i) => (
            <Comment comment={comment} key={i} node={node} />
          ))
        )}
      </div>
    </div>
  );
});

NodeComments.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default NodeComments;
