import { makeObservable, flow, observable, action } from 'mobx';
import { filter, includes, isEmpty, isEqual, map, uniqBy } from 'lodash';

import client from '../axiosClient';
import Tag from '../entities/tag';

class TagStore {
  @observable records = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  getByIds(ids) {
    return uniqBy(
      filter(this.records, r => includes(ids, r.id)),
      'id'
    );
  }

  getMultipleById(id) {
    return filter(this.records, r => isEqual(r.id, id));
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setRecords(records) {
    this.records = [...this.records, ...map(records, r => new Tag(r, this))];
  }

  @flow
  *destroyByRecordIds(tagId, nodeIds) {
    const response = yield client.put('/api/v1/nodes/remove_tag.json', {
      tag_id: tagId,
      node_ids: nodeIds,
    });

    if (response.data.success) {
      const recordsToBeDeleted = filter(
        this.records,
        r => isEqual(tagId, r.id) && includes(nodeIds, r.nodeId)
      );

      map(recordsToBeDeleted, r => r.destroy());
    }
  }

  @flow
  *fetchNodeTags(id, setRecords = true) {
    const response = yield client.get(`/api/v1/nodes/${id}/tags.json`);
    if (response.data.success) {
      if (setRecords) this.setRecords(response.data.results);

      return response.data.results;
    }

    return [];
  }

  @flow
  *addByRecordIds(tag, nodeIds) {
    const response = yield client.post('/api/v1/nodes/add_tag.json', {
      node_ids: nodeIds,
      tag,
    });

    if (response.data.success) this.setRecords(response.data.results);
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *searchTags(rootId, inputValue, board = false) {
    const response = yield client.get(
      `/api/v1/tags.json?root=${rootId}&query=${inputValue}`,
      {
        params: {
          root: isEmpty(rootId) ? null : rootId,
          query: inputValue,
          board,
        },
      }
    );

    return response.data;
  }
}

export default TagStore;
