import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const Starred = ({ record }) => (
  <Button
    className="p-0 star-toggle"
    color="link"
    onClick={() => record.toggleStarred()}
  >
    <FontAwesomeIcon
      icon={{ iconName: 'star', prefix: record.starred ? 'fas' : 'far' }}
    />
  </Button>
);

Starred.propTypes = {
  record: PropTypes.instanceOf(Object).isRequired,
};

export default observer(Starred);
