import React from 'react';

import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import SharedWith from '../../shared_with';
import ContentContainer from './ContentContainer';
import TreeScopeSelector from '../../tree_scope_selector';
import NodeTypeSelector from '../../nodes/node_type_selector';

const BoardSharedWith = observer(({ sidebar }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer title={t('sidebar.tabs.shared_with.label')}>
      <SharedWith record={sidebar.board} />
    </ContentContainer>
  );
});

export default BoardSharedWith;
