import React from 'react';

import { observer } from 'mobx-react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import {
  customStyles,
  recordsAsSelectOptions,
} from '../../../../helpers/shared_helpers';
import { revealNodeInDomWithSidebar } from '../../../../helpers/node_helpers';

const StarredList = ({ tree }) => {
  const { t } = useTranslation();
  return (
    <div className="search-inputs">
      <Select
        className="m-0"
        id="quick-nav"
        placeholder={t('control_bar.starred.placeholder')}
        value={null}
        menuPlacement="top"
        onChange={selectedOption => {
          tree.disableMultiSelect();
          const node = tree.store.rootStore.nodes.getById(selectedOption.value);
          revealNodeInDomWithSidebar(node, false);
        }}
        styles={customStyles}
        options={recordsAsSelectOptions(tree.starredNodes)}
      />
    </div>
  );
};

StarredList.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default observer(StarredList);
