import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { find, isEmpty, isEqual, map } from 'lodash';
import { Button, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const QuizForm = ({ node }) => {
  const selectedAnswer = i => find(node.quizAnswers, { questionIndex: i });

  const isAnswered = i => !isEmpty(selectedAnswer(i));

  return (
    <div className="quiz-form">
      {node.quiz?.title && <h3>{node.quiz.title}</h3>}
      {map(node.enabledQuizQuestions, (question, i) => (
        <Card key={i} className="border-0">
          <CardBody>
            <h4>{question.title}</h4>
            <div className="question-options">
              {map(question.options, (opt, index) => {
                const isAnsweredQuestion = isAnswered(i);
                const selected = selectedAnswer(i);
                const isSelectedAnswer =
                  isAnsweredQuestion && isEqual(selected.answerIndex, index);
                const isWrongAnswer =
                  isAnsweredQuestion && isSelectedAnswer && !opt.is_correct;
                const isRightAnswer = isAnsweredQuestion && opt.is_correct;
                const isGivenAnswer = isRightAnswer || isWrongAnswer;

                return (
                  <div className="question-option" key={index}>
                    <Button
                      className={classnames({
                        'text-danger': isWrongAnswer,
                        'text-success': isRightAnswer,
                      })}
                      color="link"
                      disabled={isAnsweredQuestion}
                      onClick={() => {
                        if (isAnsweredQuestion) return;
                        node.update({
                          quizAnswers: [
                            ...node.quizAnswers,
                            { questionIndex: i, answerIndex: index },
                          ],
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={{
                          iconName: 'circle',
                          prefix:
                            isWrongAnswer || isRightAnswer ? 'fas' : 'far',
                        }}
                      />
                    </Button>
                    <span
                      className={classnames({
                        'text-danger': isWrongAnswer,
                        'text-success': isRightAnswer,
                      })}
                    >
                      {opt.title}

                      {isGivenAnswer && (
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={isWrongAnswer ? 'times' : 'check'}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

QuizForm.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(QuizForm);
