import React from 'react';

import { Button } from 'reactstrap';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import useStore from '../../../data/store';

const GroupRow = ({ group }) => {
  const { nodeGroups, trees: store, app } = useStore();
  const activateGroup = () => {
    store.update({ activeTab: 'all_trees' });
    group.open();
    app.update({ showGallerySidebar: false });
  };
  return (
    <li>
      <Button
        id={group.id}
        className={classnames({
          'text-success':
            nodeGroups.hasActiveGroup &&
            isEqual(nodeGroups.activeGroupId, group.id),
        })}
        onClick={() => {
          activateGroup();
        }}
      >
        <FontAwesomeIcon className="text-success" icon="folder" /> {group.name}
      </Button>
    </li>
  );
};

GroupRow.propTypes = {
  group: PropTypes.instanceOf(Object).isRequired,
};

export default observer(GroupRow);
