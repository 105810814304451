import {
  flow,
  observable,
  action,
  computed,
  extendObservable,
  makeObservable,
} from 'mobx';

import {
  camelCase,
  filter,
  find,
  includes,
  isEmpty,
  isEqual,
  map,
  orderBy,
  reject,
  uniqBy,
} from 'lodash';

import client from '../axiosClient';
import Tree from '../entities/tree';
import Search from '../entities/search';
import selectableStore from '../mixins/selectable_store';
import Pagination from '../entities/pagination';

class TreeStore {
  /* eslint-disable */
  @observable records = [];
  @observable starred = [];
  @observable fetching = false;
  @observable sortType = 'updatedAt';
  @observable sortMode = 'desc';
  @observable activeTreeId;
  @observable masterTreeId;
  pagination = null;
  currentFilter = {};
  blank = ''
  @observable searchQuery = '';
  @observable activeTab = 'all_trees';
  /* eslint-enable */

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.pagination = new Pagination({ page: 1 }, this);
    makeObservable(this);
    extendObservable(this, selectableStore);
  }

  getByGroupId(records = this.records, groupId) {
    return filter(
      records,
      r => !isEmpty(find(r.assignedGroups, { id: groupId }))
    );
  }

  getByIds(ids) {
    return filter(this.records, r => includes(ids, r.id));
  }

  getById(id) {
    return find(this.records, { id });
  }

  filteredRecords(groupId = this.activeGroup?.id) {
    let records = filter(this.records, this.tabQuery);
    if (!isEmpty(this.sortType)) records = this.applySorting(records);

    return records;
  }

  applySorting(records = this.records) {
    return orderBy(records, [this.sortType], [this.sortMode]);
  }

  refreshStarred() {
    this.fetchStarred();
  }

  starredTrees() {
    return orderBy(this.starred, ['name'], ['asc']);
  }

  @computed
  get activeTree() {
    return this.getById(this.activeTreeId);
  }

  @computed
  get masterTree() {
    return this.getById(this.masterTreeId);
  }

  @computed
  get tabQuery() {
    const query = {};
    switch (this.activeTab) {
      case 'all_trees':
        break;
      case 'starred':
        query.starred = true;
        break;
      case 'shared_with_me':
        query.isOwner = false;
        break;
      case 'my_trees':
        query.isOwner = true;
        break;
      case 'public_trees':
        query.scope = 'world';
        break;
      default:
        break;
    }

    return query;
  }

  @computed
  get recordsToShow() {
    return this.filteredRecords();
  }

  @computed
  get activeGroup() {
    return this.rootStore.nodeGroups.activeGroup;
  }

  @computed
  get hasRecords() {
    return !isEqual(this.records.length, 0);
  }

  @action
  toggleSortMode() {
    if (isEqual(this.sortMode, 'asc')) return this.update({ sortMode: 'desc' });

    this.update({ sortMode: 'asc' });
  }

  @action
  setActiveTree(id) {
    const tree = this.createOrFindTree(id);
    this.activeTreeId = id;

    return tree;
  }

  @action
  createOrFindTree(id) {
    let tree = this.getById(id);

    if (isEmpty(tree)) {
      tree = new Tree(
        {
          id,
          search: new Search(this),
        },
        this
      );

      this.records = uniqBy([...this.records, tree], 'id');
    }

    return tree;
  }

  @action
  clearActiveTree() {
    this.activeTreeId = null;
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  resetAll() {
    this.rootStore.nodeCollaborators.resetRecords();
    this.rootStore.comments.resetRecords();
    this.rootStore.nodeAssignees.resetRecords();
    this.rootStore.nodes.resetRecords();
    this.rootStore.nodeAttachments.resetRecords();
    this.rootStore.tags.resetRecords();
    this.rootStore.users.resetRecords();
    this.clearActiveTree();
    this.records = [];
    this.masterTreeId = null;
  }

  *destroySelected() {
    this.removing = true;

    yield client.delete(`/api/v1/nodes/${this.id}.json`);
    // map through
    this.softDestroy();
  }

  @flow
  *fetch(page = 1) {
    console.log('fetching trees');
    const p = {
      currentFilter: {
        searchQuery: this.searchQuery,
        groupId: this.activeGroup?.id,
      },
    };
    this.update(p);

    const response = yield client.get('/api/v1/nodes', {
      params: {
        ...page,
        ...this.currentFilter,
      },
    });

    this.records = map(response.data.trees, tree => new Tree(tree, this));

    if (response.data.pagy) {
      this.pagination.update({ ...response.data.pagy });
    }

    const myTrees = find(this.records, { isOwner: true });
    this.refreshStarred();

    this.fetching = false;
  }

  @flow
  *fetchStarred() {
    const response = yield client.get('/api/v1/nodes/starred_trees.json');

    this.starred = map(response.data, tree => new Tree(tree, this));
  }

  // eslint-disable-next-line class-methods-use-this
  @flow
  *searchUserTrees(query, exceptNodeId = '') {
    const response = yield client.get(`/api/v1/nodes/search?query=${query}`);
    if (response.data.success) {
      if (isEmpty(exceptNodeId)) return response.data.results;

      return reject(response.data.results, d => isEqual(d.value, exceptNodeId));
    }
  }

  @flow
  *createTree(withGroupScope = false) {
    const { activeGroupId } = this.rootStore.nodeGroups;
    const params = {};

    if (!isEmpty(activeGroupId)) params.group_id = activeGroupId;
    if (withGroupScope) params.with_scope = true;

    const res = yield client.post('/api/v1/nodes/create_root.json', params);

    if (!res.data.success)
      this.rootStore.users.update({ showEnhance: res.data.message });

    return res;
  }
}

export default TreeStore;
