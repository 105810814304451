import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import RichEditor from '../../rich_text_editor';

const NodeDescription = observer(({ node, summary }) => {
  const [cellDescription, setDescription] = useState(node.description);
  const [timeout, setTimeOut] = useState(null);

  useEffect(() => {
    if (!node.tree.fetchingCollaborators || !node.tree.fetchedCollaborators)
      node.tree.fetchMyCollaborators();
  }, []);

  const { t } = useTranslation();

  if (summary)
    return (
      <ContentEditable
        html={node.description || t('general.no_description')}
        disabled
        className="node-description-ce"
      />
    );

  const handleChange = e => {
    setDescription(e);
    setTimeOut(clearTimeout(timeout));
    setTimeOut(
      setTimeout(() => {
        node.update({ description: e }, true);
      }, 2000)
    );
  };

  return (
    <div>
      <RichEditor
        onChange={handleChange}
        value={cellDescription}
        mentions={node.tree.collaboratorsAsUsers}
        label={t('sidebar.details.description')}
        placeholder={t('sidebar.details.description_placeholder')}
      />
    </div>
  );
});

NodeDescription.defaultProps = {
  summary: false,
};

NodeDescription.propTypes = {
  summary: PropTypes.bool,
};

export default NodeDescription;
