import { action, computed, makeObservable, observable } from 'mobx';
import { camelCase, isEmpty, map } from 'lodash';

class TreeTab {
  /* eslint-disable */
  @observable active = false;
  root = false;
  nodeId;
  @observable name = '';
  /* eslint-enable */

  constructor(tree, masterTree, value = {}) {
    makeObservable(this);

    this.tree = tree;
    this.masterTree = masterTree;

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );
  }

  @computed
  get node() {
    return this.tree.store.rootStore.nodes.getById(this.nodeId);
  }

  @computed
  get prev() {
    return this.masterTree.tabs[this.index - 1];
  }

  @computed
  get index() {
    return this.masterTree.tabs.indexOf(this);
  }

  @computed
  get asJSON() {
    return {
      active: this.active,
      node_id: this.nodeId,
      root: this.root,
      name: this.tabName,
    };
  }

  @computed
  get tabName() {
    return this.tree?.rootNode?.name || this.name;
  }

  @action
  setActive() {
    this.masterTree.store.rootStore.sidebars.clearAll();

    map(this.masterTree.tabs, tab => tab.update({ active: false }));

    this.update({ active: true });
    this.tree.store.setActiveTree(this.nodeId);

    this.masterTree.updateTabs();
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  destroy() {
    if (this.root) return this.masterTree.clearTabs();

    if (this.active && !isEmpty(this.prev)) this.prev.setActive();
    if (this.active) this.masterTree.store.rootStore.sidebars.clearAll();

    this.masterTree.tabs.splice(this.index, 1);

    this.masterTree.updateTabs();
  }
}

export default TreeTab;
