import React from 'react';
import { observer } from 'mobx-react';
import { isEmpty, map } from 'lodash';
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const RestrictedNodeDetails = observer(({ node }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h3>{node.name}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: node.description || t('general.no_description'),
        }}
      ></p>
      <div className="tags">
        <h4>Tags</h4>
        {isEmpty(node.tags) ? (
          <p>No Tags</p>
        ) : (
          map(node.tags, (ct, i) => (
            <Badge className="mr-2" key={i}>
              {ct.label}
            </Badge>
          ))
        )}
      </div>
    </div>
  );
});

export default RestrictedNodeDetails;
