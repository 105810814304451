import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  getNodeBodyClassNames,
  getNodeClassNames,
  revealNodeInDomWithSidebar,
} from '../../../../helpers/node_helpers';

import NodeAdditionalDetails from './NodeAdditionalDetails';
import NodeName from './NodeName';
import NodeThumbnail from './NodeThumbnail';

const Limited = observer(({ node }) => (
  <div className={getNodeClassNames(node, true)}>
    <div className={getNodeBodyClassNames(node)}>
      <NodeName
        node={node}
        onClickCallback={() => {
          if (!node.tree.multiSelect) revealNodeInDomWithSidebar(node, false);
        }}
      />
      <NodeThumbnail node={node} />
      <NodeAdditionalDetails node={node} />
    </div>
  </div>
));

Node.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default Limited;
