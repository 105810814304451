import React from 'react';

import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import AppLayout from '../../layouts/App';
import useStore from '../../data/store';
import InfoBar from './show_components/InfoBar';
import { NO_RECORDS } from '../../assets/images';

const UnAuthorized = () => {
  const { trees, users, app } = useStore();
  const { t } = useTranslation();
  return (
    <AppLayout scrollable loading={false} publiclyVisible={!users.userSignedIn}>
      <Helmet>
        <body
          className={classnames(
            'tree-view-canvas',
            `canvas-${trees.activeTree.canvas}`,
            {
              embedded: app.embedded,
            }
          )}
        />
      </Helmet>
      <InfoBar />
      <div className="no-access">
        <img src={NO_RECORDS} alt="no-access" />
        <h1>{t('trees.un_authorized')}</h1>
      </div>
    </AppLayout>
  );
};

export default observer(UnAuthorized);
