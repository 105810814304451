import { computed, makeObservable } from 'mobx';
import { camelCase, includes, isEmpty, isEqual, map } from 'lodash';

class Product {
  /* eslint-disable */
  id; treeLimit; nodesLimit; interval; name; price; store; type;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);
    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  canBeUpgradedTo(quantity) {
    const userPlan = this.userAccount.product;

    if (this.isFree) return false;
    if (isEqual(userPlan.id, this.id)) {
      if (!this.userAccount.hasSubscription) return false;

      return this.userAccount.subscription.quantity < quantity;
    }

    return isEqual(userPlan.interval, this.interval);
  }

  canBeDowngradedTo(quantity) {
    const userPlan = this.userAccount.product;

    if (this.isFree && userPlan.isPaid) return true;
    if (isEqual(userPlan.id, this.id)) {
      if (!this.userAccount.hasSubscription) return false;

      return this.userAccount.subscription.quantity > quantity;
    }

    return !isEqual(userPlan.interval, this.interval);
  }

  discount = quantity => {
    let discount = 0;

    if (quantity >= 11) {
      discount = 20;
    } else if (quantity >= 6) {
      discount = 10;
    }

    return discount;
  };

  productPrice(quantity) {
    if (this.isFree) return 0;

    return (
      quantity * (this.price - (this.price * this.discount(quantity)) / 100)
    );
  }

  formattedProductPrice(quantity) {
    return (this.productPrice(quantity) / 100).toFixed(0);
  }

  @computed
  get isUserProduct() {
    return isEqual(this.userAccount.productId, this.id);
  }

  @computed
  get userAccount() {
    return this.store.rootStore.users.currentUser.account;
  }

  @computed
  get isFree() {
    return isEqual(this.id, 'free');
  }

  @computed
  get isPaid() {
    return !this.isFree;
  }

  @computed
  get canUpgrade() {
    return includes(['forever', 'monthly'], this.interval);
  }

  @computed
  get canDowngrade() {
    return !isEqual(this.interval, 'forever');
  }
}

export default Product;
