import React, { useEffect } from 'react';

import { Alert, Button } from 'reactstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import useStore from '../../../data/store';
import { formatDate } from '../../../helpers/shared_helpers';

const CurrentPlanInfo = ({ location }) => {
  const { users: store, products: productStore } = useStore();
  const { currentUser } = store;
  const { account } = currentUser;
  const { t } = useTranslation();

  const showPlans = activePlanType => {
    if (isEqual(account.productId, 'free')) {
      productStore.changeSelectedPlanType(activePlanType);
      productStore.togglePlans();
    }
  };

  useEffect(() => {
    if (productStore.fetched && location.state && location.state.goUnlimited) {
      showPlans('unlimited');
    }
  }, [productStore.fetched]);

  const handleUnlimited = v => {
    if (v < 0) return t('labels.unlimited');
    return v;
  };

  if (isEmpty(account.product)) return null;

  return (
    <div className="current-plan-info card-common h-100">
      <h3>{t('account.plan.heading')}</h3>
      <div className="plan-info">
        <div className="plan-details">
          <h4>
            {account.product.name}{' '}
            {!isEmpty(account.subscription) && (
              <span className="text-muted small">
                until {formatDate(account.subscription.expiresAt)}
              </span>
            )}
          </h4>
          <div className="plan-benefits">
            <div className="plan-benefit">
              <span>{t('account.plan.maximum.tree')}</span>
              <span>{handleUnlimited(account.product.treeLimit)}</span>
            </div>
            <div className="plan-benefit">
              <span>{t('account.plan.maximum.nodes')}</span>
              <span>{handleUnlimited(account.product.nodesLimit)}</span>
            </div>
            {currentUser.isAccountAdmin && (
              <>
                <div className="plan-benefit">
                  <span>{t('account.plan.maximum.licenses')}</span>
                  <span>
                    {account.hasSubscription
                      ? account.subscription.quantity
                      : 1}
                  </span>
                </div>
                <div className="plan-benefit">
                  <span>{t('account.plan.maximum.assigned')}</span>
                  <span>
                    {account.hasSubscription ? account.assignedUsers : 1}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        {currentUser.isAccountAdmin ? (
          <div className="plan-actions">
            <Button className="delete-account">
              {t('account.plan.actions.delete_account')}
            </Button>

            {account.product.isFree ? (
              <Button
                size="lg"
                className="btn-success"
                onClick={() => showPlans('unlimited')}
              >
                {t('account.plan.actions.subscribe')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  account.manage();
                }}
              >
                {' '}
                {t('account.plan.actions.manage')}
                <span className="ml-2">
                  <FontAwesomeIcon icon="external-link-alt" />
                </span>
              </Button>
            )}
          </div>
        ) : (
          <div className="plan-actions">
            <Button
              onClick={() =>
                confirmAlert({
                  title: t('account.plan.confirm_alerts.unlink.title'),
                  message: t('account.plan.confirm_alerts.unlink.message'),
                  buttons: [
                    {
                      label: t('alert.buttons.yes'),
                      onClick: () => currentUser.unlinkMe(),
                    },
                    {
                      label: t('alert.buttons.no'),
                    },
                  ],
                })
              }
              className="delete-account"
            >
              {t('account.plan.actions.unlink')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CurrentPlanInfo.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default observer(CurrentPlanInfo);
