import { action, computed, makeObservable, observable } from 'mobx';
import { camelCase, map } from 'lodash';
import jQuery from 'jquery';

import { isColliding } from '../../helpers/shared_helpers';

class Drag {
  /* eslint-disable */
  tree;
  @observable position;
  @observable type;
  @observable nodeId;
  @observable dragging;
  @observable draggingNode;
  @observable canvasDragging;
  /* eslint-enable */

  constructor(tree) {
    makeObservable(this);

    this.tree = tree;
  }

  @computed
  get node() {
    return this.tree.store.rootStore.nodes.getById(this.nodeId);
  }

  @action
  checkScrollIntersection() {
    jQuery('.direction-placeholder').each((index, obj) => {
      const direction = jQuery(obj).attr('direction');

      const isCollidingWithPlaceholder = isColliding(
        `${obj.id}`,
        `cell-${this.draggingNode}`,
        1,
        this.tree.zoom
      );

      if (isCollidingWithPlaceholder) {
        const scrollElementObj = jQuery('.indiana-scroll-container');
        const scrollElement = scrollElementObj[0];
        let { scrollLeft, scrollTop } = scrollElement;

        switch (direction) {
          case 'left':
            scrollLeft -= 20;
            break;

          case 'right':
            scrollLeft += 20;
            break;

          case 'top':
            scrollTop -= 20;
            break;

          case 'bottom':
            scrollTop += 20;
            break;

          default:
        }

        this.update({ canvasDragging: true });

        scrollElement.scrollTo(scrollLeft, scrollTop);

        this.update({ canvasDragging: false });
      }
    });
  }

  @action
  checkIntersection() {
    let params = {
      nodeId: null,
      position: null,
      type: null,
    };

    this.checkScrollIntersection();

    jQuery('.cell-children:not(.parent-dragging) .placeholder').each(
      (index, obj) => {
        if (isColliding(`cell-${this.draggingNode}`, obj.id)) {
          const type = jQuery(obj).data('type');
          const objPosition = jQuery(obj).data('position');
          const objNodeId = jQuery(obj).data('cellid');

          params = {
            nodeId: objNodeId,
            position: objPosition,
            type,
          };
        }
      }
    );

    this.update(params);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }
}

export default Drag;
