import React from 'react';

import Select from 'react-select/async';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import { customStyles } from '../../../../helpers/shared_helpers';

const NodeParentSelector = observer(({ node }) => {
  const { store } = node;

  const handleChange = option => {
    const parentNode = store.getById(option.value);
    const childrenLength = parentNode.children.length;

    node.updatePosition(
      { parent_id: option.value, position: childrenLength + 1 },
      true
    );
  };
  const { t } = useTranslation();
  return (
    <div className="parent-selector">
      <Label>{t('sidebar.change_parent.label')}</Label>
      <Select
        styles={customStyles}
        loadOptions={val => store.searchParents(node.id, val)}
        value={node.parentAsSelectOption}
        onChange={handleChange}
      />
    </div>
  );
});

NodeParentSelector.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default NodeParentSelector;
