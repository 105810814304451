import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Card } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import UserInfo from '../../shared/users/Info';

const UserContactsItem = ({ contact }) => {
  const billingAccount = contact.store.currentUser.account;
  const { t } = useTranslation();

  return (
    <Card
      color="link border-0"
      className={classnames('user-contact', { active: contact.selected })}
    >
      <Button
        color="link"
        className="overlay-link"
        onClick={() => contact.toggleSelect()}
      />
      <UserInfo user={contact} onClick={() => contact.toggleSelect()}>
        {contact.hasActiveSubscription ? (
          <>
            {contact.managedByYou && (
              <Button
                onClick={() =>
                  billingAccount.manageAccountUser(contact.id, 'unlink')
                }
                className="p-0 text-danger position-relative"
                style={{ zIndex: 9 }}
                color="link"
              >
                {t('contacts.actions.unassign_license')}
              </Button>
            )}
          </>
        ) : (
          <Button
            style={{ zIndex: 9 }}
            onClick={() => billingAccount.manageAccountUser(contact.id, 'link')}
            color="link p-0 position-relative"
          >
            {t('contacts.actions.assign_license')}
          </Button>
        )}
      </UserInfo>
    </Card>
  );
};

UserContactsItem.propTypes = {
  contact: PropTypes.instanceOf(Object).isRequired,
};

export default observer(UserContactsItem);
