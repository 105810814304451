import { action, computed, flow, makeAutoObservable, observable } from 'mobx';

import { camelCase, find, map, groupBy, isEqual } from 'lodash';

import Product from '../entities/product';
import client from '../axiosClient';

class ProductStore {
  /* eslint-disable */
  @observable records = [];
  @observable showModal = false;
  @observable selectedPlanType = 'free';
  @observable selectedPlanInterval = 'month';
  @observable fetched = false;
  /* eslint-enable */

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getById(id) {
    return find(this.records, { id });
  }

  @computed
  get selectedInterval() {
    if (isEqual(this.selectedPlanType, 'free')) return 'forever';

    return this.selectedPlanInterval;
  }

  @computed
  get groupByType() {
    return groupBy(this.records, 'type');
  }

  @action
  resetRecords() {
    this.update({ records: [] });
  }

  @action
  setRecords(records) {
    this.update({ records: [...map(records, r => new Product(r, this))] });
  }

  @action
  togglePlans() {
    this.update({ showModal: !this.showModal });
  }

  @action
  changeSelectedPlanType(planType) {
    this.update({ selectedPlanType: planType });
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @flow
  *fetchProducts() {
    const response = yield client.get(`/api/v1/billing/products.json`);
    if (response.data.success) {
      this.setRecords(response.data.results);
      this.update({ fetched: true });
    }
  }
}

export default ProductStore;
