import React, { useEffect } from 'react';

import { Button, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import { map, startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import CurrentPlanInfo from './components/CurrentPlanInfo';
import Plans from './components/plans';
import useStore from '../../data/store';

const UserAccount = ({ location }) => {
  const { t } = useTranslation();
  const { users: store } = useStore();
  const user = store.currentUser;

  useEffect(() => {
    user.fetchMyContacts();
  }, []);

  return (
    <div className="user-account">
      <Row>
        <Col lg={7}>
          <CurrentPlanInfo location={location} />
        </Col>
        {user.hasActiveSubscription && (
          <Col lg={5}>
            <div className="h-100 current-plan-info card-common">
              <h3>{t('account.user_management.heading')}</h3>
              <h5 className="mb-3">Users having access to this plan</h5>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {startCase(user.fullName)}{' '}
                      <span className="small text-muted">(You)</span>
                    </td>
                  </tr>
                  {map(user.managedByMe, u => (
                    <tr key={u.id}>
                      <td>{startCase(u.fullName)}</td>
                      <td className="text-right">
                        <Button
                          onClick={() =>
                            confirmAlert({
                              title: 'Revoke License',
                              message: `Do you want to revoke license of ${u.fullName}`,
                              buttons: [
                                {
                                  label: 'Revoke',
                                  onClick: () =>
                                    user.account.manageAccountUser(
                                      u.id,
                                      'unlink'
                                    ),
                                },
                                {
                                  label: 'Cancel',
                                },
                              ],
                            })
                          }
                          size="sm"
                          color="danger"
                        >
                          Revoke License
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
              {user.hasAvailableLicence && (
                <div className="text-center">
                  <Link
                    className="text-success small"
                    to={{ pathname: '/settings', state: { tab: 'contacts' } }}
                  >
                    Assign Available License
                  </Link>
                </div>
              )}
            </div>
          </Col>
        )}
      </Row>
      <Plans />
    </div>
  );
};

UserAccount.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default observer(UserAccount);
