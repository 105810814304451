import React from 'react';

import { PropTypes } from 'prop-types';
import { isEmpty, isNull, map } from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';
import Tree from './Tree';

const TreeList = observer(({ store, tab }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        {map(store.recordsToShow, (tree, i) => (
          <Col
            lg={6}
            xl={3}
            key={i}
            className={classnames({
              'featured-tree': tree.showFeaturedPreview,
            })}
          >
            <Tree tree={tree} />
          </Col>
        ))}
      </Row>
      {isEmpty(store.recordsToShow) && (
        <div className="empty-trees">
          <h2>{t('trees.no_trees')}</h2>
        </div>
      )}
    </>
  );
});

TreeList.propTypes = {
  store: PropTypes.instanceOf(Object).isRequired,
  tab: PropTypes.string.isRequired,
};

export default TreeList;
