import React, { useState } from 'react';

import { Button } from 'reactstrap';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../../data/store';
import board from '../../../data/entities/board';
import { ResponsiveToggle } from '../../trees/index_components/FilterWidget';

const BoardActions = observer(() => {
  const { t } = useTranslation();
  const { boards: store, sidebars } = useStore();
  const [show, setShow] = useState(false);

  const onAddClick = () => {
    if (sidebars.hasType('board-add')) return;

    store.initializeForm();

    sidebars.addSidebar({
      type: 'board-add',
      viewType: 'board',
    });
  };

  const onEditClick = () => {
    if (sidebars.hasType('board-edit')) return;

    sidebars.addSidebar({
      type: 'board-edit',
      viewType: 'board',
    });
  };

  return (
    <ResponsiveToggle
      btnClassName="btn-sm h-auto ml-3"
      className="ml-auto"
      size="md"
      icon="bars"
      direction="right"
    >
      <div className="ml-auto add-new-board">
        <Button
          onClick={onAddClick}
          className="tree-settings"
          size="sm"
          color="light"
        >
          <FontAwesomeIcon icon="plus" /> {t('boards.actions.add')}
        </Button>
        {store.activeBoard && store.activeBoard.canChange && (
          <Button
            onClick={onEditClick}
            className="ml-2 tree-settings"
            size="sm"
            color="light"
          >
            <FontAwesomeIcon icon="bars" />
            <span className="pl-2">{t('boards.infobar.settings.title')}</span>
          </Button>
        )}
      </div>
    </ResponsiveToggle>
  );
});

export default BoardActions;
