import React from 'react';

import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import NodeComments from '../../nodes/node_comments';
import ContentContainer from './ContentContainer';

const Comments = observer(({ sidebar }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer title={t('sidebar.tabs.comments.label')}>
      <NodeComments node={sidebar.node} />
    </ContentContainer>
  );
});

export default Comments;
