import React from 'react';

import { observer } from 'mobx-react';
import { Button, Label } from 'reactstrap';
import Select from 'react-select/async';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
import { customStyles } from '../../../../helpers/shared_helpers';
import useStore from '../../../../data/store';

const LinkedNodeSelector = ({ node }) => {
  const { trees: treeStore } = useStore();

  const { t } = useTranslation();

  const onChange = val => {
    node.update({ linkedNode: val });
    node.update(
      { linkedNodeId: val.value, externalised: true, linking: true },
      true
    );
  };

  return (
    <div className="parent-selector">
      <Label>{t('sidebar.linked_node.label')}</Label>
      <Select
        styles={customStyles}
        loadOptions={val => treeStore.searchUserTrees(val, node.id)}
        value={node.linkedNode}
        onChange={onChange}
        placeholder={t('sidebar.linked_node.placeholder')}
      />
      {!isEmpty(node.linkedNodeId) && (
        <Button
          onClick={() => {
            node.update({ linkedNode: null });
            node.update({ externalised: false, linking: true }, true);
          }}
          className="mt-1 p-0"
          color="link text-success"
          style={{ fontSize: 12 }}
        >
          <strong className="">Unlink Linked Tree</strong>
        </Button>
      )}
    </div>
  );
};

LinkedNodeSelector.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default observer(LinkedNodeSelector);
