import React from 'react';

import { NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { GARDEN_LOGO } from '../../assets/images';
import useStore from '../../data/store';

const BrandNavbar = () => {
  const { app } = useStore();
  return (
    <NavbarBrand to={app.embedded ? null : '/'} tag={Link}>
      <img src={GARDEN_LOGO} alt="garden logo" />
    </NavbarBrand>
  );
};

export default BrandNavbar;
