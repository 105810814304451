import React from 'react';
import PropTypes from 'prop-types';
import useStore from '../../../../data/store';

const EmbedTutorial = ({ node }) => {
  const { users } = useStore();

  return (
    <div>
      <pre>
        {`<script src="https://app.amalati.com/embed.js"></script>`}
        {`\n\n`}
        {`<script>`}
        {`\n`}
        &emsp;&emsp;{`window.node = new AmalatiEmbed({`}
        {`\n`}
        &emsp;&emsp;&emsp;&emsp;nodeId: '{node.id}',
        {`\n`}
        &emsp;&emsp;&emsp;&emsp;apiKey: '{users.currentUser.apiKey}',
        {`\n`}
        &emsp;&emsp;{`});`}
        {`\n\n`}
        &emsp;&emsp;window.node.init();
        {`\n\n`}
        &emsp;&emsp;// To Start Tutorial
        {`\n`}
        &emsp;&emsp;{`window.node.start(function () {`}
        {`\n`}
        &emsp;&emsp;&emsp;&emsp;// Callback on complete
        {`\n`}
        &emsp;&emsp;&emsp;&emsp;// window.node.destroy();
        {`\n`}
        &emsp;&emsp;});
        {`\n\n`}
        &emsp;&emsp;// To Stop Tutorial
        {`\n`}
        &emsp;&emsp;{`window.node.stop();`}
        {`\n`}
        {`</script>`}
      </pre>
    </div>
  );
};

EmbedTutorial.propTypes = {
  node: PropTypes.instanceOf(Object).isRequired,
};

export default EmbedTutorial;
