import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { isEqual, map } from 'lodash';
import classnames from 'classnames';

import { nodePlaceholders } from '../../../../helpers/node_helpers';

const NodePlaceholders = observer(({ node }) => {
  const {
    drag: { type, nodeId, dragging, canvasDragging },
  } = node.tree;

  useEffect(() => {}, [dragging, canvasDragging]);

  if (!node.tree.isActive || !node.showPlaceholders) return null;

  return map(nodePlaceholders, (n, i) => (
    <div
      id={`${node.id}-${n.type}`}
      key={i}
      className={classnames({
        [`placeholder ${n.class}`]: true,
        active: isEqual(type, n.type) && isEqual(nodeId, node.id),
      })}
      data-type={n.type}
      data-position={parseInt(node.position) + n.position}
      data-cellid={node.id}
    />
  ));
});

export default NodePlaceholders;
