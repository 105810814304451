import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

const Loader = ({ text, progress, fixed, children, className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames('zoom-alert', {
        'position-relative': !fixed,
        [className]: !isEmpty(className),
      })}
    >
      {progress && <FontAwesomeIcon icon="spinner" className="fa-spin mr-3" />}
      {text || t('general.loading_message')}
      {children}
    </div>
  );
};

Loader.defaultProps = {
  text: '',
  progress: true,
  fixed: true,
  className: '',
};

Loader.propTypes = {
  text: PropTypes.string,
  progress: PropTypes.bool,
  fixed: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Loader;
