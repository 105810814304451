import { makeObservable, action, computed } from 'mobx';
import { camelCase, map } from 'lodash';

class Tag {
  /* eslint-disable */
  id;
  nodeId;
  name;
  store;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  @computed
  get nodes() {
    const tags = this.store.getMultipleById(this.id);
    const nodeIds = map(tags, t => t.nodeId);

    return this.store.rootStore.nodes.getByIds(nodeIds);
  }

  @action
  destroy() {
    this.store.records.splice(this.store.records.indexOf(this), 1);
  }
}

export default Tag;
