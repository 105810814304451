import React from 'react';

import { Button } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isEqual } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';

import useStore from '../../../data/store';

const AddTree = () => {
  const { t } = useTranslation();
  const { trees } = useStore();
  const history = useHistory();

  const group = trees.rootStore.nodeGroups.activeGroup;

  const handleAdd = (withGroupScope = false) => {
    trees.createTree(withGroupScope).then(res => {
      if (!res.data.success) return false;

      history.push(`/trees/${res.data.node_id}`);
    });
  };

  const handleAddWithGroup = () => {
    confirmAlert({
      title: t('trees.confirm_alerts.add_tree.title'),
      message: t(`trees.confirm_alerts.add_tree.message_${group.scope}`),
      buttons: [
        {
          label: t('alert.buttons.yes'),
          onClick: () => handleAdd(true),
        },
        {
          label: t('alert.buttons.no'),
          onClick: () => handleAdd(false),
        },
      ],
    });
  };

  const handleClick = () => {
    if (!isEmpty(group) && group.isOwner) {
      if (isEqual(group.scope, 'user')) return handleAdd(true);
      if (!isEqual(group.scope, 'public')) return handleAddWithGroup();
    }

    handleAdd(false);
  };

  return (
    <div className="add-tree text-right">
      <Button color="success" onClick={handleClick}>
        <FontAwesomeIcon icon="plus-circle" className="mr-2" />
        <span className="btn-text">{t('trees.buttons.add_new_tree')}</span>
      </Button>
    </div>
  );
};

export default AddTree;
