import { makeObservable, action, observable, flow, computed } from 'mobx';
import { filter, find, includes, isEmpty, isEqual, map, uniqBy } from 'lodash';

import { remove } from 'lodash/array';
import client from '../axiosClient';
import Node from '../entities/node';

class NodeStore {
  /* eslint-disable */
  @observable records = [];
  @observable iframeActive = false;
  @observable copiedNodeIds = [];
  expandedNodes = [];
  @observable filters = {};
  /* eslint-enable */

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  getById(id) {
    return find(this.records, { id });
  }

  getByIds(ids) {
    return filter(this.records, r => includes(ids, r.id));
  }

  exists(id) {
    return !isEmpty(this.getById(id));
  }

  @computed
  get hasCopiedNodes() {
    return !isEmpty(this.copiedNodeIds);
  }

  @computed
  get calendarEvents() {
    return map(this.records, r => r.eventJson);
  }

  @action
  updateFilters(params) {
    this.filters = { ...this.filters, ...params };
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  copyNodes(ids) {
    this.copiedNodeIds = ids || [];
  }

  @action
  setRecords(records) {
    this.records = uniqBy(
      [...this.records, ...map(records, r => new Node(r, this))],
      'id'
    );
  }

  @action
  addNode(params) {
    if (this.exists(params.id)) return;

    const n = new Node(params, this);
    this.records.push(n);

    return n;
  }

  @action
  toggleNodeExpand(nodeId, expanded) {
    if (expanded) {
      this.expandedNodes.push(nodeId);
    } else {
      remove(this.expandedNodes, n => n === nodeId);
    }
  }

  @action
  clearExpandedNodes() {
    this.expandedNodes = [];
  }

  @flow
  *updateNodes(nodeIds, params) {
    const response = yield client.put('/api/v1/nodes/bulk_update.json', {
      ...params,
      node_ids: nodeIds,
    });
    if (response.data.success) {
      map(nodeIds, n => {
        const node = find(
          isEqual(params.node_type, 'tree')
            ? this.rootStore.trees.records
            : this.records,
          { id: n }
        );

        if (isEqual(params.action_type, 'remove_all')) {
          if (!isEmpty(node)) {
            const sidebars = this.rootStore.sidebars.findByNodeId(node.id);
            if (!isEmpty(sidebars))
              map(sidebars, sidebar => sidebar.destroy(true));

            if (
              !isEmpty(this.rootStore.playlist.active) &&
              isEqual(this.rootStore.playlist.active.id, node.id)
            )
              this.rootStore.playlist.reset();
            if (isEqual(params.node_type, 'node')) {
              node.deleteAndSetOrphans();
            } else {
              node.softDestroy();
            }
          }
          return response;
        }

        // if (isEqual(params.node_type, 'node'))
        node.update(params);
      });

      return response;
    }
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *searchParents(id, val) {
    const response = yield client.get(
      `/api/v1/nodes/${id}/search_parent.json?query=${val}`
    );

    return response.data?.results;
  }

  @flow
  // eslint-disable-next-line class-methods-use-this
  *markAsViewed(nodeId) {
    const response = yield client.get(
      `/api/v1/nodes/${nodeId}/mark_as_viewed.json`
    );
    return response.data.success;
  }

  @flow
  *fetchCalendarRecords() {
    const response = yield client.get(`/api/v1/nodes/calendar.json`, {
      params: this.filters,
    });

    if (response.data.nodes) {
      map(response.data.nodes, n => this.addNode(n));
    }
  }
}

export default NodeStore;
