import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useStore from '../../../data/store';

const BookingCalender = () => {
  const { app } = useStore();
  const { t } = useTranslation();

  const handleToggle = () => app.update({ showBookingCalendar: false });

  return (
    <Modal size="lg" toggle={handleToggle} isOpen={app.showBookingCalendar}>
      <ModalBody>
        {app.showBookingCalendar && (
          <iframe
            title="Book a call"
            src="https://on.sprintful.com/amalati"
            width="100%"
            height="660px"
            frameBorder="0"
          />
        )}
      </ModalBody>
      <ModalFooter className="text-center">
        <Button color="danger" onClick={handleToggle}>
          {t('sidebar.tabs.close.label')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default observer(BookingCalender);
