import React, { useEffect } from 'react';

import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Loader from '../../../layouts/shared/Loader';
import Playlist from '../../modules/nodes/playlist';
import useStore from '../../../data/store';

const PlaylistBody = ({ location, embed }) => {
  const { playlist, trees } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      (location?.state && location?.state?.playlist) ||
      (trees.masterTree.isTutorial && !trees.masterTree.canChange)
    ) {
      playlist.update({ loading: true });
    }
  }, []);

  useEffect(() => {
    if (trees.masterTree.fetchedNodes) {
      if (playlist.loading && !isEmpty(playlist.videos)) {
        playlist.start(trees.masterTree.rootNode);
        playlist.update({ loading: false });
      }
    }
  }, [trees.masterTree.fetchedNodes]);

  return (
    <>
      {playlist.loading ? (
        <div
          style={{ height: '60vh' }}
          className={classnames('mb-4', { 'embed-playlist-loader': embed })}
        >
          <Loader
            className="flex-column justify-content-center"
            text={t(
              trees.masterTree.fetchedNodes
                ? 'playlist.empty'
                : 'playlist.loading'
            )}
            fixed={false}
            progress={!trees.masterTree.fetchedNodes}
          >
            {!embed && (
              <Button
                onClick={() => {
                  playlist.reset();
                  playlist.update({ loading: false });
                }}
                color="danger mt-2"
              >
                {t('labels.close')}
              </Button>
            )}
          </Loader>
        </div>
      ) : (
        <Playlist embed={embed} />
      )}
    </>
  );
};

PlaylistBody.defaultProps = {
  embed: false,
};

PlaylistBody.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  embed: PropTypes.bool,
};

export default observer(PlaylistBody);
