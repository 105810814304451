import Toastify from 'toastify-js';

const notify = (type = 'info', text = 'Alert') =>
  Toastify({
    text,
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    className: `bg-${type}`,
    backgroundColor: '#000',
    stopOnFocus: true, // Prevents dismissing of toast on hover
  }).showToast();

export default notify;
