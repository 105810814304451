import classnames from 'classnames';
import { isEmpty, isEqual, map } from 'lodash';

export const scrollContainerStyle = tree => {
  const heightOfScrollCanvas = window.innerHeight;
  const widthOfScrollCanvas = window.innerWidth;
  const canvasZoom = tree?.zoom || 1;
  const zoomHeight = heightOfScrollCanvas / canvasZoom;
  const zoomWidth = widthOfScrollCanvas / canvasZoom;
  let minusHeight = 73 / canvasZoom;

  if (
    isEqual(tree?.canvas, 'full') ||
    isEqual(tree?.controlBarView, 'minimized')
  )
    minusHeight = 0;

  return {
    zoom: canvasZoom,
    height: zoomHeight - minusHeight,
    width: zoomWidth,
    '-ms-zoom': canvasZoom,
    '-webkit-zoom': canvasZoom,
    '-moz-transform': `scale(${canvasZoom})`,
    transformOrigin: '0 0 0',
  };
};

export const treeContainerStyle = tree =>
  classnames({
    tree: true,
    'tree-dragging': tree.drag.dragging,
    [`${tree.direction}-view`]: true,
  });

export const treeViewContainerStyle = tree => ({
  position: 'relative',
  top: 79 / tree.zoom,
});

export const scopeObjects = (t, context) => {
  const allScopes = {};
  map(['user', 'instance', 'world'], x => {
    const key = isEqual(x, 'user') ? `${context}_${x}` : x;
    allScopes[x] = {
      label: t(`scopes.${key}.label`),
      value: x,
      title: t(`scopes.${key}.title`),
      description: t(`scopes.${key}.description`),
    };
  });

  return allScopes;
};

export const textForScope = (scope, t, context = 'tree') => {
  const so = scopeObjects(t, context)[scope];
  if (!isEmpty(so)) return so;
  return {};
};

export const scopes = (userIsInstanceAdmin, t, context = 'tree') => {
  const rtn = [];
  const tScopes = scopeObjects(t, context);

  rtn.push(tScopes.user);
  rtn.push(tScopes.world);
  if (userIsInstanceAdmin) rtn.push(tScopes.instance);
  return rtn;
};

export const treeTypes = t => [
  { label: t('tree_types.general'), value: 'general' },
  { label: t('tree_types.tutorial'), value: 'tutorial' },
];
