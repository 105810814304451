import React, { useEffect } from 'react';

import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { observer } from 'mobx-react';
import { isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import useStore from '../../../data/store';
import Plans from '../../settings/components/plans/Plans';

const EnhancedUpgrade = () => {
  const { users: store, products } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    products.changeSelectedPlanType('unlimited');
  }, []);

  return (
    <Modal
      size="lg"
      isOpen={!isEmpty(store.showEnhance)}
      toggle={() => store.update({ showEnhance: '' })}
    >
      <ModalHeader toggle={() => store.update({ showEnhance: '' })}>
        {t('upgrade_required.heading')}
      </ModalHeader>
      <ModalBody>
        <Alert className="mb-2 text-center">
          <span
            dangerouslySetInnerHTML={{
              __html: t(`upgrade_required.${store.showEnhance}`),
            }}
          />
        </Alert>
        {!isEqual(store.showEnhance, 'free_other_user_limit') && <Plans />}
      </ModalBody>
    </Modal>
  );
};

export default observer(EnhancedUpgrade);
