import React from 'react';

import { observer } from 'mobx-react';
import ScrollContainer from 'react-indiana-drag-scroll';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import {
  scrollContainerStyle,
  treeContainerStyle,
  treeViewContainerStyle,
} from '../../../helpers/tree_helpers';
import Node from './node';
import ControlBar from './control_bar';
import { imageBackgroundStyle } from '../../../helpers/unsplash_helper';
import UnAuthorized from '../unAuthorized';
import HandleFetching from './HandleFetching';

const TreeCanvas = ({ windowWidth, tab }) => {
  if (tab.tree.unAuthorized) return <UnAuthorized />;

  return (
    <div
      className="tree-container w-100"
      style={
        tab.tree.isActive
          ? {}
          : { opacity: 0, visibility: 'hidden', position: 'absolute' }
      }
    >
      <HandleFetching tree={tab.tree} />
      <div
        style={imageBackgroundStyle(tab.tree?.rootNode)}
        className="overlay-bg"
      />
      <ScrollContainer
        onStartScroll={() => tab.tree.drag.update({ canvasDragging: true })}
        onEndScroll={() => tab.tree.drag.update({ canvasDragging: false })}
        style={scrollContainerStyle(tab.tree)}
        vertical={!tab.tree.drag.dragging}
        horizontal={!tab.tree.drag.dragging}
      >
        <div
          className={classnames('tree-view', {
            [`${tab.tree.direction}-tree-direction`]: true,
          })}
          style={treeViewContainerStyle(tab.tree)}
        >
          <div className={treeContainerStyle(tab.tree)}>
            <div className="root">
              {tab.tree?.rootNode ? (
                <Node node={tab.tree.rootNode} />
              ) : (
                'Loading tree'
              )}
            </div>
          </div>
        </div>
      </ScrollContainer>
      {tab.tree?.rootNode && <ControlBar tree={tab.tree} />}
    </div>
  );
};

TreeCanvas.propTypes = {
  tab: PropTypes.instanceOf(Object).isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default observer(TreeCanvas);
