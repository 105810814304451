import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ContentEditable from 'react-contenteditable';
import { isEmpty, isEqual, isFunction } from 'lodash';

import { useTranslation } from 'react-i18next';
import { sanitizedName } from '../../../../helpers/node_helpers';

const NodeName = observer(({ node, onClickCallback }) => {
  const [name, setName] = useState(node.name);
  const { t } = useTranslation();

  useEffect(() => {
    setName(node.name);
  }, [node.name]);

  const isDisabled = () => {
    if (node.new) return false;
    if (!node.canChange || node.fetching) return true;

    return !node.focused;
  };

  const onKeyPress = e => {
    if (
      !node.isTag &&
      (node.hasTags || node.hasChildren || node.isExternal) &&
      e.key === '#'
    ) {
      e.preventDefault();
      node.store.rootStore.notification('danger', t('nodes.name.error'));
    }
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleBlur = e => {
    const nName = sanitizedName(e.target.innerHTML);

    if (isEqual(nName, name)) return;

    node.update({ name: nName, temporaryName: '' }, true);
    node.toggleSelected();
    node.blur();
  };

  return (
    <>
      {node.isOrphanContainerNode ? (
        <div className="cell-edit node-name">{node.name}</div>
      ) : (
        <ContentEditable
          className="cell-edit node-name"
          html={name}
          spellCheck={false}
          disabled={isDisabled()}
          onKeyDown={onKeyPress}
          onDoubleClick={() => {
            node.toggleSelected(true, false);
            if (isFunction(onClickCallback)) onClickCallback();
          }}
          onClick={() => {
            node.toggleSelected(false, true);
            if (isFunction(onClickCallback)) onClickCallback();
          }}
          onChange={e => {
            setName(e.target.value);
            node.update({ temporaryName: e.target.value });
          }}
          onBlur={handleBlur}
        />
      )}
    </>
  );
});

NodeName.defaultProps = {
  onClickCallback: () => {},
};

NodeName.propTypes = {
  node: PropTypes.instanceOf(Object),
  onClickCallback: PropTypes.func,
};

export default NodeName;
