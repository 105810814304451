import React from 'react';

import { observer } from 'mobx-react';
import { Button, Label } from 'reactstrap';
import Select from 'react-select/async/dist/react-select.esm';
import makeAnimated from 'react-select/animated';

import { map, isEqual, includes } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  customStyles,
  handleRecordChange,
  recordsAsSelectOptions,
} from '../../../../helpers/shared_helpers';

import useStore from '../../../../data/store';

const animatedComponents = makeAnimated();

const Form = observer(({ viewType, nodes, assignees }) => {
  const { nodeAssignees: store, sidebars, users: userStore } = useStore();

  const { rootId } = nodes[0];
  const options = recordsAsSelectOptions(assignees);

  const handleChange = e => {
    handleRecordChange(nodes, e, options, store, true);
  };

  const handleInvite = () =>
    sidebars.addSidebar({
      type: 'tree',
      nodeIds: [rootId],
      viewType: isEqual(viewType, 'board') ? 'board-share' : 'tree',
      activeTab: 'share',
    });

  const assignedToMe = !includes(
    map(nodes, n => n.hasAssignedToMe),
    false
  );
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-3">
        <Label className="d-flex justify-content-between">
          {t('sidebar.assign.title')}
          {!assignedToMe && (
            <Button
              onClick={() =>
                handleChange([...options, { value: userStore.currentUser.id }])
              }
              className="mb-0 ml-auto small"
              color="link"
              style={{ fontSize: 12 }}
            >
              {t('sidebar.assign.to_me')}
            </Button>
          )}
          <Button
            onClick={handleInvite}
            color="link m-0 ml-2"
            style={{ fontSize: 12 }}
          >
            {t('sidebar.assign.invite')}
          </Button>
        </Label>
        <Select
          isMulti
          components={animatedComponents}
          value={options}
          onChange={handleChange}
          loadOptions={val => store.searchCollaborators(rootId, val)}
          styles={customStyles}
        />
      </div>
    </div>
  );
});

export default Form;
