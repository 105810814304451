import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Button } from 'reactstrap';

const NodeInfo = observer(({ node }) => {
  const { t } = useTranslation();

  const infoToShow = [];
  if (!isEmpty(node.description)) infoToShow.push('info');
  if (!isEmpty(node.attachments)) infoToShow.push('paperclip');
  if (!isEmpty(node.comments)) infoToShow.push('comment-alt');

  if (isEmpty(infoToShow)) return null;

  const info = infoToShow.map(i => (
    <Button disabled>
      <FontAwesomeIcon icon={i} data-toggle="tooltip" />
    </Button>
  ));

  return <>{info}</>;
});

NodeInfo.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default NodeInfo;
