import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import TreeScopeSelector from '../../tree_scope_selector';
import NodeAssign from '../../nodes/node_assign';
import NodeStatus from '../../nodes/node_status';
import NodeDueDate from '../../nodes/node_due_date';
import NodeWorkEstimate from '../../nodes/node_work_estimate';
import ContentContainer from './ContentContainer';

const NodeWorkManagement = observer(({ sidebar }) => {
  const sNode = sidebar.node;
  const sNodes = sidebar.nodes;
  const { t } = useTranslation();

  if (!sNode.canChange) return <p>{t('sidebar.no_access')}.</p>;

  return (
    <ContentContainer title={t('sidebar.tabs.work.label')}>
      <div className="node-details">
        {sNode.root ? (
          <TreeScopeSelector node={sNode} treeName={sidebar.treeName} />
        ) : (
          <>
            <NodeAssign nodes={sNodes} viewType={sidebar.viewType} />
            <NodeStatus nodes={sNodes} />
            <NodeWorkEstimate node={sNode} />
            <NodeDueDate nodes={sNodes} />
          </>
        )}
      </div>
    </ContentContainer>
  );
});

export default NodeWorkManagement;
