import { action, computed, flow, makeAutoObservable, observable } from 'mobx';
import { camelCase, isEmpty, map, unset, values } from 'lodash';
import client from '../axiosClient';
import { getUrlParam } from '../../helpers/shared_helpers';

class AppStore {
  /* eslint-disable */
  unsplashCollectionId;
  isBeta = false;
  amalatiEmbedApiKey = 'AFcBr7tz486XASWlSRRpJg';
  @observable _headers = {};

  @observable embedded = false;
  @observable showPromoPopup = false;
  @observable showBookingCalendar = false;
  @observable promoPopupShown = false;
  @observable theme = 'default';
  @observable contactId = null;
  @observable saveUser = false;
  @observable guestAlert = false;
  @observable settingUpGuestUser = false;
  @observable showZoomAlert = false;
  @observable zoomPercent = '0';
  @observable showGallerySidebar = false;
  @observable gettingStartedTreeId = '';
  @observable onboardingNodeId = ''
  /* eslint-enable */

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    this.update({ embedded: !isEmpty(getUrlParam('embed')) });
    this.update({ contactId: getUrlParam('cid') || null });
    this.fetchConfigFromServer();
  }

  @action
  setHeader(key, headerItem) {
    this._headers[key] = headerItem;
  }

  @action
  unsetHeader(key) {
    this._headers[key] = null;
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @computed
  get headers() {

    return map(values(this._headers), h => {
      if (h) return h;
    });
  }

  @computed
  get shouldShowPromoPopup() {
    return !isEmpty(this.contactId) && !this.promoPopupShown;
  }

  @computed
  // eslint-disable-next-line class-methods-use-this
  get isGuestUrl() {
    return !isEmpty(getUrlParam('guest'));
  }

  @flow
  *fetchConfigFromServer() {
    const response = yield client.get('/api/v1/app/configuration.json');
    if (response.data.success) {
      this.update(response.data.config);
    }
  }

  @flow
  *unsplashAccessKey() {
    const response = yield client.get('/api/v1/app/proxy_unsplash.json');
    if (response.data.success) {
      this.update(response.data.config);
    }
  }
}

export default AppStore;
