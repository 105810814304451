import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useStore from '../../../../data/store';
import ContentContainer from '../tab_content/ContentContainer';
import NotificationList from '../../notification_list';

const Notifications = () => {
  const { notifications: store } = useStore();
  const { t } = useTranslation();

  // on unMount
  useEffect(() => setTimeout(() => store.viewedAll(), 5000), []);

  return (
    <ContentContainer title={t('notifications.heading')}>
      <div className="notification-list">
        {store.hasUnread && (
          <>
            <NotificationList
              records={store.unread}
              title={t('notifications.unread')}
              showTitle
            />
            <hr />
          </>
        )}
        <NotificationList
          records={store.read}
          title={t('notifications.all')}
          showTitle={store.hasUnread}
        />
      </div>
    </ContentContainer>
  );
};

export default observer(Notifications);
