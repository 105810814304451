import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { Button } from 'reactstrap';
import classnames from 'classnames';

const SettingsNavItem = ({
  title,
  icon,
  tabId,
  active,
  onClickHandler,
  tag,
  to,
}) => {
  const clickMe = () => {
    onClickHandler(tabId);
  };
  return (
    <Button
      to={to}
      tag={tag}
      onClick={clickMe}
      className={classnames({
        active,
        'text-success': !active,
        'mb-3': true,
        'text-left': true,
      })}
      color={active ? 'success' : 'link'}
    >
      {icon && <FontAwesomeIcon icon={icon} size="sm" className="mr-2" />}
      <span className="user-settings-nav-item">{title}</span>
    </Button>
  );
};

SettingsNavItem.defaultProps = {
  onClickHandler: () => {},
  tag: Button,
  to: {},
};

SettingsNavItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  tabId: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func,
  tag: PropTypes.instanceOf(Object),
  to: PropTypes.instanceOf(Object),
};

export default SettingsNavItem;
