import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Attachments from '../../nodes/node_attachments';
import ContentContainer from './ContentContainer';

const NodeAttachments = ({ sidebar }) => {
  const { t } = useTranslation();
  return (
    <ContentContainer title={t('sidebar.tabs.attachments.label')}>
      <div>
        <Attachments node={sidebar.node} />
      </div>
    </ContentContainer>
  );
};

NodeAttachments.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default observer(NodeAttachments);
