import React from 'react';

import { map } from 'lodash';

import { Badge, Button } from 'reactstrap';
import { observer } from 'mobx-react';
import useStore from '../../../../data/store';

import Form from './Form';
import { getCommonValues } from '../../../../helpers/shared_helpers';

const Tags = observer(({ nodes, viewType }) => {
  const { tags: store } = useStore();
  const tags = store.getByIds(getCommonValues(nodes, 'tags'));

  return (
    <div className="node-tags">
      <Form viewType={viewType} nodes={nodes} tags={tags} />
    </div>
  );
});

export default Tags;
