import classnames from 'classnames';
import { includes, isEmpty, isEqual } from 'lodash';

import { getValueFromRecordsIfEqual } from './shared_helpers';

export const handleNodeToBoard = (
  node,
  creatingBoard,
  setCreatingBoard,
  history
) => {
  if (creatingBoard) return;
  setCreatingBoard(true);
  node.createBoard().then(res => {
    if (res) {
      setTimeout(() => {
        history.push('/boards');
      }, 1000);
    } else {
      setCreatingBoard(false);
    }
  });
};

export const getNodeBodyClassNames = node => {
  const { computedStatus } = node;
  return classnames({
    'cell-body': true,
    [`status-${computedStatus}`]: true,
    'status-orphan': node.isOrphanContainerNode,
    'has-thumbnail': node.hasThumbnail || node.showPlayButton,
  });
};

export const getNodeClassNames = (node, noChildren = false) =>
  classnames(`node_publication_status_${node.computedPublicationStatus}`, {
    cell: true,
    'cell-root': node.root,
    orphan: node.isOrphanContainerNode,
    'empty-cell': noChildren || !node.hasChildren || node.isExternal,
    collapsed: !node.expanded || node.isExternal,
    'cell-active': node.selected,
    'has-assignees': !isEmpty(node.assignees) || !isEmpty(node.dueDate),
  });

export const canDeleteNode = node =>
  !node.root && node.canChange && node.canDelete;

export const canDragNode = node =>
  !node.root && node.canChange && node.canAccess && !node.isTag;

export const canStarNode = node => node.canChange && node.canAccess;

export const showEllipsisButton = node => {
  const {
    drag: { dragging },
    multiSelect,
  } = node.tree;

  return node.canChange && !dragging && !multiSelect && node.fetched;
};

export const selectAllText = ref => {
  if (isEmpty(ref)) return;
  const el = ref;
  if (window.getSelection && document.createRange) {
    // Browser compatibility
    const sel = window.getSelection();
    if (isEqual(sel.toString(), '')) {
      // no text selection
      window.setTimeout(function() {
        const range = document.createRange(); // range object
        range.selectNodeContents(el); // sets Range
        sel.removeAllRanges(); // remove all ranges from selection
        sel.addRange(range); // add Range to a Selection.
      }, 1);
    }
  } else if (document.selection) {
    // older ie
    const sel = document.selection.createRange();
    if (isEqual(sel.text, '')) {
      // no text selection
      const range = document.body.createTextRange(); // Creates TextRange object
      range.moveToElementText(el); // sets Range
      range.select(); // make selection.
    }
  }
};

export const deselectAllText = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

export const getNodesValue = (nodes, key) =>
  getValueFromRecordsIfEqual(nodes, key);

export const getStatuses = t => [
  { label: t('status.pending'), value: 'pending' },
  { label: t('status.started'), value: 'started' },
  { label: t('status.completed'), value: 'completed' },
  { label: t('status.blocked'), value: 'blocked' },
];

export const getPublicationStatuses = t => [
  { label: t('publication_status.draft'), value: 'draft' },
  { label: t('publication_status.published_all'), value: 'published_all' },
];

export const nodePlaceholders = [
  { type: 'position-prev', position: -0.5, class: 'placeholder-prev' },
  { type: 'position-next', position: +0.5, class: 'placeholder-next' },
  { type: 'parent', position: null, class: 'placeholder-child' },
];

export const sanitizedText = text =>
  text
    .replace(/<p><\/p>/, '')
    .replace(/<\/?[^>]*>/, '')
    .trim()
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ');

export const sanitizedName = name => sanitizedText(name);
export const sanitizedDescription = description => sanitizedText(description);

export const canAddChildren = node =>
  node.canChange && !node.isTag && !node.isExternal;

export const revealNodeInDomWithSidebar = (node, sidebar = true) => {
  node.revealTab();
  setTimeout(() => {
    node.toggleSelected(sidebar);
    node.reveal();
  }, 500);
};

export const checkNodeIdInParamsAndRevealIfPresent = tree => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const nodeId = params.get('n');

  if (isEmpty(nodeId)) return;

  const node = tree.store.rootStore.nodes.getById(nodeId);

  if (isEmpty(node)) return;
  if (!isEqual(node.tree.id, tree.id)) return;

  revealNodeInDomWithSidebar(node);
};

export const showSiblingHandles = node => {
  if (node.new) return true;
  if (!node.canChange) return false;
  if (node.root || node.fetching || node.isOrphanContainerNode) return false;
  if (node.tree.multiSelect || !node.selected || node.isOrphan) return false;
  if (node.externalised && node.expanded) return false;

  return true;
};

export const getEllipsisActions = (t, node, callback, history) => {
  const canPaste =
    node.store.hasCopiedNodes &&
    !includes(node.store.copiedNodeIds, node.id) &&
    !node.externalised &&
    !node.isTag &&
    !node.masterTree.pastingNodes;

  return [
    {
      icon: 'edit',
      name: t('labels.edit'),
      onClick: () => {
        node.focusOn(false);
        callback();
      },
      show: true,
    },
    {
      icon: 'copy',
      name: t('labels.copy'),
      onClick: () => {
        node.store.copyNodes([node.id]);
        callback();
      },
      show: !node.root,
    },
    {
      icon: 'paste',
      name: t('labels.paste'),
      onClick: () => {
        node.pasteNodes(false);
        callback();
      },
      show: canPaste,
    },
    {
      icon: 'paste',
      name: t('labels.paste_deep'),
      onClick: () => {
        node.pasteNodes(true);
        callback();
      },
      show: canPaste,
    },
    {
      icon: 'user',
      name: t('sidebar.assign.to_me'),
      show: !node.root && !node.isAssignedToMe,
      onClick: () => {
        node.store.rootStore.nodeAssignees.addByRecordIds(
          node.store.rootStore.users.currentUser.id,
          [node.id]
        );
        node.update({ selected: false });
        callback();
      },
    },
    // {
    //   icon: 'columns',
    //   name: t('boards.from_node.action'),
    //   onClick: () => {
    //     handleNodeToBoard(node, false, () => {}, history);
    //     callback();
    //   },
    //   show: node.hasChildren,
    // },
    {
      icon: 'hourglass',
      name: `Set to '${t('status.pending')}'`,
      onClick: () => {
        node.updateStatus('pending');
        callback();
      },
      show: !node.root && node.status !== 'pending',
    },
    {
      icon: 'hourglass-start',
      name: `Set to '${t('status.started')}'`,
      onClick: () => {
        node.updateStatus('started');
        callback();
      },
      show: !node.root && node.status !== 'started',
    },
    {
      icon: 'hourglass-end',
      name: `Set to '${t('status.completed')}'`,
      onClick: () => {
        node.updateStatus('completed');
        callback();
      },
      show: !node.root && node.status !== 'completed',
    },
    {
      icon: 'exclamation',
      name: `Set to '${t('status.blocked')}'`,
      onClick: () => {
        node.updateStatus('blocked');
        callback();
      },
      show: !node.root && node.status !== 'blocked',
    },
  ];
};
