import React from 'react';

import { observer } from 'mobx-react';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import useStore from '../../../data/store';
import TreeTabs from './TreeTabs';
import TreeCollaborators from './TreeCollaborators';

const InfoBar = () => {
  const { trees, app } = useStore();
  const { t } = useTranslation();

  return (
    <div className="info-bar">
      <TreeTabs tree={trees.masterTree} />
      {!app.embedded && (
        <div className="non-tabs">
          <TreeCollaborators tree={trees.activeTree} />
          {trees.activeTree?.rootNode?.canChange && (
            <Button
              className="tree-settings"
              size="sm"
              color="light"
              title={t('trees.infobar.settings.tooltip')}
              onClick={() => trees.activeTree.showSettings()}
            >
              <FontAwesomeIcon icon="bars" />
              <span className="pl-2">{t('trees.infobar.settings.title')}</span>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(InfoBar);
