import React from 'react';

import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import SharedWith from '../../shared_with';
import ContentContainer from './ContentContainer';
import TreeScopeSelector from '../../tree_scope_selector';
import NodeTypeSelector from '../../nodes/node_type_selector';

const NodeSharedWith = observer(({ sidebar }) => {
  const { t } = useTranslation();
  const sNode = sidebar.node;

  return (
    <ContentContainer title={t('sidebar.tabs.shared_with.label')}>
      <SharedWith record={sidebar.node} />
      {sNode.root && (
        <>
          <hr />
          <TreeScopeSelector node={sNode} treeName={sidebar.treeName} />
        </>
      )}
    </ContentContainer>
  );
});

export default NodeSharedWith;
