import React, { useEffect, useState } from 'react';

import Board from 'react-trello';
import { observer } from 'mobx-react';

import { uniq } from 'lodash/array';
import { arrayMove } from '../../../helpers/shared_helpers';
import useStore from '../../../data/store';
import BoardItem from './BoardItem';

const Kanban = observer(({ board }) => {
  const { sidebars } = useStore();
  const [changedLanes] = useState([]);

  useEffect(() => {
    if (!board.fetched) {
      board.fetchAllLanes();
    }
  }, [board?.id]);

  const addToChangedLanes = newLane => {
    if (!changedLanes[newLane]) changedLanes.push(newLane);
  };

  const handleDataChangeForCardPosition = (
    cardId,
    sourceLaneId,
    targetLaneId
  ) => {
    addToChangedLanes(sourceLaneId);
    if (targetLaneId && sourceLaneId !== targetLaneId) {
      // status has changed
      board.boardItemById(cardId).node.update({ status: targetLaneId }, true);
      addToChangedLanes(targetLaneId);
    }
    return true;
  };

  const handleBoardStateChanged = newBoardData => {
    if (changedLanes.length < 1) return true;

    for (const cl of uniq(changedLanes)) {
      let laneData;
      for (const l of newBoardData.lanes) {
        if (l.id === cl) {
          laneData = l.cards;
          break;
        }
      }
      const boardStatePayload = {};
      boardStatePayload[cl] = laneData;
      board.laneById(cl).updateItemPositionsInBoardState(laneData);
    }
    changedLanes.length = 0;
    return true;
  };

  const handleDataChangeForLanePosition = (oldIndex, newIndex) => {
    const newLanesOrder = arrayMove(
      oldIndex,
      newIndex,
      board.userState.laneIds || []
    );

    board.userState.update({ laneIds: newLanesOrder }, true);
  };

  const handleScroll = (rp, laneId) => {
    if (board.hasNextPage(laneId)) {
      return board.fetchItems(laneId);
    }
    return new Promise((resolve, reject) => {
      resolve([]);
    });
  };

  const handleCardClick = cardId => {
    const bItem = board.boardItemById(cardId);

    sidebars.addSidebar({
      type: 'node',
      nodeIds: [cardId],
      activeTab: 'details',
      viewType: 'board',
      treeName: bItem.rootNode.name,
      onDestroy: () => bItem.destroy(),
    });
    sidebars.rootStore.nodes.markAsViewed(cardId);
  };

  return (
    <Board
      components={{
        Card: BoardItem,
      }}
      onCardClick={handleCardClick}
      data={board.kanbanJson}
      draggable
      cardDraggable={board.canChange}
      laneDraggable={board.canChange}
      handleLaneDragEnd={handleDataChangeForLanePosition}
      handleDragEnd={handleDataChangeForCardPosition}
      hideCardDeleteIcon
      onLaneScroll={handleScroll}
      onDataChange={handleBoardStateChanged}
      cardStyle={{ maxWidth: 320 }}
      style={{ textTransform: 'capitalize' }}
    />
  );
});

export default Kanban;
