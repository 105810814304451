import { makeObservable, action, flow, computed } from 'mobx';
import { camelCase, isEmpty, isEqual, map } from 'lodash';

class NodeAssignee {
  /* eslint-disable */
  id;
  nodeCollaboratorId;
  userId;
  nodeId;
  store;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'node_collaborator')) {
          this.nodeCollaboratorId = value[k];
        }
        if (isEqual(k, 'user')) {
          this.userId = value[k]?.id;
          return;
        }

        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  @computed
  get user() {
    if (isEmpty(this.userId)) return null;

    return this.store.rootStore.users.getById(this.userId);
  }

  @action
  destroy() {
    this.store.records.splice(this.store.records.indexOf(this), 1);
  }
}

export default NodeAssignee;
