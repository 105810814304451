import { makeObservable, observable, computed, action, flow, toJS } from 'mobx';
import {
  camelCase,
  find,
  isEmpty,
  isEqual,
  isNull,
  map,
  uniqBy,
  includes,
  filter,
} from 'lodash';

import { compact, concat, indexOf } from 'lodash/array';
import Criteria from './board_criteria';
import Lane from './board_lane';
import BoardItem from './board_item';
import client from '../axiosClient';
import UserState from './user_state';
import { getValuesFromArray, parameterize } from '../../helpers/shared_helpers';

class Board {
  /* eslint-disable */
  id;
  @observable name;
  @observable description;
  isNew;
  @observable criteria;
  @observable userState;
  @observable state;
  @observable canChange;
  @observable boardItems = [];
  @observable lanes = [];
  store;
  @observable fetched = false;
  @observable backgroundUrl = 'random';
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        if (isEqual(k, 'collaborators')) return;
        if (isEqual(k, 'criteria')) {
          this.criteria = new Criteria(value[k], this);
          return;
        }
        if (isEqual(k, 'user_state')) {
          this.userState = new UserState(value[k], this);
          return;
        }
        this[camelCase(k)] = value[k];
        this.store = store;
      }.bind(this)
    );

    if (!isEmpty(value.user_state)) {
      this.lanes = map(
        value.user_state.lane_ids,
        l => new Lane({ id: l, name: l }, this)
      );
    }
  }

  laneById(lane) {
    return find(this.lanes, { id: lane });
  }

  boardItemById(id) {
    return find(this.boardItems, { id });
  }

  hasNextPage(laneId) {
    return !isNull(this.laneById(laneId).pagy.next);
  }

  orderedByState(lane, laneRecords) {
    const savedOrderOfLaneItems = this.state[lane];
    if (isEmpty(savedOrderOfLaneItems)) return laneRecords;
    if (isEmpty(laneRecords)) return laneRecords;

    const ordered = [];
    const unOrdered = [];
    for (const lr of laneRecords) {
      const indexOfSavedItem = indexOf(savedOrderOfLaneItems, lr.id);

      if (indexOfSavedItem >= 0) {
        ordered[indexOfSavedItem] = lr;
      } else {
        unOrdered.push(lr);
      }
    }
    const rtn = compact(concat(ordered, unOrdered));

    return rtn;
  }

  @computed
  get collaborators() {
    return filter(this.store.rootStore.boardCollaborators.records, {
      collaborationId: this.id,
    });
  }

  @computed
  get kanbanJson() {
    return {
      boardId: this.id,
      lanes: map(this.userState.laneIds, l => ({
        id: l,
        title: l,
        cards: this.orderedByState(l, this.laneById(l).recordsAsCardsJson),
      })),
    };
  }

  @computed
  get toParams() {
    return {
      name: this.name,
      description: this.description,
      criteria: {
        assigned_to: getValuesFromArray(
          toJS(this.criteria.assignedTo),
          'value'
        ),
        trees: getValuesFromArray(toJS(this.criteria.trees), 'value'),
        tags: getValuesFromArray(toJS(this.criteria.tags), 'value'),
        due_date_from: this.criteria.dueDateFrom,
        due_date_to: this.criteria.dueDateTo,
      },
    };
  }

  @computed
  get asForm() {
    return new Board(
      {
        id: this.id,
        name: this.name,
        description: this.description,
        criteria: this.criteria,
      },
      this.store
    );
  }

  @action
  update(params, updateServer = false) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );

    if (updateServer) this.updateServer(parameterize(params));
  }

  // remove BoardItem here
  @action
  setBoardItems(records) {
    this.boardItems = uniqBy(
      [...this.boardItems, ...map(records, r => new BoardItem(r, this))],
      'id'
    );
  }

  @action
  fetchAllLanes() {
    this.fetched = true;

    map(this.lanes, l => {
      this.fetchItems(l.id);
    });
  }

  @action
  reset() {
    this.fetched = false;
    map(this.lanes, l => l.reset());
    this.boardItems = [];
    this.fetchAllLanes();
  }

  @flow
  *markAsViewed() {
    const response = yield client.get(
      `/api/v1/boards/${this.id}/mark_as_viewed.json`
    );
    return response.data.success;
  }

  @flow
  *fetchItems(laneId) {
    const lane = this.laneById(laneId);

    const response = yield client(`/api/v1/boards/${this.id}/page.json`, {
      params: {
        status: laneId,
        page: lane.pagy.next,
      },
    });

    if (response.data.success) {
      this.setBoardItems(response.data.results.cards);
      lane.update({ pagy: response.data.results.pagy });
    }
  }

  @flow
  *destroy() {
    const response = yield client.delete(`/api/v1/boards/${this.id}.json`);

    if (response.data.success) {
      this.store.updateActiveBoard(null);
      this.store.records.splice(this.store.records.indexOf(this), 1);
    }

    return response.data.success;
  }

  @flow
  *updateServer(params) {
    yield client.put(`/api/v1/boards/${this.id}.json`, params);
  }

  @flow
  *submit() {
    const params = this.toParams;
    const response = this.isNew
      ? yield client.post('/api/v1/boards.json', params)
      : yield client.put(`/api/v1/boards/${this.id}.json`, params);

    if (response.data.success) {
      if (this.isNew) {
        this.store.setRecords([response.data.board], false);
        this.store.updateActiveBoard(response.data.board.id, true);
      } else {
        const board = this.store.getById(this.id);
        board.update({
          name: this.name,
          description: this.description,
          criteria: this.criteria,
        });
        board.reset();
      }

      return true;
    }

    return false;
  }

  @flow
  *inviteCollaborator(params) {
    const response = yield* this.store.rootStore.boardCollaborators.invite(
      this.id,
      params
    );

    return response;
  }

  @flow
  *refreshState() {
    const response = yield client.get(
      `/api/v1/boards/${this.id}/get_state.json`
    );

    if (response.data.success) {
      this.update({ state: response.data.state });
    }
  }
}

export default Board;
