import React from 'react';

import { chunk, map } from 'lodash';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

const NodeAssignees = observer(({ node }) => (
  <>
    {map(chunk(node.assignees, 3)[0], (a, i) => (
      <div key={i} className="cell-assignee-avatar">
        {a?.user?.initials}
      </div>
    ))}
  </>
));

NodeAssignees.propTypes = {
  node: PropTypes.instanceOf(Object),
};

export default NodeAssignees;
