import React from 'react';

import {
  Button,
  Form as BootstrapForm,
  FormGroup,
  Input,
  Label,
  Alert,
  InputGroup,
} from 'reactstrap';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import fileExtension from 'file-extension';
import { includes, isEmpty, join, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const Form = ({ attachment, reset, featured }) => {
  const featuredWhiteListed = [
    'jpeg',
    'gif',
    'jpg',
    'png',
    'mp4',
    'mkv',
    'avi',
  ];
  const allWhiteListed = [...featuredWhiteListed, 'pdf', 'txt'];

  const whiteListed = featured ? featuredWhiteListed : allWhiteListed;
  const extensions = join(
    map(whiteListed, e => `.${e}`),
    ','
  );
  const handleChange = e =>
    attachment.update({ [e.target.name]: e.target.value });

  const handleFileChange = e => {
    if (!isEmpty(e.target.files)) {
      const file = e.target.files[0];
      const extension = fileExtension(file.name);

      if (includes(whiteListed, extension)) {
        attachment.update({ attachment: file });
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (attachment.isNew) {
      if (attachment.creating) return;
      attachment.create().then(() => reset());
    } else {
      attachment.updateServer().then(() => reset());
    }
  };
  const { t } = useTranslation();
  return (
    <BootstrapForm onSubmit={handleSubmit}>
      {!featured && (
        <h4 className="mb-3">{attachment.isNew ? 'Add' : 'Edit'} Attachment</h4>
      )}
      <div className="sidebar-common-inputs">
        {!featured && (
          <FormGroup>
            <Label>{t(`sidebar.attachments.form.title`)}</Label>
            <Input
              required
              disabled={attachment.creating}
              type="text"
              name="attachmentTitle"
              onChange={handleChange}
              accept={extensions}
              value={attachment.attachmentTitle}
            />
          </FormGroup>
        )}
        {attachment.isNew && (
          <>
            <FormGroup style={{ zIndex: 101 }}>
              <Label>
                {t(
                  `sidebar.attachments.form.${featured ? 'featured' : 'file'}`
                )}
              </Label>
              <InputGroup
                className={classnames({ 'custom-file-input-group': featured })}
              >
                <div className="custom-file">
                  <Input
                    onChange={handleFileChange}
                    type="file"
                    name="attachment"
                    id="attachmentFile"
                    accept={extensions}
                  />
                  <Label
                    disabled={attachment.creating}
                    className="custom-file-label"
                    for="attachmentFile"
                  >
                    {attachment.attachment
                      ? attachment.attachment.name
                      : t('sidebar.attachments.form.choose')}
                  </Label>
                </div>
                {featured && (
                  <Button color="success" type="submit">
                    {t('sidebar.attachments.form.upload')}
                  </Button>
                )}
              </InputGroup>
              <div className="small feedback text-muted">
                {t('sidebar.attachments.form.extensions')}
                <strong> {join(whiteListed, ' , ')} </strong>
              </div>
            </FormGroup>
          </>
        )}
        <div className="actions d-flex justify-content-between mt-4">
          {attachment.creating ? (
            <Alert className="flex-grow-1 text-center" color="success">
              {t('sidebar.attachments.form.uploading')}
            </Alert>
          ) : (
            <>
              {!featured && (
                <>
                  <Button
                    onClick={reset}
                    type="button"
                    color="outline-success mr-2"
                  >
                    {t('alert.buttons.cancel')}
                  </Button>
                  <Button color="success" type="submit" style={{ flex: 1 }}>
                    {attachment.isNew
                      ? t('sidebar.attachments.form.upload')
                      : t('sidebar.attachments.form.update')}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BootstrapForm>
  );
};

Form.defaultProps = {
  featured: false,
};

Form.propTypes = {
  attachment: PropTypes.instanceOf(Object).isRequired,
  reset: PropTypes.func.isRequired,
  featured: PropTypes.bool,
};

export default observer(Form);
