import { action, computed, makeObservable, observable } from 'mobx';
import { camelCase, find, findIndex, isEmpty, isNull, map } from 'lodash';

class Playlist {
  /* eslint-disable */
  rootStore;
  @observable active = null;
  @observable loading = false;
  @observable playingQuiz = false;
  /* eslint-enable */

  constructor(rootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  @computed
  get videos() {
    if (
      !isEmpty(this.rootStore.trees.masterTree) &&
      !this.rootStore.trees.masterTree.isTutorial
    )
      return [];

    const attachments = [];

    const getAttachmentFromChildren = node => {
      if (!node.isTag && node.hasChildren) {
        map(node.children, c => {
          if (c.isPlayable) {
            attachments.push(c);
          }

          getAttachmentFromChildren(c);
        });
      }
    };

    const startNode = this.rootStore.trees.masterTree.rootNode;

    if (startNode.isPlayable) {
      attachments.push(startNode);
    }

    getAttachmentFromChildren(startNode);

    return attachments;
  }

  @computed
  get description() {
    return this.active?.description;
  }

  @computed
  get hasDescription() {
    return !isEmpty(this.description);
  }

  @computed
  get hasActive() {
    return !isEmpty(this.active);
  }

  @computed
  get previous() {
    if (isNull(this.active)) return null;
    if (isEmpty(this.videos[this.activeIndex - 1])) return null;

    return this.videos[this.activeIndex - 1];
  }

  @computed
  get next() {
    if (isNull(this.active)) return null;
    if (isEmpty(this.videos[this.activeIndex + 1])) return null;

    return this.videos[this.activeIndex + 1];
  }

  @computed
  get activeIndex() {
    return findIndex(this.videos, { id: this.active.id });
  }

  @computed
  get showPlayer() {
    return !isNull(this.active);
  }

  @computed
  get hasPrevious() {
    return !isEmpty(this.previous);
  }

  @computed
  get hasNext() {
    return !isEmpty(this.next);
  }

  @action
  gotoPrev() {
    this.start(this.previous);
  }

  @action
  gotoNext() {
    this.start(this.next);
  }

  @action
  start(node = null) {
    const nodeToStart = find(this.videos, { id: node.id }) || this.videos[0];
    if (nodeToStart) {
      nodeToStart.reveal();
      this.update({ active: nodeToStart, playingQuiz: false });
    }
  }

  @action
  reset() {
    this.update({ active: null });
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }
}

export default Playlist;
