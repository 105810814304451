import React from 'react';

import PropTypes from 'prop-types';
import { map } from 'lodash';
import { observer } from 'mobx-react';
import { Input } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const StoreSearchWidget = ({ className, stores }) => {
  const { t } = useTranslation();
  const handleChange = e => {
    map(stores, store => {
      store.update({ searchQuery: e.target.value });
      store.fetch();
    });
  };

  return (
    <div className={classnames(className, 'sidebar-common-inputs')}>
      <Input
        type="search"
        placeholder={t('form.placeholders.search')}
        value={stores[0].searchQuery}
        onChange={handleChange}
        className="w-100 form-control"
      />
    </div>
  );
};

StoreSearchWidget.defaultProps = {
  className: '',
};

StoreSearchWidget.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.instanceOf(Array).isRequired,
};

export default observer(StoreSearchWidget);
