import { makeAutoObservable, observable, action, flow, computed } from 'mobx';
import { filter, isEmpty, map, uniqBy, sortBy } from 'lodash';

import Notification from '../entities/notification';
import client from '../axiosClient';

class NotificationStore {
  @observable records = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  @computed
  get unread() {
    return filter(this.records, r => !r.isViewed);
  }

  @computed
  get read() {
    return filter(this.records, r => r.isViewed);
  }

  @computed
  get hasUnread() {
    return !isEmpty(this.unread);
  }

  @computed
  get unreadCount() {
    return this.unread.length;
  }

  @action
  resetRecords() {
    this.records = [];
  }

  @action
  setRecords(records) {
    this.records = uniqBy(
      [...map(records, r => new Notification(r, this))],
      'id'
    );
  }

  @action
  showNotifications() {
    this.rootStore.sidebars.addSidebar({
      type: 'notifications',
    });
  }

  @action
  addNewNotification(record) {
    this.records.push(new Notification(record, this));
  }

  @flow
  *fetchNotifications() {
    const response = yield client.get('/api/v1/users/my_activities.json');

    this.setRecords(response.data.results);
  }

  @flow
  *viewedAll() {
    if (!this.hasUnread) return;

    const response = yield client.put('/api/v1/users/viewed_activities.json');

    if (response.data.success) this.fetchNotifications();
  }
}

export default NotificationStore;
