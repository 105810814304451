import React from 'react';

import { Label } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import {
  customStyles,
  getIdsFromRecords,
} from '../../../../helpers/shared_helpers';
import { getNodesValue, getStatuses } from '../../../../helpers/node_helpers';
import useStore from '../../../../data/store';

const NodeStatus = observer(({ nodes }) => {
  const { nodes: store } = useStore();
  const status = getNodesValue(nodes, 'status');

  const handleChange = selectedVal =>
    store.updateNodes(getIdsFromRecords(nodes), {
      action_type: 'work',
      status: selectedVal.value,
    });
  const { t } = useTranslation();
  const statuses = getStatuses(t);
  return (
    <div className="status-selector">
      <Label>{t('sidebar.work.status')}</Label>
      <Select
        options={statuses}
        value={find(statuses, { value: status })}
        onChange={handleChange}
        styles={customStyles}
      />
    </div>
  );
});

NodeStatus.propTypes = {
  nodes: PropTypes.instanceOf(Array).isRequired,
};

export default NodeStatus;
