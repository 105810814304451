import React, { useState } from 'react';

import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isEmpty } from 'lodash';
import useStore from '../../data/store';
import { isEmail } from '../../helpers/shared_helpers';

const GuestAlert = () => {
  const { app, users, notification } = useStore();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const toggle = () => {
    app.update({ guestAlert: false, settingUpGuestUser: false });
  };

  const onSubmit = () => {
    if (isEmpty(email) || !isEmail(email))
      return notification('danger', 'Please enter a valid email');

    app.update({ settingUpGuestUser: true });

    users.createGuest({ email }).then(res => {
      if (res.success) return users.fetchCurrentUser();

      app.update({ settingUpGuestUser: false });
      notification('danger', res.message);
    });
  };

  return (
    <Modal isOpen={app.guestAlert} toggle={toggle} backdrop="static">
      <Button className="toggle-modal" onClick={toggle}>
        &times;
      </Button>
      <ModalHeader>{t('users.guest.alert_modal.heading')}</ModalHeader>
      <ModalBody>
        <div
          className="text-left"
          dangerouslySetInnerHTML={{
            __html: t('users.guest.access_limit'),
          }}
        />
        <div className="common-inputs mt-3">
          <FormGroup>
            <Input
              placeholder={t('form_fields_email')}
              value={email}
              type="email"
              onChange={e => setEmail(e.target.value)}
              required
            />
          </FormGroup>
        </div>
        <Button
          disabled={app.settingUpGuestUser}
          onClick={onSubmit}
          color="success mt-5 d-block w-100"
        >
          {app.settingUpGuestUser ? (
            <FontAwesomeIcon icon="spinner" className="fa-spin" />
          ) : (
            t('users.guest.alert_modal.start_exploring')
          )}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default observer(GuestAlert);
