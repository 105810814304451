import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty, isEqual, map } from 'lodash';

import { useTranslation } from 'react-i18next';
import ControlBarButton from './Button';
import useStore from '../../../../data/store';

const ZoomToggle = observer(({ tree }) => {
  const { app } = useStore();
  const [timeout, setTimeOut] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    app.update({
      showZoomAlert: true,
      zoomPercent: parseInt(`${tree.zoom * 100}`),
    });

    setTimeOut(clearTimeout(timeout));
    setTimeOut(
      setTimeout(
        () => app.update({ showZoomAlert: false, zoomPercent: '0' }),
        1000
      )
    );
  }, [tree.zoom]);

  if (isEmpty(tree)) return null;

  return (
    <>
      <div className="control-bar-btn change-zoom">
        {map([tree.zoom - 0.1, tree.zoom + 0.1], (z, i) => (
          <ControlBarButton
            key={i}
            disabled={parseFloat(z) <= 0.2 || parseFloat(z) >= 2}
            paramKey="zoom"
            tree={tree}
            paramValue={parseFloat(z)}
            active={false}
            title={t('control_bar.tooltip.zoom')}
          >
            <FontAwesomeIcon
              icon={isEqual(i, 0) ? 'search-minus' : 'search-plus'}
              data-toggle="tooltip"
            />
          </ControlBarButton>
        ))}
      </div>
    </>
  );
});

ZoomToggle.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default ZoomToggle;
