import { action, computed, makeObservable, observable } from 'mobx';
import { isNull } from 'lodash';

import BaseEntity from './base_entity';

class Pagination extends BaseEntity {
  /* eslint-disable */
  @observable count = 0;
  @observable page = 1;
  @observable pages = null;
  @observable prev = null;
  @observable next = null;
  @observable last = null;
  @observable from = null;
  @observable to = null;
  @observable params = {};
  @observable filterType = 'fetch';
  initialParams = {};
  /* eslint-enable */

  constructor(value, store) {
    super(value, store);

    makeObservable(this);

    this.initialize(value);
    this.initialParams = value.params;
  }

  @action
  fetch() {
    this.store.fetch({ ...this.params, page: this.page });
  }

  @action
  updateParams(params) {
    this.update({ params: { ...this.params, ...params } });
  }

  @action
  gotoPage(page) {
    this.update({ page });
    this.fetch();
  }

  @action
  resetPageAndFetch() {
    this.gotoPage(1);
  }

  @action
  reset() {
    this.update({ page: 1, params: this.initialParams });
    this.fetch();
  }
}

export default Pagination;
