import React, { useState } from 'react';

import { PropTypes } from 'prop-types';
import { isEmpty, isNull, map } from 'lodash';
import { Row, Col, Button, Input } from 'reactstrap';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Tree from './Tree';

import GroupDeleteHandler from '../../shared/GroupDeleteHandler';
import useStore from '../../../data/store';

const GroupTreeList = observer(({ store, group }) => {
  const { t } = useTranslation();
  const [groupName, setGroupName] = useState(group.name);
  const [edit, setEdit] = useState(false);
  const {
    users: { currentUser },
  } = useStore();

  const pagination = () => (
    <div className="group-name">
      <span>{group.name}</span>
      <ul className="list-unstyled list-inline justify-content-start">
        {store.pagination.count > 0 && (
          <>
            <li className="list-inline-item">
              <span className="trees-count">
                <span>
                  {store.pagination.count >= 20 && (
                    <>
                      {store.pagination.from} to {store.pagination.to} of{' '}
                    </>
                  )}
                  {store.pagination.count}
                </span>{' '}
                {store.recordsToShow.length > 1 ? 'trees' : 'tree'}
              </span>
            </li>
            {!(
              isNull(store.pagination.prev) && isNull(store.pagination.next)
            ) && (
              <>
                <li className="small list-inline-item">
                  <Button
                    color="link"
                    disabled={isNull(store.pagination.prev)}
                    onClick={() =>
                      store.pagination.gotoPage(store.pagination.prev)
                    }
                  >
                    Prev
                  </Button>
                </li>
                <li className="small list-inline-item">
                  <Button
                    color="link"
                    disabled={isNull(store.pagination.next)}
                    onClick={() =>
                      store.pagination.gotoPage(store.pagination.next)
                    }
                  >
                    Next
                  </Button>
                </li>
              </>
            )}
          </>
        )}
      </ul>
    </div>
  );

  return (
    <>
      <div className="group-header">
        {!group.default && (
          <Button color="light" onClick={() => group.close()}>
            <FontAwesomeIcon icon="chevron-left" />
          </Button>
        )}
        <h4 className="m-0 ml-3 group-name-actions">
          {edit ? (
            <>
              <Input
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
              />
              <div className="ml-3 group-edit-actions">
                <Button
                  color="success"
                  onClick={() => {
                    group.update({ name: groupName }, true);
                    setEdit(false);
                  }}
                >
                  <FontAwesomeIcon icon="check" />
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    setEdit(false);
                    setGroupName(group.name);
                  }}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>
              </div>
            </>
          ) : (
            pagination()
          )}
          {group.isOwner && !edit && (
            <div className="group-edit-actions">
              {currentUser?.isInstanceAdmin && (
                <Button
                  color={group.selected ? 'success' : 'light'}
                  onClick={() => group.toggleSelect()}
                >
                  <FontAwesomeIcon icon="bars" />
                </Button>
              )}
              <GroupDeleteHandler group={group} color="danger" />
              {!edit && (
                <Button color="light" onClick={() => setEdit(true)}>
                  <FontAwesomeIcon icon="edit" />
                </Button>
              )}
            </div>
          )}
        </h4>
      </div>
      <Row>
        {map(store.recordsToShow, (tree, i) => (
          <Col
            lg={6}
            xl={3}
            key={i}
            className={classnames({
              'featured-tree': tree.showFeaturedPreview,
            })}
          >
            <Tree tree={tree} />
          </Col>
        ))}
      </Row>
      {isEmpty(store.recordsToShow) && (
        <div className="empty-trees">
          <h2>{t('trees.no_trees')}</h2>
        </div>
      )}
    </>
  );
});

GroupTreeList.propTypes = {
  tab: PropTypes.string.isRequired,
  store: PropTypes.instanceOf(Object).isRequired,
  group: PropTypes.instanceOf(Object).isRequired,
};

export default GroupTreeList;
