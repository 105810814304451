import { action, computed, flow, makeObservable, observable } from 'mobx';
import { camelCase, filter, isEqual, map } from 'lodash';
import client from '../axiosClient';

class Lane {
  /* eslint-disable */
  id;
  name;
  @observable pagy = {
    next: 1
  };
  board;
  /* eslint-enable */

  constructor(value, board) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.board = board;
  }

  @action
  reset() {
    this.pagy = {
      next: 1,
    };
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  // sort the boarditems here based on position.
  @computed
  get boardItems() {
    return filter(this.board.boardItems, bi =>
      isEqual(bi.node.status, this.id)
    );
  }

  @computed
  get recordsAsCardsJson() {
    return map(this.boardItems, b => b.cardJson);
  }

  @flow
  *updateItemPositionsInBoardState(snapshotOfBoardItemsInLane) {
    const nodeIds = snapshotOfBoardItemsInLane.map(c => c.id);
    return yield client.put(`/api/v1/boards/${this.board.id}/state.json`, {
      key: this.id,
      data: nodeIds,
    });
  }
}

export default Lane;
