import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { getComponentFromTab } from '../../../../helpers/sidebar_helpers';

const BoardSettings = observer(({ sidebar }) => (
  <div className="node-properties">
    <div className="sidebar-tab-content">{getComponentFromTab(sidebar)}</div>
  </div>
));

BoardSettings.propTypes = {
  sidebar: PropTypes.instanceOf(Object).isRequired,
};

export default BoardSettings;
