import { makeObservable, observable, action, computed } from 'mobx';
import { map, camelCase, isEmpty, isEqual, includes } from 'lodash';
import Node from './node';

class Sidebar {
  /* eslint-disable */
  id;
  nodeIds = [];
  viewType = 'tree';

  // all tabs go here, keyed on name
  allTabs = {
    details: ['details', 'info'],
    work: ['work', 'tasks'],
    attachments: ['attachments', 'paperclip'],
    tree_attachments: ['tree_attachments', 'paperclip'],
    comments: ['comments', 'comment-alt'],
    share: ['share', 'share-alt'],
    sharedWith: ['shared_with', 'users'],
    settings: ['settings', 'cog'],
    summary: ['summary', 'file-alt'],
    quiz: ['quiz', 'question'],
  };

  @observable type;
  @observable active = true;
  @observable activeTab = 'details';
  @observable treeName = '';
  @observable onDestroy;
  @observable boardId;
  /* eslint-enable */

  constructor(value, store) {
    makeObservable(this);

    map(
      Object.keys(value),
      function(k) {
        this[camelCase(k)] = value[k];
      }.bind(this)
    );

    this.store = store;
  }

  @computed
  get prevSidebar() {
    return this.store.findByIndex(this.index - 1);
  }

  @computed
  get prevSidebarPresent() {
    return !isEmpty(this.prevSidebar);
  }

  @computed
  get index() {
    return this.store.records.indexOf(this);
  }

  @computed
  get node() {
    if (isEqual(this.viewType, 'board-share')) {
      return new Node(
        {
          id: this.nodeIds[0],
          root: true,
          rootId: this.nodeIds[0],
          name: this.prevSidebar.treeName,
        },
        this.store.rootStore.nodes
      );
    }

    return this.nodes[0];
  }

  @computed
  get board() {
    return this.store.rootStore.boards.activeBoard;
  }

  @computed
  get record() {
    return isEqual(this.type, 'board-edit') ? this.board : this.node;
  }

  @computed
  get nodes() {
    return map(this.nodeIds, n => this.store.rootStore.nodes.getById(n));
  }

  @computed
  get tabs() {
    let rtn = [];
    switch (this.viewType) {
      case 'board-share':
        rtn = [this.allTabs.share];
        break;
      case 'tree':
        rtn = this.commonTabs;
        break;
      case 'board':
        rtn = this.commonTabs;
        break;
      default:
        rtn = [];
    }

    const allTabs = map(rtn, r => r[0]);

    if (!includes(allTabs, this.activeTab)) {
      if (rtn[0] && rtn[0][0]) {
        // eslint-disable-next-line prefer-destructuring
        this.activeTab = rtn[0][0];
      }
    }

    return rtn;
  }

  @computed
  get nodeTabs() {
    const sTree = this.node?.tree || {};
    const sTabs = [];

    sTabs.push(this.allTabs.details);
    if (this.store.rootStore.users.userSignedIn)
      sTabs.push(this.allTabs.comments);

    if (sTree.isOwner || sTree.userIsInstanceAdmin || this.node.canChange) {
      if (!this.node.root) sTabs.push(this.allTabs.work);
      sTabs.push(this.allTabs.attachments);
      if (!this.node.root) sTabs.push(this.allTabs.settings);
      sTabs.push(this.allTabs.quiz);
    }
    if (sTree.userIsInstanceAdmin) sTabs.push(this.allTabs.summary);
    return sTabs;
  }

  @computed
  get treeTabs() {
    const sTree = this.node?.tree || {};
    const sTabs = [];

    if (
      this.node.root &&
      (sTree.isOwner || sTree.userIsInstanceAdmin || this.node.canChange)
    ) {
      sTabs.push(this.allTabs.settings);
      sTabs.push(this.allTabs.share);
      sTabs.push(this.allTabs.sharedWith);
      sTabs.push(this.allTabs.tree_attachments);
    }
    if (sTree.userIsInstanceAdmin) sTabs.push(this.allTabs.summary);
    return sTabs;
  }

  @computed
  get addBoardTabs() {
    return [this.allTabs.details];
  }

  @computed
  get editBoardTabs() {
    const sTabs = [];

    sTabs.push(this.allTabs.details);
    sTabs.push(this.allTabs.settings);
    sTabs.push(this.allTabs.share);
    sTabs.push(this.allTabs.sharedWith);

    return sTabs;
  }

  @computed
  get commonTabs() {
    switch (this.type) {
      case 'node':
        return this.nodeTabs;
      case 'tree':
        return this.treeTabs;
      case 'board-add':
        return this.addBoardTabs;
      case 'board-edit':
        return this.editBoardTabs;
      default:
        return [];
    }
  }

  @computed
  get fetching() {
    return this.node.fetching;
  }

  @action
  gotoPrev() {
    this.prevSidebar.setActive();
    setTimeout(() => this.destroy(), 1000);
  }

  @action
  update(params) {
    map(
      Object.keys(params),
      function(k) {
        this[camelCase(k)] = params[k];
      }.bind(this)
    );
  }

  @action
  setActive() {
    this.store.closeAll();
    this.active = true;
  }

  @action
  destroy(withCallback = false) {
    if (this.onDestroy && withCallback) this.onDestroy();

    this.store.records.splice(this.store.records.indexOf(this), 1);
  }
}

export default Sidebar;
