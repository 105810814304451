import {
  differenceWith,
  filter,
  isEmpty,
  isEqual,
  keys,
  lowerCase,
  map,
  snakeCase,
} from 'lodash';
import $ from 'jquery';
import moment from 'moment';

import { getUserIdsFromAssignees } from './node_assignee_helpers';

export const KEYCLOAK_AUTH_URL = '/users/auth/keycloakopenid';
export const INSTANCE_WIDE_GROUPS_ID = 'instance';

export const parameterize = obj => {
  const objKeys = keys(obj);
  const params = {};
  map(objKeys, k => {
    params[snakeCase(k)] = obj[k];
  });

  return params;
};

export const customStylesForBoards = {
  control: (provided, st) => ({
    ...provided,
    backgroundColor: '#ffffff',
    color: '#333333',
    boxShadow: 'none',
    border: 'none',
    borderRadius: 20,
    minHeight: 28,
    height: 28,
    fontSize: 12,
  }),
  input: (provided, st) => ({
    ...provided,
    height: 22,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export const customStyles = {
  control: (provided, st) => ({
    ...provided,
    backgroundColor: '#83b451',
    color: '#fff',
    boxShadow: 'none',
    border: 'none',
  }),
  dropdownIndicator: provided => ({ ...provided, color: '#ffffff' }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#ffffff',
  }),
  input: (provided, st) => ({
    ...provided,
    color: '#fff',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#ffffff',
  }),
  singleValue: provided => ({
    ...provided,
    color: '#ffffff',
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: '#ffffff',
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#618c36',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#618c36' : '#ffffff',
  }),
};

export const customStylesForFilter = {
  control: (provided, st) => ({
    ...provided,
    backgroundColor: '#eeeeee',
    color: '#618c36',
    boxShadow: 'none',
    border: 'none',
    height: 40,
    fontSize: 14,
    borderRadius: 15,
    paddingLeft: 10,
  }),
};

export const customControlStyle = newValues => ({
  control: (provided, st) => ({
    ...provided,
    ...newValues,
  }),
});

export const customStylesForGroupInfo = () =>
  customControlStyle({
    backgroundColor: '#eeeeee',
    color: '#618c36',
    boxShadow: 'none',
    border: 'none',
    height: 40,
    fontSize: 14,
  });

export const getIdsFromRecords = records => map(records, r => r.id);

export const getUrlParam = key =>
  new URLSearchParams(window.location.search).get(key);

export const getCommonValues = (records, type) => {
  let parameters = '';

  map(records, (record, i) => {
    if (i > 0 && i < records.length) {
      parameters += ',';
    }

    let value = [];

    switch (type) {
      case 'tags':
        value = getIdsFromRecords(record.tags);
        break;

      case 'assignees':
        value = getUserIdsFromAssignees(record.assignees);
        break;

      case 'groups':
        value = getIdsFromRecords(record.assignedGroups);
        break;

      default:
        value = [];
    }

    parameters += JSON.stringify(value);
  });

  const functionName = `_.intersectionWith(${parameters}, _.isEqual)`;

  // eslint-disable-next-line no-eval
  return eval(functionName);
};

export const allValuesEqual = arr => arr.every(v => isEqual(v, arr[0]));

export const isColliding = function(idOne, idTwo, scaleOne = 1, scaleTwo = 1) {
  // Div 1 data
  const objOne = $(`#${idOne}`);
  const objTwo = $(`#${idTwo}`);
  const offsetOne = objOne.offset();
  const offsetTwo = objTwo.offset();
  const topOne = offsetOne.top * scaleOne;
  const topTwo = offsetTwo.top * scaleTwo;
  const leftOne = offsetOne.left * scaleOne;
  const leftTwo = offsetTwo.left * scaleTwo;
  const widthOne = objOne.outerWidth() * scaleOne;
  const widthTwo = objTwo.outerWidth() * scaleTwo;
  const heightOne = objOne.outerHeight() * scaleOne;
  const heightTwo = objTwo.outerHeight() * scaleTwo;

  const leftTop =
    leftTwo > leftOne &&
    leftTwo < leftOne + widthOne &&
    topTwo > topOne &&
    topTwo < topOne + heightOne;
  const rightTop =
    leftTwo + widthTwo > leftOne &&
    leftTwo + widthTwo < leftOne + widthOne &&
    topTwo > topOne &&
    topTwo < topOne + heightOne;
  const leftBottom =
    leftTwo > leftOne &&
    leftTwo < leftOne + widthOne &&
    topTwo + heightTwo > topOne &&
    topTwo + heightTwo < topOne + heightOne;
  const rightBottom =
    leftTwo + widthTwo > leftOne &&
    leftTwo + widthTwo < leftOne + widthOne &&
    topTwo + heightTwo > topOne &&
    topTwo + heightTwo < topOne + heightOne;
  return leftTop || rightTop || leftBottom || rightBottom;
};

export const arrayMove = (oldIndex, newIndex, arr) => {
  const newArr = [...arr];

  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      newArr.push(undefined);
    }
  }

  newArr.splice(newIndex, 0, newArr.splice(oldIndex, 1)[0]);
  return newArr;
};

export const recordsAsSelectOptions = records =>
  map(records, r => ({
    label: r.name,
    value: r.id,
  }));

export const handleRecordChange = (
  records,
  value,
  options,
  store,
  onlyValue = true
) => {
  const recordToAdd = differenceWith(value, options, isEqual)[0];
  const recordToRemove = differenceWith(options, value, isEqual)[0];

  if (isEmpty(recordToAdd)) {
    store.destroyByRecordIds(recordToRemove.value, getIdsFromRecords(records));
  } else {
    store.addByRecordIds(
      onlyValue ? recordToAdd.value : recordToAdd,
      getIdsFromRecords(records)
    );
  }
};

export const getValuesFromArray = (options, k) => map(options, o => o[k]);

export const basicEditorToolbar = [
  ['bold', 'italic', 'underline'], // ['strike'] toggled buttons
  ['blockquote', 'code-block'],
  ['link', 'image'],
  ['emoji'],
  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ direction: 'rtl' }], // text direction

  // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  // ['clean'],
];

export const commentEditorToolbar = [
  ['bold', 'italic', 'underline'],
  [{ font: [] }],
  ['emoji'],
];

export const setRedirectionUrl = () => {
  localStorage.setItem(
    'redirectURL',
    `${window.location.pathname}${window.location.search}`
  );
};

export const formatDate = d => {
  if (isEmpty(d)) return null;

  return moment(d).format('DD/MMM/YYYY');
};

export const redirectTo = url => {
  window.location.href = url;
};

export const applySearchQuery = (searchQuery, records) => {
  if (isEmpty(searchQuery)) return records;

  return filter(
    records,
    record => lowerCase(record.name).indexOf(lowerCase(searchQuery)) > -1
  );
};

export const getValueFromRecordsIfEqual = (records, key) => {
  if (records.length < 2 || allValuesEqual(map(records, n => n[key])))
    return records[0][key];

  return null;
};

export const isEmail = email =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
