import React from 'react';

import { Container } from 'reactstrap';

import Select from 'react-select';
import { observer } from 'mobx-react';

import useStore from '../../../data/store';
import { customStylesForBoards } from '../../../helpers/shared_helpers';
import BoardActions from './BoardActions';

const Toolbar = observer(() => {
  const { boards: store } = useStore();

  const changeActiveBoard = sv => {
    store.updateActiveBoard(sv.value);
  };

  return (
    <Container fluid>
      <div className="info-bar">
        <div className="board-selector">
          <Select
            styles={customStylesForBoards}
            value={store.lastOpenedBoard}
            options={store.boardsAsSelectOptions}
            onChange={changeActiveBoard}
          />
        </div>
        <span className="board-info"> {store.activeBoard?.description} </span>
        <BoardActions />
      </div>
    </Container>
  );
});

export default Toolbar;
