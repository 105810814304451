import React, { useEffect, useState } from 'react';

import { FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DateTime from 'react-datetime';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getNodesValue } from '../../../../helpers/node_helpers';
import useStore from '../../../../data/store';
import { getIdsFromRecords } from '../../../../helpers/shared_helpers';

const NodeDueDate = observer(({ nodes }) => {
  const [dueDate, setDueDate] = useState(null);
  const [funcTimeout, setFuncTimeout] = useState(null);

  const { nodes: store } = useStore();

  useEffect(() => {
    const date = getNodesValue(nodes, 'dueDate');

    if (!isEmpty(date)) setDueDate(new Date(date));
  }, []);

  const handleChange = val => {
    setFuncTimeout(clearTimeout(funcTimeout));

    const params = { action_type: 'work', due_date: val };

    setDueDate(val);

    setFuncTimeout(
      setTimeout(() => {
        store.updateNodes(getIdsFromRecords(nodes), params);
      }, 1000)
    );
  };
  const { t } = useTranslation();
  return (
    <div className="plan-param-fields sidebar-common-inputs">
      <FormGroup>
        <Label>{t('sidebar.work.due_date')}</Label>
        <DateTime
          value={dueDate}
          timeFormat={false}
          dateFormat="DD/MM/YYYY"
          closeOnSelect
          closeOnTab
          closeOnClickOutside
          onChange={d => handleChange(d)}
        />
      </FormGroup>
    </div>
  );
});

NodeDueDate.propTypes = {
  nodes: PropTypes.instanceOf(Array).isRequired,
};

export default NodeDueDate;
