import React from 'react';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, Label } from 'reactstrap';
import Toggle from 'react-toggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import { getIdsFromRecords } from '../../../../helpers/shared_helpers';

const MultiSelectable = observer(({ tree }) => {
  const { t } = useTranslation();
  const handleMultiSelectInfoClick = () => {
    tree.store.rootStore.sidebars.addSidebar({
      type: 'multiNode',
      nodeIds: getIdsFromRecords(tree.selectedNodes),
      treeName: tree.name,
    });
  };

  return (
    <>
      <div className="control-bar-block">
        <Label>
          <Toggle
            checked={tree.multiSelect}
            icons={false}
            aria-label="Multi Select"
            onChange={() => tree.toggleMultiSelect()}
          />
          <div className="toggle-label">
            <span className="toggle-label-text">
              {t('control_bar.multiselect.label')}
            </span>
            {tree.multiSelect && tree.selectedNodes.length > 0 && (
              <span>
                {t('control_bar.multiselect.nodes_selected', {
                  count: tree.selectedNodes.length,
                })}
              </span>
            )}
          </div>
        </Label>
        {tree.multiSelect && (
          <>
            <div className="control-bar-divider" />
            <Button
              disabled={tree.selectedNodes.length < 1}
              onClick={handleMultiSelectInfoClick}
              title={t('control_bar.tooltip.multiselect')}
            >
              <FontAwesomeIcon icon="ellipsis-v" data-toggle="tooltip" />
            </Button>
          </>
        )}
      </div>
    </>
  );
});

MultiSelectable.propTypes = {
  tree: PropTypes.instanceOf(Object).isRequired,
};

export default MultiSelectable;
