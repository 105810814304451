import React from 'react';

import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import useStore from '../../../../data/store';

const MultipleNodeRemove = observer(({ nodeIds, className, nodeType }) => {
  const { nodes: store, sidebars: sidebarStore } = useStore();
  const { t } = useTranslation();
  const handleClick = () =>
    confirmAlert({
      title: t('alert.titles.attention'),
      message: t('sidebar.delete_items.alert_message'),
      buttons: [
        {
          label: t('alert.buttons.yes'),
          onClick: () => {
            store
              .updateNodes(nodeIds, {
                node_type: nodeType || 'node',
                action_type: 'remove_all',
              })
              .then(res => {
                sidebarStore.clearAll();
              });
          },
        },
        {
          label: t('alert.buttons.no'),
        },
      ],
    });

  return (
    <Button onClick={handleClick} color={`danger ${className || 'w-100'}`}>
      Delete All
    </Button>
  );
});

export default MultipleNodeRemove;
