import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Label,
  ButtonGroup,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { clone, isEmpty, map } from 'lodash';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isObservable, toJS, untracked } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../data/store';
import { bgParams, GALLERY_MAX, unsplash } from '../../helpers/unsplash_helper';

const UnsplashImagePicker = ({ record }) => {
  const { app, users } = useStore();

  const [selectedImage, setSelectedImage] = useState({});
  const [noneSelected, setNoneSelected] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [resetUrl, setResetUrl] = useState('');
  const [gallery, setGallery] = useState([]);
  const { t } = useTranslation();
  const uSplash = unsplash(app);
  const nobg = { backgroundUrl: '' };

  const searchImages = searchKey => {
    uSplash.search
      .photos(searchKey, 1, GALLERY_MAX)
      .then(resp => resp.json())
      .then(json => {
        const { results } = json;
        setGallery(results);
      });
  };

  useEffect(() => {
    searchImages('trees');

    setResetUrl(toJS(record).backgroundUrl);
  }, []);

  const setImageOnTree = (si, ns = false) => {
    setSelectedImage(si);
    setNoneSelected(ns);
    setCanSave(true);
    record.update(ns ? si : bgParams(si));
  };

  const saveBackgroundUrl = (reset = false) => {
    if (!noneSelected) {
      uSplash.photos.downloadPhoto(selectedImage);
    }
    let bg = {};
    if (reset) {
      bg.backgroundUrl = resetUrl;
    } else {
      bg = noneSelected ? nobg : bgParams(selectedImage);
    }

    record.update(bg, true);

    if (!reset) {
      setResetUrl(bg.backgroundUrl);
    }
    setSelectedImage({});
    setCanSave(false);
  };

  const attribution = record.backgroundImageAttribution;

  return (
    <div>
      <Label> {t('unsplash.title')}</Label>
      <Row>
        {users.currentUser.isInstanceAdmin && (
          <Col md={12}>
            <Input
              name="url"
              className="form-control mb-3"
              placeholder={t('unsplash.paste_url')}
              onChange={e =>
                record.update({ backgroundUrl: e.target.value }, true)
              }
            />
          </Col>
        )}
        <Col md={12}>
          <Input
            name="search"
            className="form-control mb-3"
            placeholder={t('unsplash.search_placeholder')}
            onChange={e => searchImages(e.target.value)}
          />
        </Col>
        <Col md={12}>
          <div className="us-image-gallery">
            <Button
              style={{
                backgroundImage: `url("")`,
              }}
              className={classnames({
                'us-image-thumb': true,
                none: true,
                active: noneSelected,
              })}
              onClick={() => setImageOnTree(nobg, true)}
            />
            {map(gallery, (e, i) => (
              <Button
                key={i}
                style={{ backgroundImage: `url(${e.urls.thumb})` }}
                className={classnames({
                  'us-image-thumb': true,
                  active: selectedImage && selectedImage.id === e.id,
                })}
                onClick={() => setImageOnTree(gallery[i])}
              />
            ))}
          </div>
        </Col>
        <Col>
          <Row className="mt-2">
            <Col>
              <Button
                onClick={() => saveBackgroundUrl()}
                color="success btn-block"
                disabled={!canSave}
              >
                {t('unsplash.save')}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  saveBackgroundUrl(true);
                }}
                color="secondary btn-block"
                disabled={!canSave}
              >
                {t('unsplash.reset')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {!isEmpty(attribution) && (
        <div>
          <div className="small text-muted mt-2 mb-1 text-center">
            {t('unsplash.photo_by')}&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://unsplash.com/@${attribution.username}`}
            >
              {attribution.fullname}
            </a>
          </div>
        </div>
      )}
      <p className="text-center small text-left text-muted mt-1">
        {t('unsplash.powered_by')}
        &nbsp;
        <a href="https://unsplash.com" rel="noreferrer" target="_blank">
          {t('unsplash.unsplash')}
        </a>
      </p>
    </div>
  );
};

UnsplashImagePicker.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  record: PropTypes.instanceOf(Object),
};

export default UnsplashImagePicker;
