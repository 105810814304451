import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import RootStore from '../../data';
import { StoreProvider } from '../../data/provider';
import '../../i18n';

// Load Styles
import '../../assets/stylesheets';
import Routes from './Routes';

const EntryPoint = () => (
  <StoreProvider value={new RootStore()}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </StoreProvider>
);

export default EntryPoint;
