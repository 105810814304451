import React from 'react';

import { observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import {
  KEYCLOAK_AUTH_URL,
  setRedirectionUrl,
} from '../../helpers/shared_helpers';
import useStore from '../../data/store';
import AmalatiEmbedComponent from '../shared/_amalati_embed';

const AuthRoute = ({ exact, path, component, skipPublic }) => {
  const { users: userStore } = useStore();

  if (!userStore.userSignedIn) {
    if (window.location.pathname.indexOf('api/v1/users/heartbeat.json') === -1)
      setRedirectionUrl();

    if (skipPublic) {
      return (window.location.href = KEYCLOAK_AUTH_URL);
    }

    return <Redirect to="/public/auth" />;
  }
  return <Route exact={exact} path={path} component={component} />;
};

AuthRoute.defaultProps = {
  skipPublic: false,
};

AuthRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.instanceOf(Object).isRequired,
  skipPublic: PropTypes.bool,
};

export default observer(AuthRoute);
